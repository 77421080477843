<template>
    <div style="margin-top:30px;">
        <h2>{{__('site.historial')}}</h2>
        <div class="tablalistado" v-if="historial.length">
            <table class="table table-striped table-bordered tablastatic" >
                <thead>
                <tr>
                    <th style="width: 140px">{{__('site.fecha')}}</th>
                    <th>{{__('site.revision')}}</th>
                    <th>{{__('site.observaciones')}}</th>
                    <th>{{__('site.estado_actual')}}</th>
                    <th>{{__('site.estado_anterior')}}</th>
                    <th>{{__('site.usuario')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in historial" >
                    <td>{{mostrarFechaLarga(item.fecha)}}</td>
                    <td>{{item.revision}}</td>
                    <td>{{item.descripcion?item.descripcion:__('site.actualizacion_metadatos')}}</td>
                    <td>{{motrarEstadoArticulo(item.estado)}}</td>
                    <td>{{motrarEstadoArticulo(item.estado_anterior)}}</td>
                    <td>{{item.nombre}} {{item.apellidos}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            {{__('site.no_hay_datos')}}
        </div>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    export default {
        created: function(){
            let vm = this;
            vm.actualizar();
        },
        props: ['articulo'],
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            actualizar: function(){
                let vm = this;
                vm.historial = [];
                axios.get(route('articulos.historial',{id:vm.articulo.id}))
                    .then(response => {
                        vm.historial = response.data.historial;
                    });
            }
        },
        data() {
            return {
                historial: [],
            }
        },
    }
</script>
