<template>
    <div style="margin-top:30px;">
        <h2>{{__('site.revisiones')}}</h2>
        <div v-if="articulo.revisiones && articulo.revisiones.length">
            <template v-for="revision in articulo.revisiones" >
                <h2>{{__('site.revision_realizada_por')}} {{revision.revisor.nombre_completo}}</h2>
                <revisiones-show-datos :revision="revision" :showrevisor="false"></revisiones-show-datos>
            </template>
        </div>
        <div v-else>
            {{__('site.no_hay_datos')}}
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarCampo from "../utils/MostrarCampo";
    import RevisionesShowDatos from "../revisiones/RevisionesShowDatos";
    export default {
        mixins: [mixin],
        components: {RevisionesShowDatos, MostrarCampo},
        methods: {
            ...mapMutations(['setCargando']),
        },
        computed: {
            ...mapState('auth',['usuario']),
        },
        props: ['articulo'],
        data() {
            return {

            }
        },
    }
</script>
