<template>
    <div class="content" style="width: 100%;">

        <ventana-flotante
                :titulo="numero.definicion"
                v-if="ordenar"
                @close="actualizar">
            <div slot="body">
                <numeros-ordenar :articulos="numero.articulos" @cerrar="actualizar"></numeros-ordenar>
            </div>
        </ventana-flotante>
        <ventana-flotante
                @close="show_enviar = false"
                :titulo="__('site.enviar_newsletter')"
                v-if="show_enviar">
            <div slot="body">
                <numeros-enviar @cerrar="show_enviar = false" :numero="numero"></numeros-enviar>
            </div>
        </ventana-flotante>

        <div class="container" v-if="numero.id">
            <h1 >{{numero.definicion}}</h1>

            <h2>{{__('site.metadatos')}}</h2>
            <div class="row">
                <div class="col-md-2">
                    <template v-if="numero.portada" >
                        <img :src="url_portada" class="img-fluid" />
                        <div class="text-right" style="margin-top:10px;">
                            <button type="button" class="btn boton" data-toggle="modal" data-target="#exampleModal">
                                <i class="fas fa-search-plus"></i>
                            </button>
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body text-center" >
                                            <img :src="url_portada" class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="numero.portada_en" >
                        <img :src="url_portada_en" class="img-fluid" />
                        <div class="text-right" style="margin-top:10px;">
                            <button type="button" class="btn boton" data-toggle="modal" data-target="#exampleModal2">
                                <i class="fas fa-search-plus"></i>
                            </button>
                            <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body text-center" >
                                            <img :src="url_portada_en" class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                
                
                
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6">
                            <mostrar-campo :label="__('site.titulo_espanol')" :valor="numero.titulo_es"></mostrar-campo>
                        </div>
                        <div class="col-md-6">
                            <mostrar-campo :label="__('site.titulo_ingles')" :valor="numero.titulo_en"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.tipo')" :valor="elementos_tipo[numero.tipo].nombre"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.fecha')" :valor="mostrarFecha(numero.fecha)"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.ano')" :valor="numero.year"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.volumen')" :valor="numero.volumen"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.numero')" :valor="numero.numero"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.meses_es')" :valor="numero.meses_es"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.meses_en')" :valor="numero.meses_en"></mostrar-campo>
                        </div>
                        <div class="col-md-3">
                            <mostrar-campo :label="__('site.paginas')" :valor="numero.paginas"></mostrar-campo>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6" v-if="numero.documento">
                    <mostrar-fichero :fichero="numero.documento"
                                     :url="url_documento"
                                     :titulo="__('site.documento_pdf')+' '+__('site.espanol').toLowerCase()"
                                     :editable = "false"
                                     :obligatorio = "true"
                    ></mostrar-fichero>
                </div>
                <div class="col-md-6" v-if="numero.documento_en">
                    <mostrar-fichero :fichero="numero.documento_en"
                                     :url="url_documento_en"
                                     :titulo="__('site.documento_pdf')+' '+__('site.ingles').toLowerCase()"
                                     :editable = "false"
                                     :obligatorio = "true"
                    ></mostrar-fichero>
                </div>
            </div>

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6" v-if="numero.indice_editorial">
                    <mostrar-fichero :fichero="numero.indice_editorial"
                                     :url="url_indice"
                                     :titulo="__('site.indice_editorial')+' '+__('site.espanol').toLowerCase()"
                                     :editable = "false"
                                     :obligatorio = "true"
                    ></mostrar-fichero>
                </div>
                <div class="col-md-6" v-if="numero.indice_editorial_en">
                    <mostrar-fichero :fichero="numero.indice_editorial_en"
                                     :url="url_indice_en"
                                     :titulo="__('site.indice_editorial')+' '+__('site.ingles').toLowerCase()"
                                     :editable = "false"
                                     :obligatorio = "true"
                    ></mostrar-fichero>
                </div>
            </div>

            <h2>{{__('site.articulos')}}</h2>
            <div v-for="tipo in secciones" v-if="articulosByTipo(tipo.id).length">
                <h3 class="seccion">{{tipo.nombre}}</h3>
                <table class="table table-striped table-bordered tablastatic">
                    <thead>
                    <tr>
                        <th>{{__('site.articulo')}}</th>
                        <th width="170px">{{__('site.objid')}}</th>
                        <th width="70px">{{__('site.maquetado')}}</th>
                        <th style="width: 95px"></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(articulo,index) in articulosByTipo(tipo.id)">
                            <td>
                                <strong>{{articulo.codigo}} - {{articulo.titulo_es}}</strong><br/>
                                {{articulo.autores_nombres}}
                            </td>
                            <td>
                                <template v-if="!articulo.edit">
                                    {{articulo.objid}}
                                </template>
                                <template v-else>
                                    <input class="form-control" v-model="articulo.objid" />
                                </template>
                            </td>
                            <td>{{articulo.maquetado?__('site.si'):__('site.no')}}</td>
                            <td>
                                <router-link :to="{name:'articulos.show',params:{id:articulo.id}}" class="icono" :title="__('site.ver_articulo')"><i class="fas fa-eye"></i></router-link>
                                <a href="#!" v-if="!articulo.edit" @click="articulo.edit = 1" class="icono" :title="__('site.editar_objid')" ><i class="far fa-edit"></i></a>
                                <a href="#!" v-else class="icono" @click="guardarObjid(articulo)" :title="__('site.guardar')" ><i class="fas fa-save"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="text-center" style="margin-top:30px;">
                        <router-link :to="{name:'numeros.index'}" class="btn boton">{{__('site.volver')}}</router-link>
                        <router-link :to="{name:'numeros.edit',params:{id:$route.params.id}}" class="btn boton">{{__('site.editar')}}</router-link>
                        <button @click="ordenar = true" class="btn boton">{{__('site.ordenar')}}</button>
                        <button @click="publicar(1)" v-if="!numero.publicada" class="btn boton">{{__('site.publicar')}}</button>
                        <button @click="publicar(0)" v-else class="btn boton">{{__('site.retirar_publicacion')}}</button>
                        <button @click="show_enviar = true" class="btn boton">{{__('site.newsletter')}}</button>
                        <button v-if="$route.params.id && !numero.publicada"
                                @click="borrar(__('site.msg_confirmar_eliminar'),
                                            $route.params.id,
                                            'numeros.destroy',
                                            'numeros.index')"
                                class="btn boton" >{{__('site.eliminar')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarCampo from "../utils/MostrarCampo";
    import NumerosOrdenar from "./NumerosOrdenar";
    import MostrarFichero from "../utils/MostrarFichero";
    import NumerosEnviar from "./NumerosEnviar";
    export default {
        created: function(){
            let vm = this;

            axios.get(route('articulos.getsecciones')).then(response => {
                vm.secciones = response.data.secciones;
                vm.actualizar();
            });
        },
        computed: {
            url_portada: function(){
                return route('web.numeros.portada',{id:this.numero.id,portada:this.numero.portada})
            },
            url_portada_en: function(){
                return route('web.numeros.portada',{id:this.numero.id,portada:this.numero.portada_en})
            },
            url_indice: function(){
                return route('numeros.downloadficheros',{id:this.numero.id,filename:this.numero.indice_editorial})
            },
            url_indice_en: function(){
                return route('numeros.downloadficheros',{id:this.numero.id,filename:this.numero.indice_editorial_en})
            },
            url_documento: function(){
                return route('numeros.downloadficheros',{id:this.numero.id,filename:this.numero.documento})
            },
            url_documento_en: function(){
                return route('numeros.downloadficheros',{id:this.numero.id,filename:this.numero.documento_en})
            }
        },
        mixins: [mixin],
        components: {NumerosEnviar, NumerosOrdenar, MostrarCampo, MostrarFichero},
        methods: {
            ...mapMutations(['setCargando']),
            
            articulosByTipo: function(tipo){
                let vm = this;
                if(vm.numero.articulos.length){
                    return vm.numero.articulos.filter(e => e.tipo == tipo);
                }
                return [];
            },
            actualizar: function(){
                let vm = this;
                vm.setCargando(true);
                vm.ordenar = false;
                axios.get(route('numeros.show',{'id':vm.$route.params.id})
                ).then(response => {
                    vm.numero = response.data.numero;
                    vm.numero.articulos.forEach(function(item){
                        Vue.set(item,'edit',0);
                    });
                    vm.setCargando(false);
                });
            },
            publicar: function(tipo){
              let vm = this;
                let texto = tipo?vm.__('site.publicar_aviso'):vm.__('site.retirar_publicacion_aviso');

                swal({
                    text: texto,
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: vm.__('site.cancelar'),
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.aceptar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.setCargando(true);
                        axios.post(route('numeros.publicar',{id:vm.numero.id}),{
                            publicada: tipo
                        })
                        .then((response) => {
                            vm.actualizar();
                        });
                    }

                });
            },
            guardarObjid: function(articulo){
                let vm = this;
                vm.setCargando(true);
                axios.post(route('articulos.updateObjid',{id:articulo.id}),{
                    objid: articulo.objid
                })
                    .then((response) => {
                        vm.actualizar();
                    });
            },
            enviar: function(){
                let vm = this;
                swal({
                    text: vm.__('site.msg_envio_newsletter'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: 'Cancelar',
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.enviar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.setCargando(true);
                        axios.post(route('numeros.enviar',{id:vm.numero.id})
                        ).then(function (response) {
                            swal({
                                text: vm.__('site.msg_envio_newsletter_ok'),
                                icon: 'success',
                                buttons: {
                                    aceptar: {
                                        text: vm.__('Aceptar'),
                                        value: true
                                    }
                                },
                            }).then(function(){
                                vm.setCargando(false);
                            });
                        })
                            .catch(function (error) {
                                console.log(error);
                                swal(error, 'error', 'error');
                            });
                    }
                });
            }
        },
        data() {
            return {
                numero: {},
                secciones: [],
                ordenar: false,
                cargando: false,
                show_enviar: false,
                elementos_tipo: [{nombre:this.__('site.numero'),id:0},{nombre:this.__('site.suplemento'),id:1}],
            }
                
        },
    }
</script>
