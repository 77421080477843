<template>
    <div >
        <div>
            <div class="input-group">
                <label>{{__('site.email')}}</label>
                <div class="form-group">
                    <input class="form-control" v-model="email" />
                </div>
            </div>
        </div>
        <div class="text-center">
            <boton-guardar @guardar="enviar" :cargando="cargando" ><template slot="texto">{{__('site.enviar')}}</template></boton-guardar>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import BotonGuardar from "../BotonGuardar";
    export default {
        components: {BotonGuardar},
        mixins: [mixin],
        methods: {
            enviar: function(){
                let vm = this;
                vm.cargando = true;
                axios.post(route('numeros.enviar',{id:vm.numero.id}),{
                    email: vm.email
                }).then(function (response) {
                    swal({
                        text: vm.__('site.msg_envio_newsletter_ok'),
                        icon: 'success',
                        buttons: {
                            aceptar: {
                                text: vm.__('Aceptar'),
                                value: true
                            }
                        },
                    }).then(function(){
                        vm.cargando = false;
                        vm.$emit('cerrar');
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    swal(error, 'error', 'error');
                });
            }
        },
        props: ['numero'],
        data() {
            return {
                email: '',
                cargando: false,
            }
                
        },
    }
</script>
