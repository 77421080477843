<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{__('site.usuarios')}}</h1>
            </div>
            <div class="col-md-3 text-right">
                <router-link v-if="checkPermiso(tipos_permiso.gestion_administracion_usuarios)" class="btn boton" :to="{ name: 'admin.usuarios.create' }">{{__('site.nuevo_usuario')}}</router-link>
            </div>
        </div>
        <v-server-table ref="tabla" @row-click="verUsuario" @loaded="setCargando(false)" class="tablalistado tablausuarios" :url="url" :columns="columns" :options="options" >
            <div slot="afterFilterWrapper" style="display:inline-flex;margin-bottom:15px;">
                <div style="margin-right: 15px;display: flex;width: 100%;">
                    <input type="text"
                           class="form-control"
                           v-model="query_text"
                           @keyup.enter="actualizarTabla"
                           :placeholder="__('site.buscar')" />&nbsp;
                </div>
                    <div style="margin-left: 5px;display: flex;width: 100%;">
                        <botones-radio v-model="activo"
                                       :obligatorio="false"
                                       :elementos="elementos_activo"></botones-radio>
                    </div>
                    <div style="margin-left: 5px;margin-right: 5px;display: flex;width: 100%;">
                        <select class="form-control" v-model="rol">
                            <option value="">{{__('site.rol')}}</option>
                            <option value="0">{{__('site.autor')}}</option>
                            <option value="1">{{__('site.revisor')}}</option>
                            <option value="2">{{__('site.editor_adjunto')}}</option>
                            <option value="3">{{__('site.director')}}</option>
                            <option value="4">{{__('site.coordinador')}}</option>
                            <option value="5">{{__('site.administrador')}}</option>
                        </select>
                    </div>
                    <button @click="actualizarTabla" class="btn boton"
                            :title="__('site.buscar')">
                        <i class="fas fa-search"></i>
                    </button>
            </div>

            <template v-slot:rol="props">{{mostrarRol(props.row)}}</template>
            <i slot="activo" slot-scope="props" v-if="props.row.activo" class="fas fa-check"></i>
        </v-server-table>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    import {textos_tabla,iconos_tabla} from '../../config_tabla'
    import BotonesRadio from "../utils/BotonesRadio";
    export default {
        components: {BotonesRadio},
        created: function(){
            let vm = this;
            vm.setCargando(true);
            vm.url =route('usuarios.index');
            vm.options.headings = vm.headings;
        },
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            verUsuario: function(data){
                this.$router.push({ name: 'admin.usuarios.edit', params: {'id':data.row.id} })
            },
            actualizarTabla: function(){
                let vm = this;
                vm.$refs.tabla.refresh();
            },
            mostrarRol: function(item){
                let vm = this;
                switch (item.rol){
                    case vm.roles.autor:
                        return vm.__('site.autor')
                    case vm.roles.revisor:
                        return vm.__('site.revisor')
                    case vm.roles.editor:
                        return vm.__('site.editor')
                    case vm.roles.director:
                        return vm.__('site.director')
                    case vm.roles.coordinador:
                        return vm.__('site.coordinador')
                    case vm.roles.administrador:
                        return vm.__('site.administrador')
                }
            },
            funcionEnvio: function (data) {
                let vm = this;
                if(vm.query_text){
                    data['query'] = vm.query_text;
                }
                if(vm.activo == 0 || vm.activo == 1){
                    data['activo'] = vm.activo;
                }
                if(vm.rol.length){
                    data['rol_search'] = vm.rol;
                }
                if(vm.seccion_selected){
                    data['seccion'] = vm.seccion_selected;
                }
                if(vm.fecha_selected){
                    data['fecha_envio'] = moment(vm.fecha_selected).format('DD-MM-YYYY');
                }

                return axios.get(this.url, {
                    params: data
                }).catch(function (e) {
                    this.dispatch('error', e);
                }.bind(this));
            }
        },
        data() {
            return {
                usuarios: [],
                url: '',
                query_text: '',
                activo: 1,
                rol: '',
                columns: [
                    'nombre_completo',
                    'email',
                    'rol',
                    'activo',
                ],
                headings: {
                        'nombre_completo': this.__('site.nombre'),
                        'email': this.__('site.email'),
                        'rol': this.__('site.rol'),
                        'activo': this.__('site.activo'),
                },
                options: {
                    filterable: false,
                    texts: textos_tabla,
                    sortIcon: iconos_tabla,
                    perPage: 50,
                    perPageValues: [25,50,100],
                    requestFunction: this.funcionEnvio
                },
                elementos_activo: [{nombre:this.__('site.activo'),id:1},{nombre:(this.__('site.no')+' '+this.__('site.activo')),id:0}],
            }
        },
    }
</script>
<style type="text/css">
    .tablausuarios thead th:nth-child(4){
        width: 90px;
    }
    .tablausuarios thead th:nth-child(3){
        width: 150px;
    }
    .tablausuarios thead th:nth-child(2){
        width: 350px;
    }
</style>