<template>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <h1>{{__('site.ajustes')}}</h1>
                </div>
            </div>
            <h2>{{__('site.informacion_general')}}</h2>
            <div class="row">
                    <div v-for="item in generales" :class="item.tipo==3?'col-md-12':'col-md-4'" >
                        <div class="input-group">
                            <label>{{item.nombre}}</label>
                            <div class="form-group">
                                <ckeditor v-if="item.tipo == 3" v-model="item.valor" :config="opciones_editor"></ckeditor>
                                <input v-else class="form-control" v-model="item.valor" :type="getTipo(item.tipo)" />
                            </div>
                        </div>
                    </div>
            </div>
            <h2>{{__('site.crossref_pubmed')}}</h2>
            <div class="row">
                <div class="col-md-4" v-for="item in crossref">
                    <div class="input-group">
                        <label>{{item.nombre}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="item.valor" :type="getTipo(item.tipo)" />
                        </div>
                    </div>
                </div>
            </div>
            <h2>{{__('site.limites')}}</h2>
            <div class="row">
                <div class="col-md-4" v-for="item in limites">
                    <div class="input-group">
                        <label>{{item.nombre}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="item.valor" :type="getTipo(item.tipo)" />
                        </div>
                    </div>
                </div>
            </div>
            <h2>{{__('site.plazos')}}</h2>
            <div class="row">
                <div class="col-md-4" v-for="item in plazos">
                    <div class="input-group">
                        <label>{{item.nombre}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="item.valor" :type="getTipo(item.tipo)" />
                        </div>
                    </div>
                </div>
            </div>
            <h2>{{__('site.ajustes_envio')}}</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.emisor')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_from_name" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.email_emisor')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_from_address" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.driver')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_driver" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.usuario')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_username" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.password')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_password" type="password" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.puerto')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_port" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.host')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_host" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.encriptacion')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_enc" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <h2>{{__('site.conf_envios_newsletter')}}</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.emisor')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_fromname_newsletter" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.email_emisor')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_fromaddress_newsletter" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.usuario')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_username_newsletter" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.password')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_password_newsletter" type="password" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.puerto')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_port_newsletter" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.host')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_host_newsletter" type="text" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.encriptacion')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_enc_newsletter" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <h2>{{__('site.modo_debug')}}</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.modo_debug')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="ajustes_app.modo_debug"
                                           :obligatorio="true"
                                           :elementos="elementos_debug"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.email_debug')}}</label>
                        <div class="form-group">
                            <input class="form-control" v-model="ajustes_app.email_debug" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                    <div class="text-center" style="margin-top:30px;">
                        <boton-guardar @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../../mixin.js'
    import {textos_tabla,iconos_tabla} from '../../../config_tabla'
    import BotonGuardar from "../../BotonGuardar";
    import BotonesRadio from "../../utils/BotonesRadio";
    export default {
        components: {BotonesRadio, BotonGuardar},
        created: function(){
            let vm = this;
            vm.setCargando(true);
            vm.url =route('ajustes.index');
            axios.get(vm.url).then(response => {
                vm.ajustes = response.data.ajustes;
                vm.ajustes_app = response.data.ajustes_app;
                vm.setCargando(false);
            })

        },
        computed: {
          generales: function(){
              return this.ajustes.filter(e => e.seccion == 1);
          },
          crossref: function(){
              return this.ajustes.filter(e => e.seccion == 2);
          },
          limites: function(){
              return this.ajustes.filter(e => e.seccion == 3);
          },
          plazos: function(){
              return this.ajustes.filter(e => e.seccion == 4);
          },

            opciones_editor: function () {
                return {
                    language: 'es',
                    toolbar: [
                        { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source' ] },
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Undo', 'Redo' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
                        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike' ] },
                        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Table' ] },
                        { name: 'links', items: [ 'Link', 'Unlink' ] },
                    ],
                    height: 200
                }
            },
        },
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            getTipo: function(tipo){
                switch (tipo){
                    case 0:
                        return 'text';
                    case 1:
                        return 'number';
                    case 2:
                        return 'password';

                }
            },
            guardar: function(){
                let vm = this;
                vm.setCargando(true);
                axios.post(route('ajustes.store'), {
                    ajustes: vm.ajustes,
                    email_from_name:vm.ajustes_app.email_from_name,
                    email_from_address:vm.ajustes_app.email_from_address,
                    email_driver:vm.ajustes_app.email_driver,
                    email_host:vm.ajustes_app.email_host,
                    email_port:vm.ajustes_app.email_port,
                    email_enc:vm.ajustes_app.email_enc,
                    email_username:vm.ajustes_app.email_username,
                    email_password:vm.ajustes_app.email_password,
                    email_fromname_newsletter:vm.ajustes_app.email_fromname_newsletter,
                    email_fromaddress_newsletter:vm.ajustes_app.email_fromaddress_newsletter,
                    email_host_newsletter:vm.ajustes_app.email_host_newsletter,
                    email_port_newsletter:vm.ajustes_app.email_port_newsletter,
                    email_enc_newsletter:vm.ajustes_app.email_enc_newsletter,
                    email_username_newsletter:vm.ajustes_app.email_username_newsletter,
                    email_password_newsletter:vm.ajustes_app.email_password_newsletter,
                    modo_debug:vm.ajustes_app.modo_debug,
                    email_debug:vm.ajustes_app.email_debug,
                }).then(response => {
                    vm.$router.push({ name: 'admin.home' });
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.setCargando(false);
                    vm.cargando = false;
                });
            }
        },
        data() {
            return {
                ajustes: [],
                ajustes_app: [],
                errores: [],
                cargando: false,
                elementos_debug: [{nombre:this.__('site.desarrollo'),id:1},{nombre:this.__('site.produccion'),id:0}],
            }
        },
    }
</script>

