<template>
    <button @click="$emit('guardar')" v-if="!cargando" class="btn boton">
        <slot name="texto">{{__('site.guardar')}}</slot>
    </button>
    <span class="cargandoboton" v-else><i class="fas fa-circle-notch fa-spin"></i></span>
</template>

<script>
    export default {
        props: [
          'cargando'
        ]
    }
</script>
