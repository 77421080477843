<template>
    <div >
        <ventana-flotante
                :titulo="__('site.nuevo_autor')"
                v-if="show_nuevo_autor"
                @close="show_nuevo_autor = false">
            <div slot="body">
                <usuarios-edit-autores
                        @close="show_nuevo_autor = false"
                        @guardado="addNuevoAutor"></usuarios-edit-autores>
            </div>
        </ventana-flotante>
        <table class="table table-striped table-bordered tablastatic">
            <thead>
            <tr>
                <th>{{__('site.autor')}}</th>
                <th width="170px">{{__('site.correspondencia')}}</th>
                <th style="width: 50px"></th>
            </tr>
            </thead>
            <draggable v-model="autores_data" element="tbody" @end="enviarAutores">
                <tr :key="index" v-for="(item,index) in autores_data">
                    <td>
                        <div style="display: flex;">
                            <i style="cursor:move;margin-top: 5px;" class="fas fa-grip-vertical"></i>
                            <div style="margin-left: 5px;flex-grow:2">
                                <strong>{{item.nombre_completo}}</strong><br/>
                                {{item.email}}
                            </div>
                        </div>
                    </td>
                    <td >
                        <label
                                class="checkboxcontainer checkboxinline">
                            <input type="checkbox" v-model="item.correspondencia" value="1" />
                            <span class="checkmark"></span>
                        </label>
                    </td>
                    <td >
                        <a href="javascript:void(0)" class="icono" @click="eliminarAutor(item)"><i class="fas fa-trash-alt"></i></a>&nbsp;
                    </td>
                </tr>
            </draggable>
            <tr>
                <td colspan="2">
                    <autocomplete v-model="autor"
                                  clave="id"
                                  :placeholder="__('site.buscar_autor')"
                                  @seleccionado="enviarAutor"
                                  :excluir="autores_data"
                                  :valores="['nombre','apellidos','email']"
                                  :url="url_usuarios"></autocomplete>
                </td>
                <td>
                    <button @click="addItem()" class="boton btn">
                        <i class="fas fa-plus"></i>
                    </button>
                </td>
            </tr>
        </table>
        <div class="row">
            <div class="col-md-8">
                <p>{{__('site.buscar_autor_nuevo')}}</p>
            </div>
            <div class="col-md-4 text-right">
                <button type="button" class="btn boton" @click="show_nuevo_autor = true">{{__('site.nuevo_autor')}}</button>
            </div>
        </div>
    </div>
</template>

<script>

    import draggable from 'vuedraggable';
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import Autocomplete from "../utils/Autocomplete";
    import UsuariosEditAutores from "./UsuariosEditAutores";
    export default {
        components: {UsuariosEditAutores, Autocomplete,draggable},
        created: function(){
            let vm = this;
            vm.autores_data = vm.autores;
        },
        mixins: [mixin],
        methods: {
            enviarAutor: function(){
                console.log(this.autor);
            },
            enviarAutores: function(){
                this.$emit('change',this.autores_data);
            },
            eliminarAutor: function(item){
              this.autores_data.splice(this.autores_data.indexOf(item),1);
              this.enviarAutores();
            },
            addItem: function(){
                if(this.autor.id){
                    this.autores_data.push(this.autor);
                    this.autor = {};
                }
            },
            addNuevoAutor: function(item){
                this.autores_data.push(item);
                this.show_nuevo_autor = false;
            }
        },
        props: ['autores'],
        data() {
            return {
                autor:  {},
                autores_data:  {},
                show_nuevo_autor:  false,
                url_usuarios: route('usuarios.index'),
            }
        },
    }
</script>
