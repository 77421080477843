import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
   storage: window.localStorage,
});

export default new Vuex.Store({
    state: {
        cargando: false,
        idioma: 'es',
        url: null,
        permisos: [],
        pendientes: 0
    },
    mutations: {
        setIdioma: function(state,idioma) {
            state.idioma = idioma;
        },
        setUrl: function(state,url) {
            state.url = url;
        },
        setCargando: function(state,cargando) {
            state.cargando = cargando;
        },
        setPermisos: function(state,permisos) {
            state.permisos = permisos;
        },
        setPendientes: function(state,pendientes) {
            state.pendientes = pendientes;
        },
    },
    actions: {
        setCurrentUser: function(context){
            axios.get(route('usuarios.permisos')).then(response => {
                context.commit('setPermisos',response.data.permisos);
            }).catch(error =>{
                console.log(error.response.data);
            });
        },
        cambiarIdioma: function(context,idioma){
            context.commit('setIdioma',idioma);
        },
        asignarUrl: function(context,url){
            context.commit('setUrl',url);
        },
    },
    modules: {
        auth
    },
    plugins: [vuexLocal.plugin]
})
