<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{titulo}}</h1>
            </div>
            <div class="col-md-3 text-right">
                <div style="display:inline-flex;margin-left:15px;">
                    <router-link class="btn boton" :to="{ name: 'articulos.create' }" :title="__('site.nuevo_articulo')">
                        <i class="fas fa-file-signature"></i>  {{__('site.nuevo_articulo')}}
                    </router-link>
                </div>
            </div>
        </div>
        <v-server-table ref="tabla" @row-click="verArticulo" @loading="setCargando(true)"  @loaded="setCargando(false)" class="tablalistado tablaarticulos" :url="url" :columns="columns" :options="options" >
            <div slot="afterFilterWrapper" style="display:inline-flex;margin-bottom:15px;">
                <div style="margin-right: 15px;display: flex;width: 100%;">
                    <input type="text"
                           class="form-control"
                           v-model="query_text"
                           @keyup.enter="actualizarTabla"
                           :placeholder="__('site.buscar')" />&nbsp;
                    <template v-if="usuario.rol != roles.autor">
                        <select class="form-control" v-model="estado_selected">
                            <option value="">{{__('site.estado')}}</option>
                            <option v-for="estado in estados" :value="estado.id">{{estado.nombre}}</option>
                        </select>
                        <div style="margin-left: 5px;display: flex;width: 100%;">
                            <select class="form-control" v-model="seccion_selected">
                                <option value="">{{__('site.tipo')}}</option>
                                <option v-for="seccion in secciones" :value="seccion.id">{{seccion.nombre}}</option>
                            </select>
                        </div>
                        <div style="margin-left: 5px;display: flex;width: 100%;">
                            <datepicker format="dd-MM-yyyy"
                                        :language="es"
                                        v-model="fecha_selected"
                                        :monday-first="true"
                                        input-class="form-control inputfecha"
                                        :placeholder="__('site.fecha_envio')"></datepicker>
                        </div>
                    </template>
                    <button @click="actualizarTabla" class="btn boton"
                            :title="__('site.buscar')">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
            

            <template v-slot:titulo="props">{{props.row.titulo}} <img :src="'/images/'+props.row.idioma+'.png'" style="height: 10px" /></template>
        </v-server-table>
    </div>
</template>

<script>

    import moment from 'moment';
    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    import {textos_tabla,iconos_tabla} from '../../config_tabla'
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);

            axios.get(route('articulos.getestados')).then(response => {
                vm.estados = response.data.estados;
            });

            axios.get(route('articulos.getsecciones')).then(response => {
                vm.secciones = response.data.secciones;
            });

            if(vm.$route.name == 'articulos.index.misarticulos'){
                vm.url =route('articulos.index.misarticulos');
            }
            else{
                vm.url =route('articulos.index');
            }

            vm.options.headings = vm.headings;
            vm.options.templates = {
                'estado': function(h,row){
                    return (vm.motrarEstadoArticulo(row.estado)+' ('+vm.mostrarFecha(row.fecha_ultimo_estado)+')');
                },
                'created_at': function(h,row){
                    return vm.mostrarFecha(row.created_at);
                },
                'autor': function(h,row){
                    let autor = {};
                    row.autores.forEach(function(item){
                        if(!autor.id && item.pivot.correspondencia){
                            autor = item;
                        }
                        else{
                            if(item.pivot.correspondencia && item.pivot.orden < autor.pivot.orden){
                                autor = item;
                            }
                        }
                    });
                    return autor.nombre_completo?autor.nombre_completo:'';
                }
            };
        },
        mixins: [mixin],
        components: {
            Datepicker
        },
        watch:{
          fecha_selected: function(){
              this.$refs.tabla.refresh()
          }
        },
        methods: {
            ...mapMutations(['setCargando']),
            verArticulo: function(data){
                let routeData = this.$router.resolve({ name: 'articulos.show', params: {'id':data.row.id} });
                window.open(routeData.href, '_blank');
            },
            actualizarTabla: function(){
                let vm = this;
                vm.$refs.tabla.refresh();
            },
            funcionEnvio: function (data) {
                let vm = this;
                if(vm.query_text){
                    data['query'] = vm.query_text;
                }
                if(vm.estado_selected){
                    data['estado'] = vm.estado_selected;
                }
                if(vm.seccion_selected){
                    data['seccion'] = vm.seccion_selected;
                }
                if(vm.fecha_selected){
                    data['fecha_envio'] = moment(vm.fecha_selected).format('DD-MM-YYYY');
                }

                return axios.get(this.url, {
                    params: data
                }).catch(function (e) {
                    this.dispatch('error', e);
                }.bind(this));
            }
        },
        computed: {
          ...mapState('auth',['usuario']),
          titulo: function(){
              let vm = this;
              if(vm.$route.name == 'articulos.index.misarticulos'){
                  return vm.__('site.mis_articulos');
              }
              else{
                  return vm.__('site.articulos');
              }
          }
        },
        data() {
            return {
                es: es,
                estados: [],
                secciones: [],
                estado_selected: '',
                seccion_selected: '',
                fecha_selected: '',
                url: '',
                query_text: '',
                columns: [
                    'codigo',
                    'revision_actual',
                    'tipo',
                    'titulo',
                    'autor',
                    'created_at',
                    'estado',
                ],
                headings: {
                    'codigo': this.__('site.referencia'),
                    'revision_actual': this.__('site.ronda'),
                    'tipo': this.__('site.tipo'),
                    'titulo': this.__('site.titulo'),
                    'autor': this.__('site.autor'),
                    'created_at': this.__('site.fecha_creacion'),
                    'estado': this.__('site.estado'),
                },
                options: {
                    filterable: false,
                    texts: textos_tabla,
                    sortIcon: iconos_tabla,
                    perPage: 50,
                    orderBy: {
                        ascending: false
                    },
                    perPageValues: [25,50,100],
                    templates:{},
                    requestFunction: this.funcionEnvio
                }
            }
        },
    }
</script>
<style type="text/css">
    .tablaarticulos table td:nth-child(1){
        width: 125px;
    }
    .tablaarticulos table td:nth-child(2){
        width: 90px;
    }
    .tablaarticulos table td:nth-last-child(2){
        width: 150px;
    }
    .tablaarticulos table td:nth-last-child(1){
        width: 200px;
    }
</style>