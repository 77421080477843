<template>
    <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.nombre')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="nombre" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.apellidos')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="apellidos" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.idioma')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="idioma"
                                           :obligatorio="true"
                                           :elementos="elementos_idioma"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label><template v-if="!soy_admin">*</template>{{__('site.email')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="email" class="form-control"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.pais')}}</label>
                        <div class="form-group">
                            <select class="form-control" v-model="pais" @change="provincia=''">
                                <option v-for="item in paises" :value="item.id">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.provincia')}}</label>
                        <div class="form-group">
                            <select v-if="Object.keys(provincias).length"  class="form-control" v-model="provincia">
                                <option v-for="value in provincias" :value="value">{{value}}</option>
                            </select>
                            <input v-else type="text" v-model="provincia" class="form-control"  />
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.departamento')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="departamento" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>

            <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
            <div class="text-center" style="margin-top:30px;">
                <button type="button" class="btn boton" @click="$emit('close')">{{__('site.cancelar')}}</button>
                <boton-guardar @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
            </div>
    </div>
</template>

<script>
    import BotonGuardar from "../BotonGuardar";
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import BotonesRadio from "../utils/BotonesRadio";
    export default {
        created: function(){
            let vm = this;

            axios.get(route('usuarios.getpaises')
            ).then(response => {
                vm.paises = response.data.paises;
            });
        },
        mixins: [mixin],
        components: {BotonesRadio, BotonGuardar},
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            guardar: function() {
                let vm = this;
                vm.cargando = true;
                let data = {};

                let url_post = route('usuarios.storeautor');

                data = {
                    nombre: vm.nombre,
                    apellidos: vm.apellidos,
                    email: vm.email,
                    idioma: vm.idioma,
                    pais: vm.pais,
                    provincia: vm.provincia,
                    departamento: vm.departamento,
                };


                axios.post(url_post, data).then(response => {
                    vm.cargando = false;
                    vm.$emit('guardado',response.data.usuario);
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });

            }
        },
        computed:{
            ...mapState('auth',['usuario']),
            provincias: function(){
                let vm = this;
                if(vm.pais.length && vm.paises){
                    let pais_selected = vm.paises.find(e => e.id == vm.pais);
                    return pais_selected?pais_selected.provincias:{};
                }
                return {};
            },
            soy_admin: function(){
                let vm = this;
                switch (vm.usuario.rol){
                    case vm.roles.coordinador:
                    case vm.roles.administrador:
                        return true;
                    default:
                        return false;
                }
            }
        },
        data() {
            return {
                paises: [],
                nombre: '',
                apellidos: '',
                idioma: 'es',
                email: '',
                pais: 'ES',
                provincia: '',
                departamento: '',
                errores: [],
                cargando: false,
                elementos_idioma: [{nombre:this.__('site.espanol'),id:'es'},{nombre:this.__('site.ingles'),id:'en'}],
            }
        },
    }
</script>
