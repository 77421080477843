<template>
    <div>
        <div v-for="seccion in secciones" :key="seccion.id" v-if="seccion.articulos.length">
            <h3 class="seccion">{{seccion.nombre}}</h3>
            <draggable element="ul" v-model="seccion.articulos" @end="actualizaOrden" class="elementosorden">
                <li v-for="articulo in seccion.articulos" :key="articulo.id" style="cursor:pointer">
                    <div>
                        <i style="cursor:move;margin-right: 5px;" class="fas fa-grip-vertical"></i>
                    </div>
                    <div>
                        <strong>{{articulo.codigo}} - {{articulo.titulo_es}}</strong><br/>
                        {{articulo.autores_nombres}}
                    </div>
                </li>
            </draggable>
        </div>
        <div class="text-center">
            <button @click="$emit('cerrar')" class="btn boton">{{__('site.aceptar')}}</button>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import {es} from 'vuejs-datepicker/dist/locale';
    import mixin from '../../mixin.js'
    export default {
        created: function(){
            let vm = this;
            axios.get(route('articulos.getsecciones')).then(response => {
                vm.secciones = response.data.secciones;
                vm.secciones.forEach(function(seccion){
                    vm.$set(seccion,'articulos',vm.articulosByTipo(seccion.id));
                });
            });
        },
        components: {
            draggable,
        },
        mixins: [mixin],
        methods: {
            articulosByTipo: function(tipo){
                let vm = this;
                if(vm.articulos.length){
                    return vm.articulos.filter(e => e.tipo == tipo);
                }
                return [];
            },
            actualizaOrden: function(newIndex,oldIndex,element){
                let vm = this;
                vm.errores = [];
                let articulos_envio = [];
                vm.secciones.forEach(function(seccion){
                    let contador = 1;
                    seccion.articulos.forEach(function(item){
                        item.numero_orden = contador;
                        contador++;
                        articulos_envio.push(item);
                    });
                });

                axios.post(route('numeros.reordenar',{id:vm.$route.params.id}), {
                    articulos: articulos_envio,
                }).then(response => {
                    console.log('llega todo ok');
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                });
            }
        },
        props: ['articulos'],
        data() {
            return {
                es: es,
                secciones: [],
                cargando: false,
            }
        },
    }
</script>
