<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <div>
                <h1 >{{articulo.codigo}}-{{articulo.revision}}-{{ motrarEstadoArticulo(articulo.estado) }}</h1>
            </div>

            <vue-tabs
            v-if="loaded"
            @tab-change="actualizarHistorial"
            active-tab-color="#3f6893"
            active-text-color="white">
                <v-tab :title="__('site.datos')" icon="fas fa-info" key="1">
                    <articulos-show-metadatos :articulo="articulo"></articulos-show-metadatos>
                </v-tab>
                <v-tab :title="__('site.bibliografia')" icon="fas fa-list" key="2">
                    <ol class="bibliografia">
                        <li v-for="item in articulo.bibliografias">
                            {{item.texto}} <template v-if="item.doi"><a target="_blank" :href="item.doi">{{item.doi}}</a></template>
                        </li>
                    </ol>
                </v-tab>
                <v-tab :title="__('site.archivos')" icon="fas fa-file-alt"  key="3">
                    <articulos-show-files v-if="articulo.id" :articulo="articulo" @updated="actualizar"></articulos-show-files>
                </v-tab>
                <v-tab v-if="pestanas_info" :title="__('site.revisiones')" icon="fas fa-search"  key="4">
                    <articulos-show-revisiones :articulo="articulo"></articulos-show-revisiones>
                </v-tab>
                <v-tab  v-if="usuario.rol >= roles.director && articulo.estado >= articulo_estados.aceptado_modificaciones_menores" :title="__('site.decision_director')" icon="fas fa-comment-dots"  key="5">
                    <articulos-show-decisiones v-if="articulo.id" :articulo="articulo" :rol="roles.director"></articulos-show-decisiones>
                </v-tab>
                <v-tab v-if="usuario.rol >= roles.editor && articulo.estado >= articulo_estados.aceptado_editor" :title="__('site.decision_editor')" icon="fas fa-comment-dots"  key="6">
                    <articulos-show-decisiones v-if="articulo.id" :articulo="articulo" :rol="roles.editor"></articulos-show-decisiones>
                </v-tab>
                <v-tab v-if="usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador" :title="__('site.versiones')" icon="fas fa-history"  key="7">
                    <articulos-show-versiones v-if="articulo.id" :articulo="articulo" ></articulos-show-versiones>
                </v-tab>
                <v-tab v-if="usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador" :title="__('site.historial')" icon="fas fa-terminal"  key="8">
                    <articulos-show-historial v-if="articulo.id" :articulo="articulo" ref="historial" ></articulos-show-historial>
                </v-tab>
            </vue-tabs>
            <articulos-botonera :articulo="articulo" @updated="actualizar"></articulos-botonera>
        </div>
    </div>
</template>

<script>
    import {VueTabs, VTab} from 'vue-nav-tabs';
    import 'vue-nav-tabs/themes/vue-tabs.css';
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import ArticulosShowMetadatos from "./ArticulosShowMetadatos";
    import ArticulosBotonera from "./ArticulosBotonera";
    import ArticulosShowFiles from "./ArticulosShowFiles";
    import ArticulosShowRevisiones from "./ArticulosShowRevisiones";
    import ArticulosShowVersiones from "./ArticulosShowVersiones";
    import ArticulosShowHistorial from "./ArticulosShowHistorial";
    import ArticulosShowDecisiones from "./ArticulosShowDecisiones";
    export default {
        created: function(){
            this.actualizar();
        },
        mixins: [mixin],
        components: {
            ArticulosShowDecisiones,
            ArticulosShowHistorial,
            ArticulosShowVersiones,
            ArticulosShowRevisiones,
            ArticulosShowFiles,
            ArticulosBotonera,
            ArticulosShowMetadatos,
            VueTabs,
            VTab
        },
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            actualizar: function(){
                let vm = this;
                vm.setCargando(true);
                axios.get(route('articulos.show',{id:vm.$route.params.id}))
                    .then(response => {
                        vm.articulo = response.data.articulo;

                        this.checkPermisoArticulo(vm.articulo.id,this.articulo_acciones.ver_info_articulo, 1)
                            .then(response => {
                                vm.pestanas_info = response;
                                vm.loaded = true;
                                vm.setCargando(false);
                            });
                    });
            },
            actualizarHistorial(tabIndex, newTab, oldTab){
                if(newTab.tabId == this.__('site.historial')){
                    this.$refs.historial.actualizar();
                }
            }
        },
        computed:{
            ...mapState('auth',['usuario']),
        },
        data() {
            return {
                articulo: {},
                loaded: false,
                pestanas_info: false,
            }
        },
    }
</script>
