<template>
    <div class="error">
        <ul>
            <li v-for="error in errores">
                {{error}}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: [
          'errores'
        ]
    }
</script>
<style type="text/css">
    .error{
        margin: 30px 0;
        padding: 15px;
        border: 1px solid #990000;
        color: #990000;
        background: #ffb7b3;
    }

</style>