import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

import Home from './components/Home.vue'
import Login from './components/Login.vue'
import FirmarCoautoria from './components/FirmarCoautoria'
import BajaNewsletter from './components/BajaNewsletter'
import SeccionesIndex from "./components/secciones/SeccionesIndex";
import UsuariosIndex from "./components/usuarios/UsuariosIndex";
import UsuariosEdit from "./components/usuarios/UsuariosEdit";
import UsuariosEditPwd from "./components/usuarios/UsuariosEditPwd";
import ResetPassword from "./components/ResetPassword";
import OlvidoPassword from "./components/OlvidoPassword";
import LectoresIndex from "./components/lectores/LectoresIndex";
import LectoresEdit from "./components/lectores/LectoresEdit";
import ArticulosIndex from "./components/articulos/ArticulosIndex";
import CesionesIndex from "./components/articulos/CesionesIndex";
import ArticulosCreate from "./components/articulos/ArticulosCreate";
import ArticulosShow from "./components/articulos/ArticulosShow";
import ArticulosEdit from "./components/articulos/ArticulosEdit";
import RevisionesIndex from "./components/revisiones/RevisionesIndex";
import RevisionesShow from "./components/revisiones/RevisionesShow";
import RevisionesEdit from "./components/revisiones/RevisionesEdit";
import NumerosIndex from "./components/numeros/NumerosIndex";
import NumerosShow from "./components/numeros/NumerosShow";
import NumerosEdit from "./components/numeros/NumerosEdit";
import EmailPlantillasIndex from './components/configuracion/emailplantillas/EmailPlantillasIndex.vue'
import EmailPlantillasEdit from './components/configuracion/emailplantillas/EmailPlantillasEdit.vue'
import AjustesIndex from './components/configuracion/ajustes/AjustesIndex.vue'
import AyudaIndex from './components/ayuda/AyudaIndex.vue'
import ErrorPage from "./components/ErrorPage";


const routes = [
    /*{
        path: '/',
        name: 'home',
        redirect: {name: 'admin.home'}
    },*/
    {
        path: '/admin',
        name: 'admin.home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/login',
        name: 'admin.login',
        component: Login
    },
    {
        path: '/password/reset',
        name: 'password.reset.token',
        component: ResetPassword
    },
    {
        path: '/admin/password/forgot',
        name: 'password.forgot',
        component: OlvidoPassword
    },
    {
        path: '/articulos/autoria/:userid/:id',
        name: 'coautoria.index',
        component: FirmarCoautoria
    },
    {
        path: '/admin/secciones',
        name: 'admin.secciones.index',
        component: SeccionesIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/usuarios/baja-newsletter',
        name: 'bajanewsletter.index',
        component: BajaNewsletter
    },
    {
        path: '/admin/usuarios',
        name: 'admin.usuarios.index',
        component: UsuariosIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/usuarios/create',
        name: 'admin.usuarios.create',
        component: UsuariosEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/usuarios/:id/edit',
        name: 'admin.usuarios.edit',
        component: UsuariosEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/usuarios/:id/password',
        name: 'admin.usuarios.password',
        component: UsuariosEditPwd,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/lectores',
        name: 'admin.lectores.index',
        component: LectoresIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/lectores/create',
        name: 'admin.lectores.create',
        component: LectoresEdit,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/lectores/:id/edit',
        name: 'admin.lectores.edit',
        component: LectoresEdit,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/configuracion/ajustes',
        name: 'ajustes.index',
        component: AjustesIndex,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/configuracion/emailplantillas',
        name: 'emailplantillas.index',
        component: EmailPlantillasIndex,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/configuracion/emailplantillas/:id/edit',
        name: 'emailplantillas.edit',
        component: EmailPlantillasEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/articulos',
        name: 'articulos.index',
        component: ArticulosIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/mis-articulos',
        name: 'articulos.index.misarticulos',
        component: ArticulosIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/cesiones-pendientes',
        name: 'articulos.index.cesionespendientes',
        component: CesionesIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/articulos/create',
        name: 'articulos.create',
        component: ArticulosCreate,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/articulos/:id',
        name: 'articulos.show',
        component: ArticulosShow,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/articulos/:id/edit',
        name: 'articulos.edit',
        component: ArticulosEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/revisiones',
        name: 'revisiones.index',
        component: RevisionesIndex,
        meta: { requiresAuth: true  }
    },
    {
        path: '/admin/revisiones/:id',
        name: 'revisiones.show',
        component: RevisionesShow,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/revisiones/:id/edit',
        name: 'revisiones.edit',
        component: RevisionesEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/numeros',
        name: 'numeros.index',
        component: NumerosIndex,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/numeros/create',
        name: 'numeros.create',
        component: NumerosEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/numeros/:id/edit',
        name: 'numeros.edit',
        component: NumerosEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/numeros/:id',
        name: 'numeros.show',
        component: NumerosShow,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/ayuda',
        name: 'admin.ayuda',
        component: AyudaIndex,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/error/:error',
        name: 'error',
        component: ErrorPage
    },
    {
        path: '/admin/*',
        redirect: {name: 'error',params:{error:404}}
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isLoggedIn = store.state.auth.logged;
    if(!requiresAuth && isLoggedIn && to.path === '/admin/login'){
        next({ name: 'admin.home' });
    }

    if(requiresAuth && !isLoggedIn){
        store.dispatch('asignarUrl',to);
        next({ name: 'admin.login' });
    }
    else{
        if(requiresAuth){
            store.dispatch('setCurrentUser');
            let url = store.state.url;
            if(url){
                store.dispatch('asignarUrl',null);
                next(url);
            }
            else{
                next();
            }
        }
        else{
            next();
        }
    }

})

export default router
