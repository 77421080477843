<template>
    <div >
        <ventana-flotante
                @close="enviar_prueba = false"
                :titulo="__('site.enviar_prueba')"
                v-if="enviar_prueba">
            <div slot="body">
                <numeros-enviar-prueba :numero="numero" @cerrar="enviar_prueba = false"></numeros-enviar-prueba>
            </div>
        </ventana-flotante>
        <div>
            <iframe id="iframepreview" src="about:blank" style="width: 100%;height:600px;" />
        </div>
        <div class="text-center">
            <button @click="$emit('cerrar')" class="btn boton">{{__('site.salir')}}</button>
            <button @click="enviar_prueba = true" class="btn boton">{{__('site.enviar_prueba')}}</button>
            <button @click="download" class="btn boton">{{__('site.descargar')}}</button>
            <boton-guardar @guardar="enviar" v-if="numero.publicada" :cargando="cargando" ><template slot="texto">{{__('site.enviar')}}</template></boton-guardar>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarCampo from "../utils/MostrarCampo";
    import NumerosOrdenar from "./NumerosOrdenar";
    import MostrarFichero from "../utils/MostrarFichero";
    import NumerosEnviarPrueba from "./NumerosEnviarPrueba";
    import BotonGuardar from "../BotonGuardar";
    export default {
        components: {BotonGuardar, NumerosEnviarPrueba},
        created: function(){
            let vm = this;
            axios.get(route('numeros.previewnewsletter',{id:vm.numero.id})).then(response => {
                vm.previsualizacion = response.data;
                var doc = document.getElementById('iframepreview').contentWindow.document;
                doc.open();
                doc.write(vm.previsualizacion);
                doc.close();
            });
        },
        mixins: [mixin],
        methods: {
            download: function(){
                let vm = this;
                const url = window.URL.createObjectURL(new Blob([vm.previsualizacion]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', vm.numero.id+'.html');
                document.body.appendChild(link);
                link.click();
            },
            enviar: function(){
                let vm = this;
                swal({
                    text: vm.__('site.msg_envio_newsletter'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: 'Cancelar',
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.enviar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.cargando = true;
                        axios.post(route('numeros.enviar',{id:vm.numero.id})
                        ).then(function (response) {
                            swal({
                                text: vm.__('site.msg_envio_newsletter_ok'),
                                icon: 'success',
                                buttons: {
                                    aceptar: {
                                        text: vm.__('Aceptar'),
                                        value: true
                                    }
                                },
                            }).then(function(){
                                vm.cargando = false;
                                vm.$emit('cerrar');
                            });
                        })
                            .catch(function (error) {
                                console.log(error);
                                swal(error, 'error', 'error');
                            });
                    }
                });
            }
        },
        props: ['numero'],
        data() {
            return {
                previsualizacion: '',
                enviar_prueba: false,
                cargando: false,
            }
                
        },
    }
</script>
