<template>
    <div>
        <h2 style="margin-top:30px;">{{__('site.metadatos')}}</h2>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group">
                    <label>*{{__('site.idioma_articulo')}}</label>
                    <div class="form-group">
                        <botones-radio v-model="articulo.idioma"
                                       :obligatorio="true"
                                       :elementos="elementos_idioma"></botones-radio>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <label>*{{__('site.idioma_comunicaciones')}}</label>
                    <div class="form-group">
                        <botones-radio v-model="articulo.idioma_comunicaciones"
                                       :obligatorio="true"
                                       :elementos="elementos_idioma"></botones-radio>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group">
                    <label>*{{__('site.titulo_espanol')}}</label>
                    <div class="form-group">
                        <input type="text" v-model="articulo.titulo_es" class="form-control"  />
                    </div>
                </div>
                <div class="input-group">
                    <label>*{{__('site.resumen_espanol')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="articulo.resumen_es" ></textarea>
                    </div>
                </div>
                <div class="input-group">
                    <label>*{{__('site.tags_espanol')}}</label>
                    <div class="form-group">
                        <input type="text" v-model="articulo.tags_es" class="form-control"  />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <label>*{{__('site.titulo_ingles')}}</label>
                    <div class="form-group">
                        <input type="text" v-model="articulo.titulo_en" class="form-control"  />
                    </div>
                </div>
                <div class="input-group">
                    <label>*{{__('site.resumen_ingles')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="articulo.resumen_en" ></textarea>
                    </div>
                </div>
                <div class="input-group">
                    <label>*{{__('site.tags_ingles')}}</label>
                    <div class="form-group">
                        <input type="text" v-model="articulo.tags_en" class="form-control"  />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group">
                    <label>{{__('site.bibliografia')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="articulo.bibliografia" ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="input-group">
                    <label>{{__('site.organismos_colaboradores')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="articulo.organismos_colaboradores" ></textarea>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <label>{{__('site.conflictos_interes')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="articulo.conflictos_interes" ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:30px;">
            <div class="col-md-12">
                <h2>{{__('site.autores')}}</h2>
                <usuarios-select :autores="articulo.autores" @change="updateAutores"></usuarios-select>
            </div>
        </div>
        <div class="row" style="margin-top:20px;">
            <div class="col-md-12">
                <h2>{{__('site.observaciones_autor')}}</h2>
                <div class="input-group">
                    <label>{{__('site.observaciones')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="articulo.observaciones_autor"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import BotonesRadio from "../utils/BotonesRadio";
    import UsuariosSelect from "../usuarios/UsuariosSelect";
    export default {
        created: function(){
            let vm = this;
            if(!vm.articulo.autores){
                let user = Object.assign({}, this.usuario);
                user.correspondencia = 1;
                vm.articulo.autores = [user];
            }
            else{
                vm.articulo.autores.forEach(function(item){
                    item.correspondencia = item.pivot.correspondencia;
                })
            }

        },
        mixins: [mixin],
        components: {UsuariosSelect, BotonesRadio},
        methods: {
            ...mapMutations(['setCargando']),
            updateAutores: function(result){
                this.articulo.autores = result;
            }
        },
        computed: {
            ...mapState('auth',['usuario']),
        },
        props: ['articulo'],
        data() {
            return {
                elementos_idioma: [{nombre:this.__('site.espanol'),id:'es'},{nombre:this.__('site.ingles'),id:'en'}],
            }
        },
    }
</script>
