<template>
  <div class="content" style="width: 100%">
    <div class="container">
      <h1 v-if="esNuevo">
        {{ esNuevo ? __("site.add_lector") : __("site.editar_lector") }}
      </h1>

      <div class="row">
        <div class="col-md-6">
          <div class="input-group">
            <label>*{{ __("site.nombre") }}</label>
            <div class="form-group">
              <input
                type="text"
                required
                v-model="nombre"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <label>*{{ __("site.email") }}</label>
            <div class="form-group">
              <input
                type="text"
                required
                v-model="email"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="input-group">
            <label>*{{ __("site.idioma") }}</label>
            <div class="form-group">
              <botones-radio
                v-model="idioma"
                :obligatorio="true"
                :elementos="elementos_idioma"
              ></botones-radio>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <mostrar-errores
            v-if="errores.length"
            :errores="errores"
          ></mostrar-errores>
          <div class="text-center" style="margin-top: 30px">
            <router-link
              :to="{ name: 'admin.lectores.index' }"
              class="btn boton">{{ __("site.volver") }}</router-link>

            <boton-guardar
              class="btn boton"
              @guardar="guardar"
              :cargando="cargando">{{ __("site.guardar") }}</boton-guardar>

            <button v-if="$route.params.id"
              @click="borrar(__('site.msg_confirmar_eliminar'),
                          $route.params.id,
                          'lectores.destroy',
                          'admin.lectores.index')"
              class="btn boton" >{{__('site.eliminar')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import mixin from '../../mixin.js'
    import BotonGuardar from "../BotonGuardar";
    import BotonesRadio from "../utils/BotonesRadio";
    import {mapMutations, mapState} from 'vuex';

export default{

    created: function(){
            let vm = this;
            vm.setCargando(true);
            
            if(vm.$route.params.id){
                vm.esNuevo = false;
                axios.get(route('lectores.show',{'id':vm.$route.params.id})
                ).then(response => {
                    let reader = response.data.lector;
                    vm.nombre = reader.nombre;
                    vm.email = reader.email;
                    vm.idioma = reader.idioma;

                    vm.setCargando(false);
                });
            }
            else {
                vm.setCargando(false);
            }
    },
    mixins: [mixin],
    components: { BotonGuardar, BotonesRadio},
    methods: {

        

        ...mapMutations(['setCargando']),

        guardar: function() {
                    let vm = this;
                    vm.cargando = true;

                    let data = {};
                    let url_post = route('lectores.store');
                    if(vm.$route.params.id) {
                        url_post = route('lectores.update',{'id':vm.$route.params.id});
                        
                    }
                    axios.post(url_post, {
                        nombre: vm.nombre,
                        email: vm.email,
                        idioma: vm.idioma,
                    })
                        .then(response => {
                          vm.$router.push({ name: 'admin.lectores.index' });

                        }).catch(e => {
                          vm.errores = [];
                          for (let key in e.response.data.errors) {
                              vm.errores.push(e.response.data.errors[key][0]);
                          }
                          vm.cargando = false;
                    });
        },

    },
    data() {
            return {
                nombre: '',
                email: '',
                idioma: 'es',
                errores: [],
                cargando: false,
                esNuevo: true,
                elementos_idioma: [{nombre:this.__('site.espanol'),id:'es'},{nombre:this.__('site.ingles'),id:'en'}],
        }
    }


}
</script>
