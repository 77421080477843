<template>
    <div class="itemfile">
        <ventana-flotante
                :titulo="titulo"
                v-if="editar"
                @close="editar = false">
            <div slot="body">
                <vue-dropzone ref="dropZoneMostrar" id="dropzonemostrar" :options="dropzoneOptions"
                              @vdropzone-error="errorDropzone"
                              @vdropzone-file-added="cargando = true"
                              @vdropzone-upload-progress="dropzoneUploadProgress"
                              @vdropzone-success="fileUpdated"></vue-dropzone>
                <div v-if="cargando" style="font-size: 16px;margin-top: 15px;">
                    <strong>{{__('site.subiendo_fichero')}} {{progreso}} %</strong>
                </div>
            </div>
        </ventana-flotante>

        <h3 v-if="titulo">{{titulo}}</h3>
        <div v-if="fichero && fichero.length">
            <div>
                <i :class="['iconofile',icono]"></i>
            </div>
            <div>
                {{fichero}}
                <div v-if="fechaOn" style="font-size: 12px;">
                    {{mostrarFechaLarga(fecha_prep_data)}}
                </div>
                <slot name="infoextra"></slot>
            </div>
            <div>
                <a v-if="url_mostrar" :href="url_mostrar" target="_blank" :title="__('site.abrir')"><i class="fas fa-eye"></i></a>
                <a href="javascript:void(0)" @click="download" :title="__('site.descargar')"><i class="fas fa-download"></i></a>
                <a href="javascript:void(0)" @click="recargar" v-if="prepublicacion" :title="__('site.recargar')"><i class="fas fa-sync"></i></a>
                <a href="javascript:void(0)" v-if="url_envio" :title="__('site.enviar_crossref')" @click="enviar"><i class="fas fa-cloud-upload-alt"></i></a>
                <a href="javascript:void(0)" @click="editar = true" v-if="editable"  :title="__('site.editar')"><i class="fas fa-edit"></i></a>
                <a href="javascript:void(0)" @click="borrar" v-if="!obligatorio"  :title="__('site.eliminar')"><i class="fas fa-trash-alt"></i></a>
            </div>
            
        </div>
        <div v-else>
            <span>{{__('site.no_disponible')}}</span>
            <a href="javascript:void(0)" @click="editar = true" v-if="editable"><i class="fas fa-edit"></i></a>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import mixin from '../../mixin.js'
    import {mapState, mapMutations} from 'vuex'

    export default {
        created: function(){
            this.dropzoneOptions = {
                headers: {
                    Authorization: 'Bearer ' + this.token,
                },
                url: this.url_upload,
                thumbnailWidth: 100,
                autoProcessQueue: true,
                uploadMultiple: true,
                previewTemplate: this.template(),
                maxFilesize: 150,
                maxFiles: 1,
                acceptedFiles: "application/pdf,.odf,.doc,.docx",
                dictDefaultMessage: this.__('site.texto_upload'),
                dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
            },
            this.fecha_prep_data = this.fecha;
        },
        watch: {
            url_upload: function(){
                this.dropzoneOptions = {
                    headers: {
                        Authorization: 'Bearer ' + this.token,
                    },
                    url: this.url_upload,
                    thumbnailWidth: 100,
                    autoProcessQueue: true,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    maxFiles: 1, acceptedFiles: "application/pdf,.odf,.doc,.docx",
                    dictDefaultMessage: this.__('site.texto_upload'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                }
            }
        },
        computed: {
            ...mapState('auth',['token']),
           icono: function(){
               let vm = this;
               if(vm.fichero && vm.fichero.length){
                   let re = /(?:\.([^.]+))?$/;
                   let extension = re.exec(vm.fichero)[1];
                   switch (extension){
                       case 'pdf':
                           return 'fas fa-file-pdf';
                       case 'docx':
                       case 'doc':
                       case 'odf':
                           return 'fas fa-file-word';
                       case 'xls':
                       case 'xlsx':
                           return 'fas fa-file-excel';
                       case 'jpg':
                       case 'png':
                       case 'bmp':
                       case 'gif':
                           return 'fas fa-file-image';
                       case 'xml':
                           return 'fas fa-file-code';
                   }
               }
                return 'fas fa-file';
           }
        },
        methods: {
            ...mapMutations(['setCargando']),
          download: function(){
              let vm = this;
              axios({
                  url: vm.url,
                  method: "GET",
                  responseType: "blob",
              })
              .then(response => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', vm.fichero);
                  document.body.appendChild(link);
                  link.click();
              })
              .catch(error => {
                  console.log(error);
              });
          },
          recargar: function(){
              let vm = this;
              vm.setCargando(true);
              axios.post(vm.url_recargar)
              .then(response =>  {
                  vm.setCargando(false);
                  let icon = 'success';
                  let text = vm.__('site.prepublicado_actualizado');
                  vm.fecha_prep_data = response.data.fecha;
                  if(response.data.error){
                      icon = 'error';
                      text = response.data.error;
                  }
                   swal({
                        text: text,
                        icon: icon,
                        buttons: {
                            aceptar: {
                                text: vm.__('Aceptar'),
                                value: true
                            }
                        },
                   });
              })
          },
          enviar: function(){
              let vm = this;
              vm.setCargando(true);
              axios.post(vm.url_envio).then(response => {
                  vm.setCargando(false);
                  let icon = 'success';
                  let text = vm.__('site.envio_doi_correcto');
                  if(response.data.result.length){
                      icon = 'error';
                      text = response.data.result;
                  }

                  swal({
                          text: text,
                          icon: icon,
                          buttons: {
                              aceptar: {
                                  text: 'Aceptar',
                                  value: false
                              },
                          }
                      });
                  })
                  .catch(error => {
                      console.log(error);
                  });
          },
          fileUpdated: function(response){
            this.cargando = false;
            this.editar = false;
            this.$emit('updated');
          },
          dropzoneUploadProgress: function(file, totalBytes, totalBytesSent) {
                if(file.size >= totalBytesSent){
                    this.progreso = Math.round((totalBytesSent*100)/file.size);
                }
          },
          borrar: function(){
            let vm = this;
            axios.post(vm.url_destroy)
                .then(response => {
                    vm.$emit('updated',response.data.articulo)
                });
          }
        },
        mixins: [mixin],
        components: {vueDropzone: vue2Dropzone},
        props: {
            fichero: {
                type: String,
                default: ''
            },
            titulo: {
                type: String,
                default: ''
            },
            url: {
                type: String,
                default: ''
            },
            url_envio: {
                type: String,
                default: ''
            },
            url_upload: {
                type: String,
                default: ''
            },
            url_destroy: {
                type: String,
                default: ''
            },
            url_recargar:{
                type: String,
                default: ''
            },
            obligatorio: {
                type: Boolean,
                default: false
            },
            editable: {
                type: Boolean,
                default: true
            },
            fecha: {
                type: String,
            },
            prepublicacion: {
                type: Boolean,
                default: false
            },
            fechaOn: {
                type: Boolean,
                default: false
            },
            url_mostrar: {
                type: String,
                default: null
            }
        },
        data: function(){
            return {
                editar: false,
                dropzoneOptions: {},
                fecha_prep_data: [],
                cargando: false,
                progreso: 0,
            }
        }
    }
</script>