<template>
    <div>
        <p>
            {{__('site.asignar_revisores')}}
        </p>
        <table class="table table-striped table-bordered tablastatic">
            <thead>
            <tr>
                <th>{{__('site.revisor')}}</th>
                <th style="width: 50px"></th>
            </tr>
            </thead>
            <tbody>
                <tr :key="index" v-for="(item,index) in autores_data">
                    <td>
                                <strong>{{item.nombre_completo}}</strong><br/>
                                {{item.email}}
                    </td>
                    <td >
                        <a href="javascript:void(0)" class="icono" @click="eliminarAutor(item)"><i class="fas fa-trash-alt"></i></a>&nbsp;
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <autocomplete v-model="autor"
                                      clave="id"
                                      :placeholder="__('site.buscar_autor')"
                                      :excluir="autores_data"
                                      :valores="['nombre','apellidos','email']"
                                      :url="url_usuarios"></autocomplete>
                    </td>
                    <td>
                        <button @click="addItem()" :disabled="autores_data.length == 2" class="boton btn">
                            <i class="fas fa-plus"></i>
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>
        <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
        <div class="text-center">
            <button type="button" class="btn boton" @click="$emit('updated')">{{__('site.cancelar')}}</button>
            <button type="button" class="btn boton" :disabled="!autores_data.length" @click="enviar">{{__('site.enviar')}}</button>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import Autocomplete from "../utils/Autocomplete";
    import mixin from '../../mixin.js'
    export default {
        created: function(){
          this.url_usuarios = route('usuarios.index',{rol:this.roles.revisor,articulo:this.articulo.id});
        },
        components: {Autocomplete},
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            enviar: function(){
                let vm = this;
                vm.errores = [];

                vm.setCargando(true);
                axios.post(route('articulos.asignarrevisores',{id:vm.articulo.id}),{
                    revisores: vm.autores_data,
                })
                    .then(response => {
                        axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                            estado: vm.articulo_estados.revision,
                            texto: ''
                        })
                            .then(response => {
                                vm.setCargando(false);
                                vm.$emit('updated');
                            }).catch(e => {
                            vm.errores = [];
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.setCargando(false);
                        });
                    }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.setCargando(false);
                });
            },
            eliminarAutor: function(item){
                this.autores_data.splice(this.autores_data.indexOf(item),1);
            },
            addItem: function(){
                if(this.autor.id){
                    this.autores_data.push(this.autor);
                    this.autor = {};
                }
            },
        },
        props: ['articulo'],
        data() {
            return {
                errores: [],
                autor:  {},
                autores_data:  [],
                url_usuarios: '',
            }
        },
    }
</script>
