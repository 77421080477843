<template>
    <div>
            <div v-if="enviado">
                <p class="text-center" style="font-size: 20px;">{{__('site.msg_registro_ok')}}</p>
            </div>
            <div v-else>
                <div v-if="!cargando">

                    <h2>{{__('site.datos_personales')}}</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <label>*{{__('site.nombre')}}</label>
                                <div class="form-group">
                                    <input type="text" required v-model="nombre" class="form-control"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <label>*{{__('site.apellidos')}}</label>
                                <div class="form-group">
                                    <input type="text" required v-model="apellidos" class="form-control"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <label>*{{__('site.idioma')}}</label>
                                <div class="form-group">
                                    <botones-radio v-model="idioma"
                                                   :obligatorio="true"
                                                   :elementos="elementos_idioma"></botones-radio>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <label>{{__('site.nif')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="nif" class="form-control"  />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2>{{__('site.datos_contacto')}}</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <label>*{{__('site.email')}}</label>
                                <div class="form-group">
                                    <input type="text" required v-model="email" @change="checkEmail" class="form-control"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group">
                                <label>{{__('site.telefono')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="telefono" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <label>*{{__('site.pais')}}</label>
                                <div class="form-group">
                                    <select class="form-control" v-model="pais" @change="provincia=''">
                                        <option v-for="item in paises" :value="item.id">{{item.value}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <label>{{__('site.provincia')}}</label>
                                <div class="form-group">
                                    <select v-if="Object.keys(provincias).length"  class="form-control" v-model="provincia">
                                        <option v-for="value in provincias" :value="value">{{value}}</option>
                                    </select>
                                    <input v-else type="text" v-model="provincia" class="form-control"  />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <label>{{__('site.localidad')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="localidad" class="form-control"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group">
                                <label>{{__('site.codigo_postal')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="codigo_postal" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group">
                                <label>{{__('site.direccion')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="direccion" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2>{{__('site.datos_profesionales')}}</h2>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>{{__('site.orcid')}}</label>
                                <div class="form-group">
                                    <input type="text" v-mask="'####-####-####-####'" placeholder="____-____-____-____" v-model="orcid" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>{{__('site.web')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="web" class="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Departamento</h2>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>{{__('site.perfil_profesional')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="departamento" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>{{__('site.pais')}}</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="departamento_pais" @change="provincia=''">
                                            <option v-for="item in paises" :value="item.id">{{item.value}}</option>
                                        </select>
                                    </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>{{__('site.provincia')}}</label>
                        <div class="form-group">
                            <select v-if="Object.keys(dep_provincias).length"  class="form-control" v-model="departamento_provincia">
                                <option v-for="value in dep_provincias" :value="value">{{value}}</option>
                            </select>
                            <input v-else type="text" v-model="departamento_provincia" class="form-control"  />
                        </div>
                            </div>
                        </div>
                    </div>
                    <h2>{{__('site.datos_acceso')}}</h2>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>*{{__('site.usuario')}}</label>
                                <div class="form-group">
                                    <input type="text" v-model="usuario_text" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>*{{__('site.password')}}</label>
                                <div class="form-group">
                                    <input type="password" class="form-control" v-model="password" name="password" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <label>*{{__('site.repetir_password')}}</label>
                                <div class="form-group">
                                    <input type="password" class="form-control" v-model="password_confirmation" name="password_confirmation" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                    <div class="text-center">
                        <button type="button" @click="enviar" class="btn boton" >{{__('site.aceptar')}}</button>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <img src="/images/loader.svg" />
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
    import BotonesRadio from "../components/utils/BotonesRadio";
    import {mask} from 'vue-the-mask'
    export default {
        name: "formulario-contacto",
        components: {BotonesRadio},
        created: function(){
            let vm = this;
            axios.get(vm.url_paises
            ).then(response => {
                vm.paises = response.data.paises;
            });
        },
        methods: {
            checkEmail: function(){
                let vm = this;
                axios.get('/api/usuarios/checkuser',{
                    params:{
                        email: vm.email
                    }
                }).then(response => {
                    if(response.data.usuario){
                        swal({
                            text: vm.__('site.msg_ya_existe'),
                            icon: 'warning',
                            buttons: {
                                cancelar: {
                                    text: vm.__('site.cancelar'),
                                    value: false
                                },
                                aceptar: {
                                    text: vm.__('site.recuperar_password'),
                                    value: true
                                }
                            }
                        }).then(function(result){
                            if(result){
                                window.location.href = "/admin/password/forgot";
                            }
                        });
                    }
                });
            },
            enviar: function(){
                let vm = this;
                grecaptcha.ready(function() {
                    grecaptcha.execute(process.env.MIX_GOOGLE_RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
                        vm.cargando = true;
                        vm.errores = [];
                        axios.post(vm.url, {
                            nombre: vm.nombre,
                            apellidos: vm.apellidos,
                            email: vm.email,
                            idioma: vm.idioma,
                            nif: vm.nif,
                            telefono: vm.telefono,
                            pais: vm.pais,
                            provincia: vm.provincia,
                            localidad: vm.localidad,
                            codigo_postal: vm.codigo_postal,
                            direccion: vm.direccion,
                            web: vm.web,
                            orcid: vm.orcid,
                            departamento: vm.departamento,
                            departamento_pais: vm.departamento_pais,
                            departamento_provincia: vm.departamento_provincia,
                            usuario: vm.usuario_text,
                            interes_revision: '',
                            interes_otros: '',
                            activo: vm.activo,
                            rol: 0,
                            password: vm.password,
                            password_confirmation: vm.password_confirmation,
                        }).then(response => {
                            vm.cargando = false;
                            vm.enviado = true;
                        }).catch(e => {
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.cargando = false;
                        });
                    });
                });
            },
        },
        directives: {mask},
        computed: {
            provincias: function(){
                let vm = this;
                if(vm.pais.length && vm.paises){
                    let pais_selected = vm.paises.find(e => e.id == vm.pais);
                    return pais_selected?pais_selected.provincias:{};
                }
                return {};
            },
            dep_provincias: function(){
                let vm = this;
                if(vm.departamento_pais && vm.departamento_pais.length && vm.paises){
                    let pais_selected = vm.paises.find(e => e.id == vm.departamento_pais);
                    return pais_selected?pais_selected.provincias:{};
                }
                return {};
            }
        },
        props: ['url','url_paises'],
        data: function(){
            return {
                paises: [],
                nombre: '',
                apellidos: '',
                idioma: 'es',
                nif: '',
                email: '',
                telefono: '',
                pais: 'ES',
                provincia: '',
                localidad: '',
                codigo_postal: '',
                direccion: '',
                web: '',
                orcid: '',
                departamento: '',
                departamento_pais: '',
                departamento_provincia: '',
                usuario_text: '',
                password: '',
                password_confirmation: '',
                activo: 1,
                cargando: false,
                errores: [],
                enviado: false,
                elementos_idioma: [{nombre:this.__('site.espanol'),id:'es'},{nombre:this.__('site.ingles'),id:'en'}],
                elementos_sino: [{nombre:this.__('site.si'),id:1},{nombre:this.__('site.no'),id:0}],
            }
        }
    }
</script>

