<template>
    <div>
        <template v-if="usuario.rol != roles.editor">
            <p v-html="__('site.aceptar_articulo_director_texto')"></p>
        </template>
        <template v-else>
            <p v-html="__('site.aceptar_articulo_texto')"></p>
            <div class="input-group">
                <label>{{__('site.elige_opcion')}}</label>
                <div class="form-group">
                    <select class="form-control" v-model="estado">
                        <option value="0"></option>
                        <option :value="articulo_estados.aceptado_modificaciones_menores">{{__('site.aceptado_modificaciones_menores')}}</option>
                        <option :value="articulo_estados.aceptado_modificaciones_mayores">{{__('site.aceptado_modificaciones_mayores')}}</option>
                        <option :value="articulo_estados.aceptado_editor">{{__('site.aceptado')}}</option>
                    </select>
                </div>
            </div>
        </template>
            <div class="input-group">
                <label>{{__('site.observaciones')}}</label>
                <div class="form-group">
                    <textarea class="form-control" v-model="comentario" ></textarea>
                </div>
            </div>
            <div class="input-group">
                <label>{{__('site.ficheros_adjuntos')}}</label>
                <div class="form-group">
                    <vue-dropzone ref="dropZoneAdjuntos" id="dropzone" :options="dropzoneOptions"
                                  @vdropzone-file-added="updateFiles"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles"></vue-dropzone>
                </div>
            </div>
            <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
            <div class="text-center">
                <button type="button" class="btn boton" @click="$emit('updated')">{{__('site.cancelar')}}</button>
                <button type="button" class="btn boton" :disabled="!(articulo.revisiones && articulo.revisiones.length)" @click="importarRevision">{{__('site.importar_revision')}}</button>
                <button type="button" class="btn boton" :disabled="!comentario_editor.length" v-if="usuario.rol >= roles.director"  @click="importarEditor">{{__('site.importar_editor')}}</button>
                <button type="button" class="btn boton" :disabled="!estado" @click="enviar">{{__('site.enviar')}}</button>
            </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { get } from 'http';
    export default {
        created: function(){
            let vm = this;
            if(this.usuario.rol != this.roles.editor){
                this.estado = this.articulo_estados.aceptado;
            }

            axios.get(route('articulos.getcomentario',{id:vm.articulo.id,rol:vm.roles.editor}))
                .then(response => {
                    if(response.data.log && response.data.log.descripcion.length){
                        vm.comentario_editor = response.data.log.descripcion+"\n";
                    }
            });

        },
        methods: {
            ...mapMutations(['setCargando']),
            updateFiles: function(){
                let vm = this;
                setTimeout(() => {
                    vm.ficheros = vm.$refs.dropZoneAdjuntos.getAcceptedFiles();
                }, 1);
            },
            importarRevision: function(){
                let vm = this;
                if(vm.comentario.length){
                    vm.comentario += "\n\n";
                }
                if(vm.articulo.revisiones && vm.articulo.revisiones.length){
                    vm.articulo.revisiones.forEach(function(item){
                        vm.comentario += item.informe+"\n";
                    })
                }
            },
            importarEditor: function(){
                let vm = this;
                if(vm.comentario.length){
                    vm.comentario += vm.comentario_editor+"\n\n";
                }
                vm.comentario += vm.comentario_editor;
            },
            enviar: function(){
                let vm = this;
                vm.errores = [];

                let pen_en_curso = vm.articulo.revisiones.filter(revision => revision.estado == revision_estados.pendiente 
                || revision.estado == revision_estados.en_curso);
                if(pen_en_curso.length){
                            swal({
                    text: vm.__('site.decision_revisiones_pendientes'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: vm.__('site.cancelar'),
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.enviar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.envioRevisiones();
                    }
                })
                } else {
                    vm.envioRevisiones();
                }
                },
                envioRevisiones: function(){
                    let vm = this;
                    vm.setCargando(true);
                axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                    estado: vm.estado,
                    texto: vm.comentario
                }).then(response => {
                        let promises = [];
                        let formData = new FormData();
                        if(vm.ficheros.length){
                            for(let i=0;i<vm.ficheros.length;i++){
                                formData.append('file['+i+']', vm.ficheros[i]);
                            }
                            promises.push(axios.post(route('articulos.storefileseditor',{
                                tipo:(vm.usuario.rol == vm.roles.editor)?'editor':'director',
                                id:vm.articulo.id}),formData));
                        }
                        Promise.all(promises).then(function(results){
                            vm.setCargando(false);
                            vm.$emit('updated');
                            resolve(true);
                        });
                    }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.setCargando(false);
                });
                }
        },
        props: ['articulo'],
        mixins: [mixin],
        components: {vueDropzone: vue2Dropzone},
        computed: {
            ...mapState('auth',['usuario']),
        },
        data() {
            return {
                errores: [],
                ficheros: [],
                comentario: '',
                comentario_editor: '',
                estado: 0,
                dropzoneOptions: {
                    url: route('articulos.index'),
                    thumbnailWidth: 100,
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    addRemoveLinks: true,
                    dictDefaultMessage: this.__('site.texto_upload_multi'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                }
            }
        },
    }
</script>
