<template>
    <div>
        <p>
            {{__('site.solicitar_documentacion_texto')}}
        </p>
            <div class="input-group">
                <label>{{__('site.motivos')}}</label>
                <div class="form-group">
                    <textarea class="form-control" v-model="comentario" ></textarea>
                </div>
            </div>
            <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
            <div class="text-center">
                <button type="button" class="btn boton" @click="$emit('updated')">{{__('site.cancelar')}}</button>
                <button type="button" class="btn boton" @click="enviar">{{__('site.enviar')}}</button>
            </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    export default {
        methods: {
            ...mapMutations(['setCargando']),
            enviar: function(){
                let vm = this;
                vm.errores = [];
                if(vm.comentario.length){
                    vm.setCargando(true);
                    axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                        estado: vm.articulo_estados.pendiente_documentacion,
                        texto: vm.comentario
                    })
                        .then(response => {
                            vm.setCargando(false);
                            vm.$emit('updated');
                        }).catch(e => {
                        vm.errores = [];
                        for (let key in e.response.data.errors) {
                            vm.errores.push(e.response.data.errors[key][0]);
                        }
                        vm.setCargando(false);
                    });
                }
                else{
                    vm.errores.push(vm.__('site.msg_texto_error'));
                }
            }
        },
        props: ['articulo'],
        data() {
            return {
                errores: [],
                comentario: ''
            }
        },
    }
</script>
