<template>
    <div class="botonera-radio">
            <button
                    v-for="item in elementos"
                    :disabled="disabled" :class="colorpermiso(item.id)" @click="actualizaValor(item.id)">
                {{item.nombre}}
            </button>
    </div>
</template>

<script>
    export default {
        created: function(){
            if(this.model != null){

            }
            else{
                if(this.obligatorio && this.elementos.length){
                    this.actualizaValor(this.elementos[this.defecto].id);
                }
            }
        },
        computed: {
          model: {
              get(){
                  return this.value
              },
              set(val){
                  this.$emit('input',val);
                  this.$emit('change',val);
              }
          }
        },
        watch: {
          elementos: function(){
              if(this.obligatorio){
                  this.actualizaValor(this.elementos[this.defecto].id);
              }
          }
        },
        props: {
            value: [String,Number],
            elementos: Array,
            disabled: {
                type: Boolean,
                default: false
            },
            obligatorio: {
                type: Boolean,
                default: false
            },
            defecto: {
                type: Number,
                default: 0
            }
        },
        methods: {
            colorpermiso: function(valor){
                if(valor === this.model){
                    return 'checked';
                }
                return '';
            },
            actualizaValor: function(valor){
                if(!this.obligatorio && valor === this.model){
                    this.model = '';
                }
                else{
                    this.model = valor;
                }
            }
        },
        data(){
            return {

            }
        }
    }
</script>