<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <div>
                <h1 >{{__('site.evaluar')}} {{articulo.codigo}}-{{articulo.revision}}</h1>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mostrar-campo :label="__('site.titulo_espanol')" :valor="articulo.titulo_es"></mostrar-campo>
                </div>
                <div class="col-md-6">
                    <mostrar-campo :label="__('site.titulo_ingles')" :valor="articulo.titulo_en"></mostrar-campo>
                </div>
            </div>
            <h2 style="margin-top:30px;">{{__('site.decision')}}</h2>
            <div class="row">
                <div class="col-md-6">
                    <label>{{__('site.decision')}}</label>
                    <div class="form-group">
                        <botones-radio v-model="revision.evaluacion"
                                       :obligatorio="true"
                                       :elementos="elementos_evaluacion"></botones-radio>
                    </div>
                </div>
            </div>
            <h2 style="margin-top:30px;">{{__('site.informe')}}</h2>
            <div class="input-group">
                <label>{{__('site.informe_revision')}}</label>
                <div class="form-group">
                    <textarea class="form-control" v-model="revision.informe" ></textarea>
                </div>
            </div>
            <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
            <div class="text-center" style="margin-top:30px;">
                <router-link :to="{name:'revisiones.show',params:{id:revision.id}}" class="btn boton">{{__('site.volver')}}</router-link>
                <button class="btn boton" @click="enviar">{{__('site.enviar')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-nav-tabs/themes/vue-tabs.css';
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarCampo from "../utils/MostrarCampo";
    import BotonesRadio from "../utils/BotonesRadio";
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);
            axios.get(route('revisiones.show',{id:vm.$route.params.id}))
                .then(response => {
                    vm.articulo = response.data.articulo;
                    vm.revision = response.data.revision;
                    vm.setCargando(false);
                });
        },
        mixins: [mixin],
        components: {
            BotonesRadio,
            MostrarCampo,
        },
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            enviar: function(){
                let vm = this;
                vm.errores = [];
                swal({
                    text: vm.__('site.texto_enviar_revision'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: vm.__('site.cancelar'),
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.aceptar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.setCargando(true);
                        axios.post(route('revisiones.update',{id:vm.revision.id}),{
                            evaluacion: vm.revision.evaluacion,
                            informe: vm.revision.informe,
                        })
                            .then((response) => {
                                if(response.data.revisado){
                                    axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                                        estado: articulo_estados.revisado,
                                    })
                                        .then(() => {
                                            vm.$router.push({ name: 'revisiones.show',params:{id:vm.revision.id}  });
                                        });
                                }
                                else{
                                    vm.$router.push({ name: 'revisiones.show',params:{id:vm.revision.id}  });
                                }
                            }).catch(e => {
                                vm.errores = [];
                                for (let key in e.response.data.errors) {
                                    vm.errores.push(e.response.data.errors[key][0]);
                                }
                                vm.setCargando(false);
                            });
                    }

                });
            }
        },
        computed:{
            ...mapState('auth',['usuario']),
        },
        data() {
            return {
                errores: [],
                articulo: {},
                revision: {},
                elementos_evaluacion: [{nombre:this.__('decision_revision.1'),id:1},{nombre:this.__('decision_revision.2'),id:2},{nombre:this.__('decision_revision.3'),id:3}],
            }
        },
    }
</script>
