<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <form-wizard
                :title="__('site.nuevo_articulo')"
                subtitle=""
                :back-button-text="__('site.volver')"
                :next-button-text="__('site.siguiente')"
                :finish-button-text="__('site.guardar')"
                color="#3f6893"
                ref="wizard">

                <template slot="step" slot-scope="props">
                    <wizard-step :tab="props.tab"
                                 :transition="props.transition"
                                 :key="props.tab.title"
                                 :index="props.index">
                    </wizard-step>
                </template>
                <tab-content :title="__('site.tipo_articulo_derechos')" subtitle="" icon="far fa-copyright" :before-change="validarTipo">
                    <div class="row">
                         <div class="col-md-3">
                             <div class="input-group">
                                 <label>*{{__('site.tipo_articulo')}}</label>
                                 <div class="form-group">
                                     <select class="form-control" v-model="articulo.tipo">
                                         <option value=""></option>
                                         <option v-for="item in secciones" :value="item.id">{{item.nombre}}</option>
                                     </select>
                                 </div>
                             </div>
                         </div>
                        <div class="col-md-12">
                            <div style="margin:30px 0">
                                <p v-html="__('site.texto_declaracion_derechos')"></p>
                            </div>
                            <div class="form-group" v-for="index in 8">
                                <label
                                        class="checkboxcontainer checkboxinline">
                                    <span v-html="__('site.cesion_derechos_'+index)"></span>
                                    <input type="checkbox" v-model="cesion_derechos['cesion_'+index]" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <h2>{{__('site.descargas')}} </h2>
                                <a class="btn boton" download :href="'/storage/ficheros_descargar/checklist_'+idioma+'.pdf'"><i class="fas fa-download"></i> {{__('site.checklist')}}</a>
                                <a class="btn boton" download :href="'/storage/ficheros_descargar/blankform_'+idioma+'.pdf'"><i class="fas fa-download"></i> {{__('site.blank_form')}}</a>
                            </div>
                        </div>
                    </div>
                </tab-content>
                <tab-content :title="__('site.carga_metadatos')" icon="fas fa-info"  :before-change="validarMetadatos">
                    <articulos-edit-metadatos :articulo="articulo"></articulos-edit-metadatos>
                </tab-content>
                <tab-content  :title="__('site.carga_archivos')" icon="fas fa-file" :before-change="validarDocumentos">
                    <articulos-edit-documentos :articulo="articulo"></articulos-edit-documentos>
                </tab-content>
                <tab-content  :title="__('site.finalizar')" icon="fas fa-check" >
                    <p class="text-center">
                        {{__('site.articulo_cargado_ok')}}
                    </p>
                    <p class="text-center">
                        <button class="btn boton" @click="enviar">{{__('site.enviar_revision')}}</button>&nbsp;&nbsp;
                        <router-link class="btn boton" :to="{name:'articulos.show', params:{id:articulo.id}}">{{__('site.guardar_borrador')}}</router-link>
                    </p>
                </tab-content>
                <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                <template slot="footer" slot-scope="props">
                    <div class=wizard-footer-left v-if="!props.isLastStep">
                        <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">{{__('site.volver')}}</wizard-button>
                    </div>
                    <div class="wizard-footer-right">
                        <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right"
                                       :style="props.fillButtonStyle">
                            {{__('site.siguiente')}}
                        </wizard-button>
                    </div>
                </template>
            </form-wizard>
        </div>
    </div>
</template>

<script>
    import {FormWizard, TabContent,WizardButton, WizardStep} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import BotonesRadio from "../utils/BotonesRadio";
    import ArticulosEditMetadatos from "./ArticulosEditMetadatos";
    import ArticulosEditDocumentos from "./ArticulosEditDocumentos";
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(false);
            vm.articulo.id = 0;
            vm.articulo.new_articulos_carta = [];
            vm.articulo.new_articulos_completo = [];
            vm.articulo.new_articulos_ciego = [];
            vm.articulo.new_articulos_blankform = [];
            vm.articulo.new_articulos_checklist = [];
            vm.articulo.new_articulos_adjuntos = [];
            vm.articulo.new_articulos_traduccion = [];

            axios.get(route('articulos.getsecciones')).then(response => {
                vm.secciones = response.data.secciones;
            }) 
        },
        mixins: [mixin],
        components: {
            ArticulosEditDocumentos,
            ArticulosEditMetadatos, BotonesRadio, FormWizard,TabContent,WizardButton,WizardStep},
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            validarTipo: function(){
                let vm = this;
                vm.errores = [];
                return new Promise((resolve, reject) => {
                    if(!vm.articulo.tipo){
                        vm.errores.push(vm.__('site.msg_error_tipo_articulo'))
                    }
                    let cesiones = Object.keys(vm.cesion_derechos);
                    for(let i = 0; i < cesiones.length; i++){
                        if(!vm.cesion_derechos[cesiones[i]]){
                            vm.errores.push(vm.__('site.msg_error_derechos'));
                            break;
                        }
                    }

                    if(!vm.errores.length){
                        window.scrollTo(0,0);
                        resolve(true);
                    }else{
                        reject();
                    }
                });
            },
            validarMetadatos: function(){
                let vm = this;
                var max, min;
                vm.errores = [];
                return new Promise((resolve, reject) => {
                    if(!vm.articulo.titulo_es || !vm.articulo.titulo_es.length){
                        vm.errores.push(vm.__('site.msg_error_titulo_es'))
                    }

                    if(!vm.articulo.titulo_en || !vm.articulo.titulo_en.length){
                        vm.errores.push(vm.__('site.msg_error_titulo_en'))
                    }

                    if(!vm.articulo.resumen_es || !vm.articulo.resumen_es.length){
                        vm.errores.push(vm.__('site.msg_error_resumen_es'))
                    }
                    else{
                        if(vm.tipo_selected && vm.tipo_selected.max_resumen){
                            let palabras = vm.articulo.resumen_es.split(" ");
                            if(palabras.length > vm.tipo_selected.max_resumen){
                                vm.errores.push(vm.__('site.msg_error_resumen_max_es', {max: vm.tipo_selected.max_resumen}))
                            }
                        }
                    }

                    if(!vm.articulo.resumen_en || !vm.articulo.resumen_en.length){
                        vm.errores.push(vm.__('site.msg_error_resumen_en'))
                    }
                    else{
                        if(vm.tipo_selected && vm.tipo_selected.max_resumen){
                            let palabras = vm.articulo.resumen_en.split(" ");
                            if(palabras.length > vm.tipo_selected.max_resumen){
                                vm.errores.push(vm.__('site.msg_error_resumen_max_en', {max: vm.tipo_selected.max_resumen}))
                            }
                        }
                    }
                    let min = 1;
                    let max = 0;
                    if(vm.tipo_selected && vm.tipo_selected.min_clave){
                        min = vm.tipo_selected.min_clave;
                    }
                    if(vm.tipo_selected && vm.tipo_selected.max_clave){
                        max = vm.tipo_selected.max_clave;
                    }
                    let tags = '';
                    if(vm.articulo.tags_es){
                        tags = vm.articulo.tags_es.split(',');
                    }

                    if(tags.length < min || (max>0 && tags.length > max)){
                        vm.errores.push(vm.__('site.msg_error_tags_es', {min: min , max: max}))
                    }

                    tags = '';
                    if(vm.articulo.tags_en){
                        tags = vm.articulo.tags_en.split(',');
                    }
                    if(tags.length < min || (max>0 && tags.length > max)){
                        vm.errores.push(vm.__('site.msg_error_tags_en', {min: min , max: max}))
                    }

                    if(!vm.articulo.autores.length){
                        vm.errores.push(vm.__('site.msg_error_autores', {max: vm.tipo_selected.max_autores}))
                    }
                    else{
                        if(vm.tipo_selected && vm.tipo_selected.max_autores){
                            if(vm.articulo.autores.length > vm.tipo_selected.max_autores){
                                vm.errores.push(vm.__('site.msg_error_autores_max', {max: vm.tipo_selected.max_autores}))
                            }
                        }
                    }


                    if(vm.tipo_selected && vm.tipo_selected.max_bibliografia){
                        if(vm.articulo.bibliografia && vm.tipo_selected && vm.tipo_selected.max_bibliografia){
                            let tags = vm.articulo.bibliografia.split('\n');
                            if(tags.length > vm.tipo_selected.max_bibliografia){
                                vm.errores.push(vm.__('site.msg_error_bibliografia', {max: vm.tipo_selected.max_bibliografia}))
                            }
                        }
                    }

                    if(!vm.errores.length){
                        window.scrollTo(0,0);
                        resolve(true);
                    }else{
                        reject();
                    }
                });
            },
            validarDocumentos: function(){
                let vm = this;
                vm.errores = [];
                return new Promise((resolve, reject) => {
                    if(!vm.articulo.new_articulos_completo.length){
                        vm.errores.push(vm.__('site.msg_error_articulo_completo'))
                    }
                    if(!vm.articulo.new_articulos_ciego.length){
                        vm.errores.push(vm.__('site.msg_error_articulo_ciego'))
                    }
                    if(!vm.articulo.new_articulos_blankform.length){
                        vm.errores.push(vm.__('site.msg_error_blankform'))
                    }
                    if(!vm.articulo.new_articulos_checklist.length){
                        vm.errores.push(vm.__('site.msg_error_checklist'))
                    }

                    if(!vm.errores.length){
                        window.scrollTo(0,0);
                        vm.setCargando(true);
                        //GUARDAR ARTICULO
                        axios.post(route('articulos.store'),{
                            autores: vm.articulo.autores?vm.articulo.autores:[],
                            bibliografia: vm.articulo.bibliografia?vm.articulo.bibliografia:'',
                            observaciones_autor: vm.articulo.observaciones_autor?vm.articulo.observaciones_autor:'',
                            conflictos_interes: vm.articulo.conflictos_interes?vm.articulo.conflictos_interes:'',
                            idioma: vm.articulo.idioma?vm.articulo.idioma:'es',
                            idioma_comunicaciones: vm.articulo.idioma_comunicaciones?vm.articulo.idioma_comunicaciones:'es',
                            organismos_colaboradores: vm.articulo.organismos_colaboradores?vm.articulo.organismos_colaboradores:'',
                            resumen_en: vm.articulo.resumen_en?vm.articulo.resumen_en:'',
                            resumen_es: vm.articulo.resumen_es?vm.articulo.resumen_es:'',
                            tags_en: vm.articulo.tags_en?vm.articulo.tags_en:'',
                            tags_es: vm.articulo.tags_es?vm.articulo.tags_es:'',
                            tipo: vm.articulo.tipo?vm.articulo.tipo:0,
                            tipo_original: vm.articulo.tipo_original?vm.articulo.tipo_original:"",
                            titulo_en: vm.articulo.titulo_en?vm.articulo.titulo_en:'',
                            titulo_es: vm.articulo.titulo_es?vm.articulo.titulo_es:'',
                        }).then(response => {

                            let promises = [];
                            for(let i = 1; i <= 14; i++){
                                let ficheros = [];
                                switch (i){
                                    case 1:
                                        ficheros = vm.articulo.new_articulos_carta;
                                        break;
                                    case 2:
                                        ficheros = vm.articulo.new_articulos_completo;
                                        break;
                                    case 3:
                                        ficheros = vm.articulo.new_articulos_ciego;
                                        break;
                                    case 4:
                                        ficheros = vm.articulo.new_articulos_blankform;
                                        break;
                                    case 5:
                                        ficheros = vm.articulo.new_articulos_checklist;
                                        break;
                                    case 6:
                                        ficheros = vm.articulo.new_articulos_adjuntos;
                                        break;
                                    case 14:
                                        ficheros = vm.articulo.new_articulos_traduccion;
                                        break;
                                }
                                let formData = new FormData();
                                if(ficheros.length){
                                    for(let i=0;i<ficheros.length;i++){
                                        formData.append('file['+i+']', ficheros[i]);
                                    }
                                    promises.push(axios.post(route('articulos.storefiles',{id:response.data.articulo.id,tipo:i}),formData));
                                }

                            }
                            Promise.all(promises).then(function(results){
                                vm.setCargando(false);
                                vm.articulo = response.data.articulo;
                                resolve(true);
                            });

                        }).catch(e => {
                            vm.errores = [];
                            console.log(e);
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.setCargando(false);
                            reject();
                        });
                    }else{
                        reject();
                    }
                });
            },
            enviar: function(){
                let vm = this;
                vm.setCargando(true);
                axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                    estado: vm.articulo_estados.cargado
                })
                    .then(response => {
                        vm.setCargando(false);
                        vm.$router.push({name:'articulos.show',params:{id:response.data.articulo.id}});
                    }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.setCargando(false);
                    reject();
                });
            },
            descargar: function(){
                axios.get(route('articulos.descargar')).then(response => {
                    this.archivos = response.data.archivos;
                }) 
            },
        },
        computed:{
            ...mapState('auth',['usuario']),
            ...mapState(['idioma']),
            tipo_articulo: function(){
                return this.articulo.tipo;
            },
            tipo_selected: function(){
                let vm = this;
                if(vm.secciones.length && vm.articulo.tipo){
                    return vm.secciones.find(e => e.id == vm.articulo.tipo);
                }
                return null;
            }
        },
        watch:{
            tipo_articulo: function(){
                let vm = this;
                if((vm.articulo.tipo != vm.tipos_articulo.trabajo_original) || (vm.articulo.tipo != vm.tipos_articulo.trabajo_original_breve)){
                    vm.articulo.tipo_original = "";
                }
            }
        },
        data() {
            return {
                errores: [],
                articulo: {},
                cesion_derechos: {
                    cesion_1: false,
                    cesion_2: false,
                    cesion_3: false,
                    cesion_4: false,
                    cesion_5: false,
                    cesion_6: false,
                    cesion_7: false,
                    cesion_8: false,
                },
                secciones: [],
            }
        },
    }
</script>
