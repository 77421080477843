<template>
    <div class="content" style="width: 100%;">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <h1>{{__('site.cambiar_password')}}</h1>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{__('site.password')}}</label>
                                <input type="password" class="form-control" v-model="password" name="password" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{__('site.repetir_password')}}</label>
                                <input type="password" class="form-control" v-model="password_confirmation" name="password_confirmation" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                    <div class="text-center" style="margin-top:30px;">
                        <button class="btn boton" @click="volver">{{__('site.cancelar')}}</button>
                        <boton-guardar @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BotonGuardar from "../BotonGuardar";
    import {mapMutations, mapState} from 'vuex';
    export default {
        created: function(){
            let vm = this;
            if(vm.usuario.rol !=  vm.roles.administrador && vm.$route.params.id != vm.usuario.id){
                vm.$router.push({name:'admin.home'});
            }
        },
        components: { BotonGuardar},
        methods: {
            ...mapMutations(['setCargando']),
            volver: function(){
                let vm = this;
                vm.$router.push(vm.prevRoute);
            },
            guardar: function() {
                let vm = this;
                vm.cargando = true;

                axios.post(route('usuarios.password',{id: vm.$route.params.id}), {
                    password: vm.password,
                    password_confirmation: vm.password_confirmation
                }).then(response => {
                    vm.$router.push(vm.prevRoute);
                }).catch(e => {
                    vm.cargando = false;
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);

                    }
                });

            }
        },
        computed:{
            ...mapState('auth',['usuario']),
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        data() {
            return {
                prevRoute: null,
                password: '',
                password_confirmation: '',
                errores: [],
                cargando: false,
                esNuevo: true,
            }
        },
    }
</script>
