<template>
    <div >
        <ventana-flotante
                v-if="show_correccion"
                @close="show_correccion = false">
            <div slot="body">
                <div class="input-group">
                    <label>*{{__('site.comentario_autor')}}</label>
                    <div class="form-group">
                        <textarea required v-model="correccion_comentario" class="form-control" ></textarea>
                    </div>
                </div>
                <div class="input-group">
                    <label>{{__('site.fichero_adjunto')}}</label>
                    <div class="form-group">
                        <vue-dropzone ref="dropZoneCorreccion" id="dropzonecorreccion" :options="dropzoneOptions"
                                      @vdropzone-file-added="updateFilesCorreccion()"
                                      @vdropzone-error="errorDropzone"
                                      @vdropzone-removed-file="updateFilesCorreccion()"></vue-dropzone>
                    </div>
                </div>
                <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                <div class="text-center" style="margin-top:30px;">
                    <button type="button" class="btn boton" @click="show_correccion = false">{{__('site.cancelar')}}</button>
                    <boton-guardar @guardar="saveCorreccion" :cargando="cargando_correccion" >{{__('site.guardar')}}</boton-guardar>
                </div>
            </div>
        </ventana-flotante>
        <ventana-flotante
                v-if="show_modificar"
                @close="show_modificar = false">
            <div slot="body">
                <div class="input-group">
                    <label>{{__('site.fichero_maquetado')}}</label>
                    <div class="form-group">
                        <vue-dropzone ref="dropZoneModificar" id="dropzonemodificar" :options="dropzoneOptions"
                                      @vdropzone-file-added="updateFilesModificar()"
                                      @vdropzone-error="errorDropzone"
                                      @vdropzone-removed-file="updateFilesModificar()"></vue-dropzone>
                    </div>
                </div>
                <div class="text-center" style="margin-top:30px;">
                    <button type="button" class="btn boton" @click="show_modificar = false">{{__('site.cancelar')}}</button>
                    <boton-guardar @guardar="update" :cargando="cargando_correccion" >{{__('site.guardar')}}</boton-guardar>
                </div>
            </div>
        </ventana-flotante>
        <ventana-flotante
                v-if="show_enviar"
                @close="show_enviar = false">
            <div slot="body">
                <div class="input-group">
                    <label>{{__('site.observaciones')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="observaciones" ></textarea>
                    </div>
                </div>
                <div class="text-center" style="margin-top:30px;">
                    <button type="button" class="btn boton" @click="show_enviar = false">{{__('site.cancelar')}}</button>
                    <button @click="enviar" class="btn boton" >{{__('site.enviar')}}</button>
                </div>
            </div>
        </ventana-flotante>

        <table class="table table-striped table-bordered tablastatic">
            <thead>
            <tr>
                <th width="100px">{{__('site.revision')}}</th>
                <th width="170px">{{__('site.fecha')}}</th>
                <th width="170px">{{__('site.fecha_envio')}}</th>
                <th width="170px">{{__('site.fecha_confirmacion')}}</th>
                <th style="width: 116px"></th>
            </tr>
            </thead>
            <tbody>
                <template v-for="(item,index) in articulos_data">
                    <tr>
                        <td>
                            {{item.version}}
                        </td>
                        <td >
                            {{mostrarFechaLarga(item.fecha)}}
                        </td>
                        <td >
                            {{mostrarFechaLarga(item.fecha_envio?item.fecha_envio:'')}}
                        </td>
                        <td >
                            <template v-if="!item.editar">
                                {{mostrarFecha(item.fecha_confirmacion?item.fecha_confirmacion:'')}}
                            </template>
                            <div style="display: flex;align-items: center" v-else>
                                <datepicker format="dd-MM-yyyy"
                                            :language="es"
                                            v-model="item.fecha_confirmacion"
                                            :monday-first="true"
                                            input-class="form-control inputfecha"
                                            placeholder=""></datepicker>
                                &nbsp;<a href="javascript:void(0)" @click="guardarFecha(item)"><i class="fas fa-save"></i></a>
                            </div>
                            <template v-if="editar && item.fecha_confirmacion">
                                <a v-if="item.editar == 0" href="javascript:void(0)" @click="item.editar = 1"><i class="fas fa-edit"></i></a>
                            </template>
                        </td>
                        <td class="text-right">
                            <a :href="item.url_documento" target="_blank" class="icono" :title="__('site.abrir')"><i class="fas fa-eye"></i></a>&nbsp;
                            <a href="javascript:void(0)" @click="downloadFile(item)" class="icono" :title="__('site.descargar')"><i class="fas fa-download"></i></a>&nbsp;
                            <template v-if="editar">
                                <a href="javascript:void(0)" @click="modificar(item)" class="icono" :title="__('site.editar')"><i class="fas fa-edit"></i></a>&nbsp;
                            </template>
                            <template v-if="item.validada != 2 && ((index+1) == articulos_data.length)">
                                <a href="javascript:void(0)" class="icono" :title="__('site.validar')" @click="validar"><i class="far fa-check-circle"></i></a>&nbsp;
                            </template>
                            <template v-if="editar && item.validada != 2 && ((index+1) == articulos_data.length)">
                                <a href="javascript:void(0)" class="icono" :title="__('site.enviar')" @click="show_enviar = true"><i class="fas fa-envelope"></i></a>&nbsp;
                            </template>
                            <template v-if="((index+1) == articulos_data.length) && item.validada != 2">
                                <a href="javascript:void(0)" @click="show_correccion = true" class="icono" :title="__('site.enviar_correccion')"><i class="fas fa-highlighter"></i></a>&nbsp;
                            </template>
    
                        </td>
                    </tr>
                    <tr>
                    <td colspan="5" v-if="item.observaciones || item.comentario_autor" >
                        <p v-if="item.observaciones">
                            <b>{{__('site.observaciones')}}:</b>
                            {{item.observaciones}}
                        </p>
                    <p v-if="item.comentario_autor">
                        <b>{{__('site.comentario_autor')}}:</b><br/>
                        <a href="javascript:void(0)" @click="downloadFile(item,1)" v-if="item.nombre_correccion">{{__('site.descargar')}}</a>
                        <span style="white-space: pre;">{{item.comentario_autor}}</span>
                    </p>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tr  v-if="editar">
                <td colspan="4">
                    <vue-dropzone ref="dropZoneMaquetados" id="dropzonemaquetados" :options="dropzoneOptions"
                                  @vdropzone-file-added="updateFiles()"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles()"></vue-dropzone>
                </td>
                <td>
                    <button @click="addItem()" class="boton btn">
                        <i class="fas fa-plus"></i>
                    </button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

    import moment from 'moment';
    import vue2Dropzone from 'vue2-dropzone'
    import {mapMutations, mapState} from 'vuex';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import mixin from '../../mixin.js'
    import BotonGuardar from "../BotonGuardar";
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    export default {
        created: function(){
            let vm = this;
            vm.articulos_data = vm.articulo.ficheros_maquetados.filter(e => e.idioma == vm.idioma);
            vm.articulos_data.forEach(function(item){
               vm.$set(item,'editar',0);
            });
            this.dropzoneOptions = {
                url: route('usuarios.index'),
                thumbnailWidth: 100,
                autoProcessQueue: false,
                uploadMultiple: false,
                previewTemplate: this.template(),
                maxFilesize: 150,
                maxFiles: 1,
                acceptedFiles: ".pdf",
                addRemoveLinks: true,
                dictDefaultMessage: this.__('site.texto_upload'),
                dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
            };
            vm.checkPermisoArticulo(vm.articulo.id,vm.articulo_acciones.editar_maqueta)
                .then(response => {
                    vm.editar = response;
                });
        },
        mixins: [mixin],
        components: {Datepicker, BotonGuardar, vueDropzone: vue2Dropzone},
        methods: {
            ...mapMutations(['setCargando']),
            addItem: function(){
                let vm = this;
                if(vm.ficheros_nuevo.length){
                    vm.setCargando(true);
                    let formData = new FormData();
                    formData.append('idioma',vm.idioma);
                    for(let i=0;i<vm.ficheros_nuevo.length;i++){
                        formData.append('file['+i+']', vm.ficheros_nuevo[i]);
                    }
                    axios.post(route('ficherosmaquetados.store',{id:vm.articulo.id}),formData).then(
                        response => {
                            vm.articulos_data.push(response.data.fichero);
                            vm.ficheros_nuevo = [];
                            vm.$refs.dropZoneMaquetados.removeAllFiles()
                            vm.setCargando(false);
                        }
                    ).catch(e => {
                        vm.setCargando(false);
                    });

                }
            },
            update: function(){
                let vm = this;
                if(vm.ficheros_modificar.length){
                    vm.cargando_correccion = true;
                    let formData = new FormData();
                    formData.append('idioma',vm.idioma);
                    for(let i=0;i<vm.ficheros_modificar.length;i++){
                        formData.append('file['+i+']', vm.ficheros_modificar[i]);
                    }
                    axios.post(route('ficherosmaquetados.update',{id:vm.articulo.id,idfile:vm.selected_file}),formData).then(
                        response => {
                            vm.cargando_correccion = false;
                            vm.show_modificar = false;
                            let documento = vm.articulos_data.find(e => e.id == vm.selected_file);
                            if(documento){
                                vm.articulos_data[vm.articulos_data.indexOf(documento)] = response.data.fichero;
                            }
                            vm.ficheros_modificar = [];
                            vm.$refs.dropZoneModificar.removeAllFiles()
                        }
                    ).catch(e => {
                        vm.cargando_correccion = false;
                    });

                }
            },
            guardarFecha: function(item){
                let vm = this;
                axios.post(route('ficherosmaquetados.updatefecha',{id:vm.articulo.id,idfile:item.id}),{
                    fecha: item.fecha_confirmacion?moment(item.fecha_confirmacion).format('DD-MM-YYYY'):''
                }).then(response => {
                        item.editar = 0;
                    }
                ).catch(e => {
                });
            },
            validar: function(){
                let vm = this;
                vm.setCargando(true);
                axios.post(route('ficherosmaquetados.validar',{id:vm.articulo.id}),{
                    idioma: vm.idioma
                }).then(response => {
                        vm.articulos_data[vm.articulos_data.length-1] = response.data.fichero;
                        vm.checkPermisoArticulo(vm.articulo.id,vm.articulo_acciones.editar_maqueta)
                            .then(response => {

                                vm.setCargando(false);
                                vm.editar = response;
                                swal({
                                    text: vm.__('site.articulo_validado'),
                                    icon: 'success',
                                    buttons: {
                                        aceptar: {
                                            text: vm.__('Aceptar'),
                                            value: true
                                        }
                                    },
                                });
                            });
                    }
                ).catch(e => {
                });
            },
            enviar: function(){
                let vm = this;
                vm.setCargando(true);
                axios.post(route('ficherosmaquetados.enviar',{id:vm.articulo.id}),{
                    idioma: vm.idioma,
                    observaciones: vm.observaciones,
                }).then(response => {
                        vm.articulos_data[vm.articulos_data.length-1] = response.data.fichero;
                        vm.setCargando(false);
                        vm.show_enviar =  false;
                    }
                ).catch(e => {
                    vm.setCargando(false);
                });
            },
            modificar: function(item){
               this.show_modificar = true;
               this.selected_file = item.id;
            },
            saveCorreccion: function(){
                let vm = this;
                vm.cargando_correccion = true;
                vm.errores = [];
                let formData = new FormData();
                formData.append('comentario_autor',vm.correccion_comentario);
                formData.append('observaciones',vm.observaciones);
                formData.append('idioma',vm.idioma);
                if(vm.ficheros_correccion.length) {
                    for (let i = 0; i < vm.ficheros_correccion.length; i++) {
                        formData.append('file[' + i + ']', vm.ficheros_correccion[i]);
                    }
                }
                axios.post(route('ficherosmaquetados.updatecorreccion',{id:vm.articulo.id}),formData).then(
                    response => {
                        vm.cargando_correccion = false;
                        vm.show_correccion = false;
                        vm.articulos_data[vm.articulos_data.length-1] = response.data.fichero;
                        vm.ficheros_correccion = [];
                        vm.$refs.dropZoneCorreccion.removeAllFiles()
                    }
                ).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando_correccion = false;
                });

            },
            downloadFile: function(item,tipo=0){ //tipo = 0 => fichero maquetado ; tipo = 1 => fichero correccion
                let nombre = item.nombre;
                let params = {};
                if(tipo == 1){
                    nombre = item.nombre_correccion;
                    params = {
                        tipo: 1
                    }
                }
                axios({
                    url: route('ficherosmaquetados.download',{idarticulo:item.articulo_id,id:item.id,filename:nombre}),
                    method: "GET",
                    responseType: "blob",
                    params: params
                })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', nombre);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            updateFiles: function(){
                let vm = this;
                setTimeout(() => {
                    vm.ficheros_nuevo = vm.$refs.dropZoneMaquetados.getAcceptedFiles();
                }, 1);
            },
            updateFilesCorreccion: function(){
                let vm = this;
                setTimeout(() => {
                    vm.ficheros_correccion = vm.$refs.dropZoneCorreccion.getAcceptedFiles();
                }, 1);
            },
            updateFilesModificar: function(){
                let vm = this;
                setTimeout(() => {
                    vm.ficheros_modificar = vm.$refs.dropZoneModificar.getAcceptedFiles();
                }, 1);
            }
        },
        props: ['articulo','idioma'],
        data() {
            return {
                es: es,
                errores:  [],
                articulos_data:  [],
                ficheros_nuevo:  [],
                ficheros_correccion:  [],
                ficheros_modificar:  [],
                cargando_correccion: false,
                show_correccion: false,
                show_modificar: false,
                show_enviar: false,
                editar:  false,
                selected_file: 0,
                url_usuarios: route('usuarios.index'),
                dropzoneOptions: {},
                correccion_comentario: '',
                observaciones: '',
            }
        },
    }
</script>