<template>
    <label class="checkboxcontainer">
        <input type="checkbox" v-model="localValue" value="1" :disabled="readonly" >
        <span class="checkmark"></span>
        {{label}}
    </label>
</template>

<script>
    export default {
        watch: {
            localValue (newValue) {
                this.$emit('input', newValue)
            },
            value (newValue) {
                this.localValue = this.value
            }
        },
        props: {
            value: [Number,Boolean,String],
            label: {
                type: String,
                default: ''
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                localValue: this.value,
            }
        }
    }
</script>