<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <h1>{{__('site.email_plantillas')}}</h1>

            <div class="row">
                <div class="col-md-12">
                    <div class="input-group">
                        <label>*{{__('site.nombre_carta')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="plantilla.nombre" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.asunto')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="plantilla.asunto_es" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.asunto_ingles')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="plantilla.asunto_en" class="form-control"  />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.texto')}}</label>
                        <div class="form-group">
                            <textarea style="min-height: 200px;" class="form-control" v-model="plantilla.texto_es"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.texto_ingles')}}</label>
                        <div class="form-group">
                            <textarea style="min-height: 200px;" class="form-control" v-model="plantilla.texto_en"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <p>{{__('site.plantillas_ayuda')}}:</p>
                <ul>
                    <li v-for="(value,clave) in email_variables">
                        {{mostrarValor(clave)}}: <strong>{{value}}</strong> {{clave == 'cuerpo'?mostrar_informacion:''}}
                    </li>
                </ul>
            </div>

        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                    <div class="text-center" style="margin-top:30px;">
                        <router-link :to="{name:'emailplantillas.index'}" class="btn boton">{{__('site.volver')}}</router-link>
                        <button type="button" class="btn boton" @click="restaurar" :disabled="esRestaurable" >{{__('site.restaurar')}}</button>
                        <boton-guardar @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BotonGuardar from "../../BotonGuardar";
    import mixin from '../../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);
            console.log(this.__('site.email_variables'));
            axios.get(route('emailplantillas.show',{'id':vm.$route.params.id})
            ).then(response => {
                vm.plantilla = response.data.plantilla;
                vm.email_variables = response.data.variables;
                vm.setCargando(false);
            });

        },
        mixins: [mixin],
        components: {BotonGuardar},
        computed: {
            esRestaurable: function(){
                let vm = this;
                if(vm.plantilla.asunto_es === vm.plantilla.asunto_es_original &&
                    vm.plantilla.asunto_en === vm.plantilla.asunto_en_original &&
                    vm.plantilla.texto_es === vm.plantilla.texto_es_original &&
                    vm.plantilla.texto_en === vm.plantilla.texto_en_original){
                    return true;
                }
                return false;
            }
        },
        methods: {
            ...mapMutations(['setCargando']),
            guardar: function() {
                let vm = this;
                vm.cargando = true;
                axios.post(route('emailplantillas.update',{'id':vm.$route.params.id}), {
                    nombre: vm.plantilla.nombre,
                    asunto_es: vm.plantilla.asunto_es,
                    asunto_en: vm.plantilla.asunto_en,
                    texto_es: vm.plantilla.texto_es,
                    texto_en: vm.plantilla.texto_en,
                }).then(response => {
                    vm.$router.push({ name: 'emailplantillas.index' });
                    vm.cargando = false;
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });

            },
            restaurar: function(){
                let vm = this;
                swal({
                    text: vm.__('site.restaurar_texto'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: vm.__('site.cancelar'),
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.restaurar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.setCargando(true);
                        axios.post(route('emailplantillas.restaurar',{'id':vm.$route.params.id}))
                            .then(response => {
                                vm.plantilla = response.data.plantilla;
                                vm.setCargando(false);
                            }).catch(e => {
                            vm.errores = [];
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.setCargando(false);
                        });
                    }
                });
            },
            mostrarValor: function(clave){
                return '${'+clave+'}';
            }
        },
        data() {
            return {
                plantilla: {},
                email_variables: [],
                errores: [],
                cargando: false,
            }
        },
    }
</script>
