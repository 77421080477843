<template>
    <div>
        <div v-if="articulo.id" class="text-center botoneraarticulo" style="margin-top:30px;">
            <button type="button" @click="volver" class="btn boton">{{__('site.volver')}}</button>
            <button type="button" @click="updateEstado(articulo_estados.preparacion,__('site.modificar_articulo_aceptar'))" v-if="modificar_articulo"  class="btn boton">{{__('site.modificar_articulo')}}</button>
            <button class="btn boton" v-if="validar_articulo" @click="updateEstado(articulo_estados.pendiente_asignacion,__('site.enviar_pdte_asignacion'))">{{__('site.validar')}}</button>
            <button type="button" @click="ejecutarAccion('AsignarEditor')" v-if="asignar_editor"  class="btn boton">{{__('site.asignar_editor')}}</button>
            <button type="button" @click="ejecutarAccion('AceptarArticulo')" v-if="aceptado"  class="btn boton">{{__('site.aceptar')}}</button>
            <button type="button" @click="ejecutarAccion('RezacharArticulo')" v-if="rechazado"  class="btn boton">{{__('site.rechazar')}}</button>
            <button type="button" @click="ejecutarAccion('EnviarRevision')" v-if="revisar && !revisar_nuevamente"  class="btn boton">{{__('site.enviar_revision')}}</button>
            <button class="btn boton" v-if="revisar_nuevamente" @click="updateEstado(articulo_estados.revisar_nuevamente,__('site.revisar_nuevamente_texto'))">{{__('site.revisar_nuevamente')}}</button>
            <button class="btn boton" v-if="enviar_revision" @click="updateEstado(articulo_estados.cargado,__('site.enviar_a_revision'))">{{__('site.enviar_revision')}}</button>
            <button type="button" @click="ejecutarAccion('CorregirArticulo')" v-if="corregir"  class="btn boton">{{__('site.corregir')}}</button>
            <button type="button" @click="ejecutarAccion('CorregidoArticulo')" v-if="corregido"  class="btn boton">{{__('site.corregido')}}</button>
            <button type="button" @click="updateEstado(articulo_estados.prepublicacion,__('site.aviso_doigenerado'))" v-if="prepublicar"  class="btn boton">{{__('site.doi_generado')}}</button>
            <button type="button" @click="generarWM" v-if="prepublicado && (articulo.estado == articulo_estados.prepublicacion || articulo.estado == articulo_estados.preasignado_numero) && !articulo.prepublicado"  class="btn boton">{{__('site.prepublicar')}}</button>
            <button type="button" @click="generarWM" v-if="prepublicado && (articulo.estado == articulo_estados.prepublicacion || articulo.estado == articulo_estados.preasignado_numero) && articulo.prepublicado"  class="btn boton">{{__('site.retirar_prepublicacion')}}</button>
            <button type="button" @click="ejecutarAccion('SolicitarDocumentacion')" v-if="solicitar_documentacion"  class="btn boton">{{__('site.solicitar_documentacion')}}</button>
            <button type="button" @click="ejecutarAccion('EditarSeccion')" v-if="seccion_editada"  class="btn boton">{{__('site.editar_seccion')}}</button>
            <router-link v-if="editar_metadatos" :to="{name:'articulos.edit',params:{id:articulo.id}}" class="btn boton">{{__('site.editar_articulo')}}</router-link>
            <button type="button" @click="ejecutarAccion('RechazadoAutor')" v-if="rechazado_autor"  class="btn boton">{{__('site.rechazado_autor')}}</button>
            <button type="button" @click="updateEstado(articulo_estados.eliminado,__('site.eliminar_articulo'))" v-if="eliminar_articulo"  class="btn boton">{{__('site.eliminar')}}</button>
        </div>

        <ventana-flotante
                :titulo="titulo"
                v-if="ventana_visible"
                @close="ventana_visible = false">
            <div slot="body">
                <div :is="currentComponent"
                     v-bind="currentProps"
                     @updated="actualizar"></div>
            </div>
        </ventana-flotante>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import SolicitarDocumentacion from './SolicitarDocumentacion'
    import RechazadoAutor from './RechazadoAutor'
    import RezacharArticulo from './RechazarArticulo'
    import NoPublicable from './NoPublicable'
    import AceptarArticulo from './AceptarArticulo'
    import AsignarEditor from './AsignarEditor'
    import EnviarRevision from './EnviarRevision'
    import CorregirArticulo from './CorregirArticulo'
    import CorregidoArticulo from './CorregidoArticulo'
    import EditarSeccion from './EditarSeccion'
    export default {
        props: ['articulo'],
        components: {
            SolicitarDocumentacion,
            EditarSeccion,
            RechazadoAutor,
            RezacharArticulo,
            NoPublicable,
            AceptarArticulo,
            AsignarEditor,
            EnviarRevision,
            CorregirArticulo,
            CorregidoArticulo,
        },
        mixins: [mixin],
        watch: {
          articulo: function(){
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.enviar_a_revision)
                  .then(response => {
                      this.enviar_revision = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.editar_metadatos)
                  .then(response => {
                      this.editar_metadatos = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.solicitar_documentacion)
                  .then(response => {
                      this.solicitar_documentacion = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.rechazado_autor)
                  .then(response => {
                      this.rechazado_autor = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.validar_articulo)
                  .then(response => {
                      this.validar_articulo = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.aceptar)
                  .then(response => {
                      this.aceptado = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.rechazar)
                  .then(response => {
                      this.rechazado = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.revisar_nuevamente)
                  .then(response => {
                      this.revisar_nuevamente = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.editar_seccion)
                  .then(response => {
                      this.seccion_editada = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.eliminar_articulo)
                  .then(response => {
                      this.eliminar_articulo = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.asignar_editor)
                  .then(response => {
                      this.asignar_editor = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.revisar)
                  .then(response => {
                      this.revisar = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.corregir)
                  .then(response => {
                      this.corregir = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.corregido)
                  .then(response => {
                      this.corregido = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.modificar_articulo)
                  .then(response => {
                      this.modificar_articulo = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.generar_doi)
                  .then(response => {
                      this.generar_doi = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.prepublicar)
                  .then(response => {
                      this.prepublicar = response;
                  });
              this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.prepublicado)
                  .then(response => {
                      this.prepublicado = response;
                  });
          }
        },
        methods: {
          ...mapMutations(['setCargando']),
        volver: function(){
            if(this.articulo.revision == this.articulo.revision_actual){
                this.$router.push({name:'articulos.index'});
            }
            else{
                this.$router.go(-1);
            }
        },
          actualizar: function(){
              let vm = this;
              vm.ventana_visible = false;
              vm.$emit('updated');
          },
          ejecutarAccion: function(tipo){
              let vm = this;
              vm.ventana_visible = true;
              vm.currentComponent = tipo;
              switch (tipo){
                  case 'SolicitarDocumentacion':
                      vm.titulo = vm.__('site.solicitar_documentacion');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'RechazadoAutor':
                      vm.titulo = vm.__('site.rechazado_autor');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'AceptarArticulo':
                      vm.titulo = vm.__('site.aceptar');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'RezacharArticulo':
                      vm.titulo = vm.__('site.rechazar');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'AsignarEditor':
                      vm.titulo = vm.__('site.asignar_editor');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'EnviarRevision':
                      vm.titulo = vm.__('site.enviar_revision');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'CorregirArticulo':
                  case 'CorregidoArticulo':
                      vm.titulo = vm.__('site.corregir');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
                  case 'EditarSeccion':
                      vm.titulo = vm.__('site.editar_seccion');
                      vm.currentProps = {
                          articulo:vm.articulo
                      };
                      break;
              }
          },
          updateEstado: function(estado,texto){
              let vm = this;
              swal({
                  text: texto,
                  icon: 'warning',
                  buttons: {
                      cancelar: {
                          text: vm.__('site.cancelar'),
                          value: false
                      },
                      aceptar: {
                          text: vm.__('site.aceptar'),
                          value: true
                      }
                  }
              }).then(function (result) {
                  if (result) {
                      vm.setCargando(true);
                      if(estado == vm.articulo_estados.preparacion){

                          axios.post(route('articulos.nuevaversion',{id:vm.articulo.id}),{
                              estado: estado,
                          })
                              .then((response) => {
                                  vm.setCargando(false);
                                  vm.$router.push({name:'articulos.show',params:{id:response.data.articulo.id}});
                              });
                      }
                      else{

                          axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                              estado: estado,
                          })
                              .then(() => {
                                  vm.actualizar();
                              });
                      }
                  }

              });
          },
          generarWM: function(){
              let vm = this;
              let texto = vm.articulo.prepublicado?vm.__('site.aviso_desprepublicar'):vm.__('site.aviso_prepublicar');
              swal({
                  text: texto,
                  icon: 'warning',
                  buttons: {
                      cancelar: {
                          text: vm.__('site.cancelar'),
                          value: false
                      },
                      aceptar: {
                          text: vm.__('site.aceptar'),
                          value: true
                      }
                  }
              }).then(function (result) {
                  if (result) {
                      vm.setCargando(true);
                      axios.post(route('articulos.generarwm',{id:vm.articulo.id}))
                      .then(() => {
                          vm.actualizar();
                      });
                  }

              });
          }
        },
        computed: {
            ...mapState('auth',['usuario']),
        },
        data() {
            return {
                ventana_visible: false,
                enviar_revision: false,
                editar_metadatos: false,
                rechazado_autor: false,
                aceptado: false,
                rechazado: false,
                revisar_nuevamente: false,
                asignar_editor: false,
                validar_articulo: false,
                solicitar_documentacion: false,
                modificar_articulo: false,
                revisar: false,
                corregir: false,
                corregido: false,
                generar_doi: false,
                prepublicar: false,
                prepublicado: false,
                seccion_editada: false,
                eliminar_articulo: false,
                currentComponent: '',
                currentProps: {},
            }
        },
    }
</script>
