<template>
    <div style="margin-top:30px;">
        <h2>{{__('site.versiones')}}</h2>
        <div class="tablalistado" v-if="versiones.length">
            <table class="table table-striped table-bordered tablastatic" >
                <thead>
                <tr>
                    <th>{{__('site.referencia')}}</th>
                    <th>{{__('site.ronda')}}</th>
                    <th>{{__('site.tipo')}}</th>
                    <th>{{__('site.titulo_espanol')}}</th>
                    <th>{{__('site.titulo_ingles')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="version in versiones" @click="verArticulo(version)">
                    <td>{{version.codigo}}</td>
                    <td>{{version.revision}}</td>
                    <td>{{version.seccion?version.seccion['nombre_'+idioma]:''}}</td>
                    <td>{{version.titulo_es}}</td>
                    <td>{{version.titulo_en}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            {{__('site.no_hay_datos')}}
        </div>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);
            axios.get(route('articulos.versiones',{id:vm.articulo.id}))
                .then(response => {
                    vm.versiones = response.data.versiones;
                    vm.setCargando(false);
                });
        },
        props: ['articulo'],
        mixins: [mixin],
        computed:{
            ...mapState(['idioma']),
        },
        methods: {
            ...mapMutations(['setCargando']),
            verArticulo: function(item){
                this.$router.push({ name: 'articulos.show', params: {'id':item.id} });
            },
        },
        data() {
            return {
                versiones: [],
            }
        },
    }
</script>
