<template>
    <div>
        <h2 style="margin-top:30px;">{{__('site.metadatos')}}</h2>
        <div class="row" v-if="articulo.estado == articulo_estados.preasignado_numero">
            <div class="col-md-6">
                <mostrar-campo 
                :label="__('site.publicado_en')" 
                :valor="articulo.numero?articulo.numero.titulo:''"
                :url_mostrar="articulo.estado == articulo_estados.publicado?url_numero:null">
            </mostrar-campo>
        </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <mostrar-campo :label="__('site.tipo_articulo')" :valor="articulo.seccion?articulo.seccion.nombre:''"></mostrar-campo>
            </div>
            <div class="col-md-4">
                <mostrar-campo :label="__('site.editor_adjunto')" :valor="articulo.editor?articulo.editor.nombre_completo:''"></mostrar-campo>
            </div>
            <div class="col-md-4">
                <mostrar-campo label="DOI" :valor="articulo.url_doi"></mostrar-campo>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mostrar-campo :label="__('site.idioma_articulo')" >
                    <div slot="valor">
                        <botones-radio v-model="articulo.idioma"
                                       :disabled="true"
                                       :elementos="elementos_idioma"></botones-radio>
                    </div>
                </mostrar-campo>
            </div>
            <div class="col-md-6">
                <mostrar-campo :label="__('site.idioma_comunicaciones')" >
                    <div slot="valor">
                        <botones-radio v-model="articulo.idioma_comunicaciones"
                                       :disabled="true"
                                       :elementos="elementos_idioma"></botones-radio>
                    </div>
                </mostrar-campo>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mostrar-campo :label="__('site.titulo_espanol')" :valor="articulo.titulo_es"></mostrar-campo>
                <mostrar-campo :label="__('site.resumen_espanol')" :valor="articulo.resumen_es"></mostrar-campo>
                <mostrar-campo :label="__('site.tags_espanol')" :valor="articulo.tags_es"></mostrar-campo>
            </div>
            <div class="col-md-6">
                <mostrar-campo :label="__('site.titulo_ingles')" :valor="articulo.titulo_en"></mostrar-campo>
                <mostrar-campo :label="__('site.resumen_ingles')" :valor="articulo.resumen_en"></mostrar-campo>
                <mostrar-campo :label="__('site.tags_ingles')" :valor="articulo.tags_en"></mostrar-campo>
            </div>
        </div>
        <div class="row" v-if="!esrevisor">
            <div class="col-md-6">
                <mostrar-campo :label="__('site.organismos_colaboradores')" :valor="articulo.organismos_colaboradores"></mostrar-campo>
            </div>
            <div class="col-md-6">
                <mostrar-campo :label="__('site.conflictos_interes')" :valor="articulo.conflictos_interes"></mostrar-campo>
            </div>
        </div>
        <div class="row" style="margin-top:30px;" v-if="!esrevisor">
            <div class="col-md-12">
                <h2>{{__('site.autores')}}</h2>
                <table class="table table-striped table-bordered tablastatic">
                    <thead>
                    <tr>
                        <th>{{__('site.autor')}}</th>
                        <th width="170px">{{__('site.correspondencia')}}</th>
                        <th width="170px">{{__('site.cesion_derechos')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(item,index) in articulo.autores">
                            <td>
                                <strong>{{item.nombre_completo}}</strong><br/>
                                {{item.email}}
                            </td>
                            <td class="text-center">
                                <i v-if="item.pivot.correspondencia" class="fas fa-check"></i>
                            </td>
                            <td class="text-center">
                                <i v-if="item.pivot.aceptado" :class="['fas', (item.pivot.aceptado == 1?'fa-check':'fa-times')]"></i>
                                <button class="btn boton" @click="enviarCesion(item.id)" v-if="!item.pivot.aceptado && (usuario.rol == roles.coordinador || usuario.rol == roles.administrador)"><i class="fas fa-paper-plane"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div class="col-md-12" style="margin-top:25px;">
                    <h2>{{__('site.observaciones_autor')}}</h2>
                    <mostrar-campo :label="__('site.observaciones')" :valor="articulo.observaciones_autor" :is_html="true"></mostrar-campo>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import BotonesRadio from "../utils/BotonesRadio";
    import UsuariosSelect from "../usuarios/UsuariosSelect";
    import MostrarCampo from "../utils/MostrarCampo";
    export default {
        
        mixins: [mixin],
        components: {MostrarCampo, BotonesRadio},
        methods: {
            ...mapMutations(['setCargando']),
            enviarCesion: function(usuario){
                let vm = this;
                vm.setCargando(true);
                axios.post(route('articulos.enviarcesion',{id:vm.articulo.id, usuarioid: usuario}))
                    .then(response => {
                        vm.setCargando(false);
                        swal(vm.__('site.cesion_derechos'),vm.__('site.cesion_enviada'), 'success');
                    });
            }
        },
        computed: {
            ...mapState('auth',['usuario']),
            url_numero: function (){
                return route('web.numeros.show', {id: this.articulo.numero_id});
            } 
        },
        props: ['articulo','esrevisor'],
        data() {
            return {
                elementos_idioma: [{nombre:this.__('site.espanol'),id:'es'},{nombre:this.__('site.ingles'),id:'en'}],
            }
        },
    }
</script>
