<template>
    <div>
        <h2>{{__('site.ficheros_del_articulo')}}</h2>
        <div class="row">
            <div class="col-md-6" v-if="!esrevisor">
                <mostrar-fichero
                                 :fichero="articulo.fichero_carta_original"
                                 :url="routeName(1,articulo.id,articulo.fichero_carta_original)"
                                 :url_destroy="routeDestroy(1,articulo.id)"
                                 :url_upload="routeUpdated(1,articulo.id)"
                                 :titulo="__('site.carta_presentacion')"
                                 :editable="ficheros_editable"
                                 @updated="actualizar"></mostrar-fichero>
            </div>
            <div class="col-md-6" v-if="!esrevisor">
                <mostrar-fichero :fichero="articulo.fichero_completo_original"
                                 :url="routeName(2,articulo.id,articulo.fichero_completo_original)"
                                 :url_upload="routeUpdated(2,articulo.id)"
                                 :obligatorio="true"
                                 :titulo="__('site.articulo_completo')"
                                 :editable="ficheros_editable"
                                 @updated="actualizar"></mostrar-fichero>
            </div>
            <div class="col-md-6">
                <mostrar-fichero :fichero="articulo.fichero_ciego_original"
                                 :url="routeName(3,articulo.id,articulo.fichero_ciego_original)"
                                 :url_upload="routeUpdated(3,articulo.id)"
                                 :obligatorio="true"
                                 :titulo="__('site.articulo_anonimo')"
                                 :editable="ficheros_editable"
                                 @updated="actualizar"></mostrar-fichero>
            </div>
            <div class="col-md-6" v-if="!esrevisor">
                <mostrar-fichero :fichero="articulo.fichero_traduccion_original"
                                 :url="routeName(14,articulo.id,articulo.fichero_traduccion_original)"
                                 :url_destroy="routeDestroy(14,articulo.id)"
                                 :url_upload="routeUpdated(14,articulo.id)"
                                 :titulo="__('site.articulo_traducido')"
                                 :editable="ficheros_editable"
                                 @updated="actualizar"></mostrar-fichero>
            </div>
            <div class="col-md-6" v-if="!esrevisor">
                <mostrar-fichero :fichero="articulo.fichero_blankform_original"
                                 :url="routeName(4,articulo.id,articulo.fichero_blankform_original)"
                                 :url_upload="routeUpdated(4,articulo.id)"
                                 :url_mostrar="articulo.url_blankform"
                                 :obligatorio="true"
                                 :titulo="__('site.blank_form')"
                                 :editable="ficheros_editable"
                                 @updated="actualizar"></mostrar-fichero>
                <p class="text-right">
                    <a download :href="'/storage/ficheros_descargar/blankform_'+idioma+'.pdf'">{{__('site.descargar_plantilla')}}</a>
                </p>
            </div>
            <div class="col-md-6" v-if="!esrevisor">
                <mostrar-fichero :fichero="articulo.fichero_checklist_original"
                                 :url="routeName(5,articulo.id,articulo.fichero_checklist_original)"
                                 :url_upload="routeUpdated(5,articulo.id)"
                                 :obligatorio="true"
                                 :url_mostrar="articulo.url_checklist"
                                 :titulo="__('site.checklist')"
                                 :editable="ficheros_editable"
                                 @updated="actualizar"></mostrar-fichero>
                <p class="text-right">
                    <a download :href="'/storage/ficheros_descargar/checklist_'+idioma+'.pdf'">{{__('site.descargar_plantilla')}}</a>
                </p>
            </div>
        </div>
        <h2 style="margin-top: 30px;">{{__('site.ficheros_adjuntos')}}</h2>
        <div class="row">
            <div class="col-md-6" :key="item.id" v-for="item in articulo.adjuntos">
                <mostrar-fichero
                        :fichero="item.nombre_original"
                        :url="routeName(6,articulo.id,item.nombre_original,item.id)"
                        :url_destroy="routeDestroy(6,item.id)"
                        :url_upload="routeAdjuntos(6)"
                        :editable="ficheros_editable"
                        @updated="actualizar"></mostrar-fichero>
            </div>
        </div>
        <subir-fichero @updated="actualizar" v-if="ficheros_editable" :url_upload="routeUpdated(6,articulo.id)"></subir-fichero>

        <template v-if="(usuario.rol == roles.editor ||
                            usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador) && ficheros_editable">
            <h2 style="margin-top: 30px;">{{__('site.ficheros_adjuntos')}} {{__('site.editor')}}</h2>
            <div class="row">
                <div class="col-md-6" :key="item.id" v-for="item in articulo.adjuntos_editor">
                    <mostrar-fichero
                            :fichero="item.nombre_original"
                            :url="routeName(7,articulo.id,item.nombre_original,item.id)"
                            :obligatorio="true"
                            :editable="false"
                            @updated="actualizar"></mostrar-fichero>
                </div>
            </div>
            <subir-fichero @updated="actualizar" :url_upload="routeUpdatedEditor('editor',articulo.id)"></subir-fichero>
        </template>
        <template v-if="(usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador) && ficheros_editable">

                <h2 style="margin-top: 30px;">{{__('site.ficheros_adjuntos')}} {{__('site.director')}}</h2>
                <div class="row">
                    <div class="col-md-6" :key="item.id" v-for="item in articulo.adjuntos_director">
                        <mostrar-fichero
                                :fichero="item.nombre_original"
                                :url="routeName(8,articulo.id,item.nombre_original,item.id)"
                                :obligatorio="true"
                                :editable="false"
                                @updated="actualizar"></mostrar-fichero>
                    </div>
                </div>
                <subir-fichero @updated="actualizar" :url_upload="routeUpdatedEditor('director',articulo.id)"></subir-fichero>
        </template>
        <template v-if="articulo.estado >= articulo_estados.correccion && ((usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador) && ficheros_editable)">
            <h2 style="margin-top: 30px;">{{__('site.ficheros_correccion')}}</h2>
            <div class="row">
                <div class="col-md-6">
                    <mostrar-fichero
                            :fichero="articulo.fichero_correccion_original_es"
                            :url="routeName(12,articulo.id,articulo.fichero_correccion_original_es)"
                            :url_upload="routeUpdated(12,articulo.id)"
                            :titulo="__('site.fichero_correccion')+' ('+__('site.espanol')+')'"
                            :obligatorio="true"
                            @updated="actualizar"></mostrar-fichero>
                </div>
                <div class="col-md-6">
                    <mostrar-fichero
                            :fichero="articulo.fichero_correccion_original_en"
                            :url="routeName(13,articulo.id,articulo.fichero_correccion_original_en)"
                            :url_upload="routeUpdated(13,articulo.id)"
                            :titulo="__('site.fichero_correccion')+' ('+__('site.ingles')+')'"
                            :obligatorio="true"
                            @updated="actualizar"></mostrar-fichero>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" :key="item.id" v-for="item in articulo.adjuntos_correccion">
                    <mostrar-fichero
                            :fichero="item.nombre_original"
                            :url="routeName(9,articulo.id,item.nombre_original,item.id)"
                            :obligatorio="true"
                            :editable="false"
                            @updated="actualizar">
                    </mostrar-fichero>
                </div>
            </div>
            <subir-fichero
                    v-if="usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador"
                    @updated="actualizar"
                           :url_upload="routeUpdated(9,articulo.id)"></subir-fichero>
        </template>
        <template v-if="articulo.estado >= articulo_estados.pendiente_doi && ((usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador) && ficheros_editable)">
            <h2 style="margin-top: 30px;">{{__('site.ficheros_autogenerados')}}</h2>
            <div class="row">
                <div class="col-md-6">
                    <mostrar-fichero
                            :fichero="articulo.fichero_doi"
                            :url="routeName(10,articulo.id,articulo.fichero_doi)"
                            :url_envio="ruta_doi"
                            :obligatorio="true"
                            :editable="false"
                            :fechaOn="true"
                            :fecha="articulo.doi_fecha"
                            :titulo="__('site.fichero_doi')"
                            @updated="actualizar"></mostrar-fichero>
                </div>
                <div class="col-md-6" v-if="articulo.prepublicado">
                    <mostrar-fichero
                            :fichero="articulo.fichero_prepublicado"
                            :url="routeName(11,articulo.id,articulo.fichero_doi)"
                            :url_recargar="ruta_recargar"
                            :obligatorio="true"
                            :editable="false"
                            :prepublicacion="true"
                            :fechaOn="true"
                            :fecha="articulo.prepublicado_fecha"
                            :titulo="__('site.fichero_prepublicado')"
                            @updated="actualizar"></mostrar-fichero>
                </div>
            </div>
        </template>
        <template v-if="articulo.estado >= articulo_estados.preasignado_numero">
            <h2 style="margin-top: 30px;">{{__('site.ficheros_maquetados')}}</h2>
            <h3 class="seccion">{{__('site.espanol')}}</h3>
            <articulos-maquetados :articulo="articulo" idioma="es"></articulos-maquetados>
            <h3 class="seccion">{{__('site.ingles')}}</h3>
            <articulos-maquetados :articulo="articulo" idioma="en"></articulos-maquetados>
        </template>
        <template v-if="articulo.estado >= articulo_estados.pendiente_doi && ((usuario.rol == roles.director ||
                             usuario.rol == roles.coordinador ||
                             usuario.rol == roles.administrador) && ficheros_editable)">
            <h2 style="margin-top: 30px;">{{__('site.video')}}</h2>
            <div class="inputvideo">
                <div>
                    <div v-if="edit_video" class="input-group">
                        <label>{{__('site.codigo_video')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="articulo.video" class="form-control"  />
                        </div>
                    </div>
                    <div v-else>
                        <mostrar-campo :label="__('site.codigo_video')" :valor="articulo.video"></mostrar-campo>
                    </div>
                </div>
                <div>
                    <button class="btn boton" @click="editarVideo"><i :class="edit_video?'fas fa-save':'fas fa-edit'"></i></button>
                </div>
            </div>
        </template>
    </div>

</template>

<script>
    import 'vue-nav-tabs/themes/vue-tabs.css';
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarFichero from "../utils/MostrarFichero";
    import SubirFichero from "../utils/SubirFichero";
    import ArticulosMaquetados from "./ArticulosMaquetados";
    import MostrarCampo from "../utils/MostrarCampo";
    export default {
        created: function(){
            this.actualizar();
        },
        mixins: [mixin],
        components: {
            MostrarCampo,
            ArticulosMaquetados,
            SubirFichero,
            MostrarFichero,
        },
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            routeName: function(tipo,id,filename,idadjunto = 0){
                if(filename){
                    let variables = {id:id,tipo:tipo,filename:filename};
                    if(idadjunto){
                        variables = {id:id,tipo:tipo,filename:filename,adjunto: idadjunto};
                    }
                    return route('articulos.getfile',variables);
                }

                return '';
            },
            routeUpdated: function(tipo,id){
                if(tipo && id){
                    return route('articulos.storefiles',{id:id,tipo:tipo})
                }
                return '';
            },
            routeUpdatedEditor: function(tipo,id){
                if(tipo && id){
                    return route('articulos.storefileseditor',{id:id,tipo:tipo})
                }
                return '';
            },
            routeAdjuntos: function(id){
                return route('articulos.updateAdjunto',{id:id})
            },
            routeDestroy: function(tipo,id){
                if(tipo && id)
                    return route('articulos.destroyfile',{id:id,tipo:tipo})
                return '';
            },
            actualizar: function(){
                let vm = this;
                vm.$emit('updated');
            },
            editarVideo: function(){
                let vm = this;
                if(vm.edit_video){
                    vm.setCargando(true);
                    axios.post(route('articulos.updatevideo',{id:vm.articulo.id}),{
                        video: vm.articulo.video?vm.articulo.video:''
                    }).then(() => {
                            vm.edit_video = false;
                            vm.setCargando(false);
                        }).catch(() =>{
                            vm.setCargando(false);
                    });
                }
                else{
                    vm.edit_video = true;
                }
            }
        },
        watch: {
            articulo: function(){
                this.checkPermisoArticulo(this.articulo.id,this.articulo_acciones.editar_metadatos)
                    .then(response => {
                        this.ficheros_editable = response;
                    });
            }
        },
        computed:{
            ...mapState('auth',['usuario']),
            ...mapState(['idioma']),
            ruta_doi: function(){
                return route('articulos.enviodoi',{'id':this.articulo.id});
            },
            ruta_recargar: function(){
                return route('articulos.prepublicaciondoi',{'id':this.articulo.id});
            }
        },
        props: ['articulo','esrevisor'],
        data() {
            return {
                ficheros_editable: false,
                edit_video: false,
            }
        },
    }
</script>
