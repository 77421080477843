import router from '../../router.js';

export default {
    namespaced: true,
    state: {
        logged: false,
        usuario: null,
        token: '',
        error: false,
        errorMessage: '',
        usuario_2: null,
        token_2: '',
    },
    mutations: {
        setUser: function(state,user) {
            state.usuario = user;
            state.logged = true;
            state.error = false;
            state.errorMessage = '';

        },

        setToken: function(state,token) {
            state.token = token;
            state.logged = true;
            state.error = false;
            state.errorMessage = '';

        },
        setUser2: function(state,user) {
            state.usuario_2 = user;
        },

        setToken2: function(state,token) {
            state.token_2 = token;

        },

        logout: function(state){
            if(state.usuario_2){
                state.usuario = state.usuario_2;
                state.token = state.token_2;
                state.usuario_2 = null;
                state.token_2 = '';
            }
            else{
                state.usuario = null;
                state.token = '';
                state.errorMessage = '';
                state.logged = false;
            }
        },
        authError: function(state,error){
            state.errorMessage = error;
            if(state.errorMessage.length){
                state.error = true;
                state.logged = false;
                state.usuario = null;
                state.token = '';
            }
            else{
                state.error = false;
            }
        }
    },
    actions: {
        signIn: function(context, user){
            context.commit('authError','');
            axios.post(route('panel.login'),{
                usuario: user.usuario,
                password: user.password,
                recuerdame: user.recuerdame,
            }).then(response => {
                context.commit('setUser',response.data.usuario);
                context.commit('setToken',response.data.access_token);
            }).catch(error =>{
                context.commit('authError','Los datos de acceso no son correctos');
                if (error.response.status === 401 || error.response.status === 403) {
                    context.commit('authError','Los datos de acceso no son correctos');
                }
                else{
                    context.commit('authError','Error de acceso');
                }
            })
        },
        signAsUsuario: function({commit,state}, id){
            axios.post(route('usuarios.loginasuser'),{
                id: id,
            }).then(response => {
                commit('setUser2',state.usuario);
                commit('setToken2',state.token);
                commit('setUser',response.data.usuario);
                commit('setToken',response.data.access_token);
                router.push({name: 'admin.home'});
            }).catch(error =>{
                console.log(error);
            })
        },
        refreshToken: function () {

            axios.post(route('panel.refresh'),{}).then(response => {
                console.log(response);
                context.commit('setToken',response.data.access_token);
            }).catch(error =>{
                console.log('error: '+error.response.status);
            })
        }
    },
    getters: {
        isLogged: state => {
            return state.logged;
        }
    }
}
