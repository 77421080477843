<template>
    <div class="fondocontacto">
        <div class="container">
            <h2>
                {{__('site.contacto')}}
            </h2>

            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div v-if="enviado">
                        <p class="text-center" style="font-size: 20px;">{{__('site.msg_envio_ok')}}</p>
                    </div>
                    <div v-else>
                        <div v-if="!cargando">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <input type="text" :placeholder="__('site.nombre_apellidos')"  v-model="nombre" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <input type="text" :placeholder="__('site.email')" v-model="email" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <input type="text" :placeholder="__('site.telefono')" v-model="telefono" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="input-group">
                                        <textarea :placeholder="__('site.comentario')" v-model="comentario" class="form-control" style="min-height: 100px"></textarea>
                                    </div>
                                </div>
                            </div>
                            <p style="font-size: 12px;color:#FFFFFF;">
                                <input type="checkbox" value="1" v-model="terminos" /> <a href="/acercade/politicas">{{__('site.acepto_terminos')}}</a>
                            </p>
                            <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                            <div class="text-center">
                                <button type="button" @click="enviar" class="btn boton" :disabled="!terminos">{{__('site.aceptar')}}</button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="text-center">
                                <img src="/images/loader.svg" />
                            </div>
                        </div>
                </div>
            </div>
            </div>


            <div class="row infocontacto">
                <div class="col-md-3">
                    <h3 class="tituloseccion">{{__('site.direccion_postal')}}</h3>
                    <p>
                        FUNDACIÓN SECO<br/>
                        C/ Castelló, 128 7º<br/>
                        28006 Madrid<br/>
                        España
                    </p>
                </div>
                <div class="col-md-3">
                    <h3 class="tituloseccion">{{__('site.soporte_tecnico')}}</h3>
                    <p>
                        <strong>{{__('site.resolucion_dudas')}}</strong><br/>
                        Correo electrónico: <a href="mailto:soporte@bmi-journal.com">soporte@bmi-journal.com</a><br/>
                    </p>
                </div>
                <div class="col-md-3">
                    <h3 class="tituloseccion">{{__('site.contacto_principal')}}</h3>
                    <p>
                        <strong>Coordinador bmi-journal.com</strong><br/>
                        Correo electrónico: <a href="mailto:coordinador@bmi-journal.com">coordinador@bmi-journal.com</a>
                    </p>
                </div>
                <div class="col-md-3">
                    <h3 class="tituloseccion">{{__('site.director_editor_jefe')}}</h3>
                    <p>
                        <strong>Dr. José Vicente Ferrer MD, PhD</strong><br/>
                        Correo electrónico: <a href="mailto:editorjefe@bmi-journal.com">editorjefe@bmi-journal.com</a><br/>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapState, mapActions} from 'vuex'
    export default {
        name: "formulario-contacto",
        created: function(){
            this.cambiarIdioma(this.idioma_web);
            this.$lang().setLocale(this.idioma);
        },
        computed:{
            ...mapState(['idioma']),
        },
        methods: {
            ...mapActions(['cambiarIdioma']),
            enviar: function(){
                let vm = this;
                grecaptcha.ready(function() {
                    grecaptcha.execute(process.env.MIX_GOOGLE_RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
                        vm.cargando = true;
                        vm.errores = [];
                        axios.post(vm.url,{
                            token: token,
                            nombre: vm.nombre,
                            email: vm.email,
                            telefono: vm.telefono,
                            comentario: vm.comentario,
                        }).then(() => {
                            vm.cargando = false;
                            vm.enviado = true;
                        }).catch(e => {
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.cargando = false;
                        })
                    });
                });
            }
        },
        props: ['url','idioma_web'],
        data: function(){
            return {
                nombre: '',
                email: '',
                telefono: '',
                comentario: '',
                terminos: 0,
                cargando: false,
                errores: [],
                enviado: false,
            }
        }
    }
</script>

