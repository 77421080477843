<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <div>
                <h1 >{{__('site.revision')}} {{articulo.codigo}}-{{articulo.revision}}</h1>
            </div>

            <vue-tabs
                    active-tab-color="#3f6893"
                    active-text-color="white">
                <v-tab :title="__('site.revision')" icon="fas fa-search">
                    <revisiones-show-datos :revision="revision" :showrevisor="true"></revisiones-show-datos>
                </v-tab>
                <v-tab :title="__('site.datos')" icon="fas fa-info">
                    <articulos-show-metadatos :articulo="articulo" :esrevisor="esrevisor" ></articulos-show-metadatos>
                </v-tab>
                <v-tab :title="__('site.bibliografia')" icon="fas fa-list">
                    <ol class="bibliografia">
                        <li v-for="item in articulo.bibliografias">
                            {{item.texto}} <template v-if="item.doi"><a target="_blank" :href="item.doi">{{item.doi}}</a></template>
                        </li>
                    </ol>
                </v-tab>
                <v-tab :title="__('site.archivos')" icon="fas fa-file-alt">
                    <articulos-show-files v-if="articulo.id" :articulo="articulo" :esrevisor="esrevisor" @updated="actualizar"></articulos-show-files>
                </v-tab>
            </vue-tabs>

            <div v-if="articulo.id" class="text-center" style="margin-top:30px;">
                <router-link :to="{name:'revisiones.index'}" class="btn boton">{{__('site.volver')}}</router-link>
                <router-link v-if="!esrevisor" :to="{name:'articulos.show',params:{id:articulo.id}}" class="btn boton">{{__('site.ver_articulo')}}</router-link>
                <template v-if="revision.estado == revision_estados.no_contestado && (usuario.rol == roles.coordinador || usuario.rol == roles.administrador)" >
                    <button class="btn boton" @click="aceptarRevision(1)">{{__('site.aceptar')}}</button>
                </template>
                <template v-if="revision.estado == revision_estados.pendiente" >
                    <button class="btn boton" @click="aceptarRevision(1)">{{__('site.aceptar')}}</button>
                    <button class="btn boton" @click="aceptarRevision(0)">{{__('site.rechazar')}}</button>
                </template>
                <template v-if="revision.estado == revision_estados.en_curso" >
                    <router-link :to="{name:'revisiones.edit',params:{id:revision.id}}" class="btn boton">{{__('site.evaluar')}}</router-link>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {VueTabs, VTab} from 'vue-nav-tabs';
    import 'vue-nav-tabs/themes/vue-tabs.css';
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import ArticulosShowFiles from "../articulos/ArticulosShowFiles";
    import ArticulosShowMetadatos from "../articulos/ArticulosShowMetadatos";
    import MostrarCampo from "../utils/MostrarCampo";
    import RevisionesShowDatos from "./RevisionesShowDatos";
    export default {
        created: function(){
            this.actualizar();
        },
        mixins: [mixin],
        components: {
            RevisionesShowDatos,
            MostrarCampo,
            ArticulosShowMetadatos,
            ArticulosShowFiles,
            VueTabs,
            VTab
        },
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            actualizar: function(){
                let vm = this;
                vm.setCargando(true);
                axios.get(route('revisiones.show',{id:vm.$route.params.id}))
                    .then(response => {
                        vm.articulo = response.data.articulo;
                        vm.revision = response.data.revision;
                        vm.setCargando(false);
                    });
            },
            aceptarRevision: function(aceptado){
                let vm = this;
                swal({
                    text: (aceptado == 0)?vm.__('site.texto_rechazar_revision'):vm.__('site.texto_aceptar_revision'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: vm.__('site.cancelar'),
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.aceptar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.setCargando(true);

                        axios.post(route('revisiones.aceptar',{id:vm.revision.id}),{
                            aceptado: aceptado,
                        })
                            .then(() => {
                                vm.actualizar();
                            });
                    }

                });
            }
        },
        computed:{
            ...mapState('auth',['usuario']),
            esrevisor: function(){
                let vm = this;
                if(vm.revision.revisor){
                    return (vm.usuario.id == vm.revision.revisor.id);
                }
                return false;
            }
        },
        data() {
            return {
                articulo: {},
                revision: {},
            }
        },
    }
</script>
