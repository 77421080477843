<template>
    <div class="content">
        <div class="containermarco">
            <div class="row">
                <div class="col-md-4">
                    <h1 style="font-size: 150px;font-weight: bold">{{error_code}}</h1>
                    <h2 style="font-size: 45px;font-weight: bold">{{__('site.error')}}</h2>
                    <h3>{{__('site.error_msg')}}</h3>
                </div>
                <div class="col-md-8">
                    <img width="100%" src="/images/error.jpg" class="img-responsive" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapMutations} from 'vuex'
    export default {
        created: function(){
            this.error_code = this.$route.params.error;
        },
        mounted: function(){
            this.setCargando(false);
        },
        methods: {
            ...mapMutations(['setCargando'])
        },
        data() {
            return {
                error_code: ''
            }
        },
    }
</script>
