<template>
    <div class="row">
        <div class="col-md-3">
            <mostrar-campo :label="__('site.fecha_creacion')" :valor="mostrarFechaLarga(revision.created_at)"></mostrar-campo>
        </div>
        <div class="col-md-3">
            <mostrar-campo :label="__('site.fecha_aceptacion_rechazo')" :valor="mostrarFecha(revision.fecha)"></mostrar-campo>
        </div>
        <div class="col-md-3">
            <mostrar-campo :label="__('site.fecha_finalizacion')" :valor="mostrarFecha(revision.fecha_finalizacion)"></mostrar-campo>
        </div>
        <div class="col-md-3">
            <mostrar-campo v-if="revision.fecha_limite" :label="__('site.fecha_limite')" :valor="mostrarFecha(revision.fecha_limite)"></mostrar-campo>
        </div>
        <div class="col-md-6">
            <mostrar-campo :label="__('site.estado')" :valor="motrarEstadoRevision(revision.estado)"></mostrar-campo>
        </div>
        <div class="col-md-6">
            <mostrar-campo :label="__('site.decision')" :valor="revision.evaluacion?motrarResultadoRevision(revision.evaluacion):''"></mostrar-campo>
        </div>
        <div class="col-md-12" v-if="showrevisor">
            <mostrar-campo :label="__('site.revisor')" :valor="revision.revisor?revision.revisor.nombre_completo:''"></mostrar-campo>
        </div>
        <div class="col-md-12">
            <mostrar-campo :label="__('site.informe_revision')" :valor="revision.informe" :is_html="true"></mostrar-campo>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarCampo from "../utils/MostrarCampo";
    export default {
        created: function(){

        },
        mixins: [mixin],
        components: {
            MostrarCampo,
        },
        computed:{
            ...mapState('auth',['usuario']),
        },
        props: ['revision','showrevisor'],
        data() {
            return {

            }
        },
    }
</script>
