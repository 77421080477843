<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{__('site.numeros')}}</h1>
            </div>
            <div class="col-md-3 text-right">
                <router-link class="btn boton" :to="{ name: 'numeros.create' }">{{__('site.nuevo_numero')}}</router-link>
            </div>
        </div>
        <v-server-table ref="tabla" @row-click="verNumero" @loaded="setCargando(false)" class="tablalistado tablanumeros" :url="url" :columns="columns" :options="options" >
            <div slot="afterFilterWrapper" style="display:inline-flex;margin-bottom:15px;">
                <div style="margin-right: 15px;display: flex;width: 100%;">
                    <input type="text"
                           class="form-control"
                           v-model="query_text"
                           @keyup.enter="actualizarTabla"
                           :placeholder="__('site.buscar')" />&nbsp;
                </div>
                <button @click="actualizarTabla" class="btn boton"
                        :title="__('site.buscar')">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </v-server-table>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    import {textos_tabla,iconos_tabla} from '../../config_tabla'
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);

            vm.url =route('numeros.index');


            vm.options.headings = vm.headings;
            vm.options.templates = {
                'fecha': function(h,row){
                    return vm.mostrarFecha(row.fecha);
                },
                'tipo': function(h,row){
                    return row.tipo==0?vm.__('site.numero'):vm.__('site.suplemento');
                },
                'publicada': function(h,row){
                    let texto = row.publicada==0?vm.__('site.en_preparacion'):vm.__('site.publicada');
                    texto += ' ('+(row.publicada?vm.mostrarFecha(row.fecha_publicacion):vm.mostrarFecha(row.created_at))+')';
                    return texto;
                },
            };
        },
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            verNumero: function(data){
                this.$router.push({ name: 'numeros.show', params: {'id':data.row.id} })
            },
            actualizarTabla: function(){
                let vm = this;
                vm.$refs.tabla.refresh();
            },
            funcionEnvio: function (data) {
                let vm = this;
                if(vm.query_text){
                    data['query'] = vm.query_text;
                }

                return axios.get(this.url, {
                    params: data
                }).catch(function (e) {
                    this.dispatch('error', e);
                }.bind(this));
            }
        },
        data() {
            return {
                usuarios: [],
                url: '',
                query_text: '',
                columns: [
                    'titulo',
                    'fecha',
                    'tipo',
                    'publicada',
                ],
                headings: {
                    'fecha': this.__('site.fecha_publicacion'),
                    'titulo': this.__('site.titulo'),
                    'tipo': this.__('site.tipo'),
                    'publicada': this.__('site.publicada'),
                },
                options: {
                    filterable: false,
                    texts: textos_tabla,
                    sortIcon: iconos_tabla,
                    perPage: 50,
                    orderBy: {
                        ascending: false
                    },
                    perPageValues: [25,50,100],
                    requestFunction: this.funcionEnvio
                }
            }
        },
    }
</script>
<style type="text/css">
    .tablanumeros table td:nth-child(2){
        width: 190px;
    }
    .tablanumeros table td:nth-child(3){
        width: 130px;
    }
    .tablanumeros table td:nth-last-child(1){
        width: 265px;
    }

</style>
