<template>
    <div class="content">
                <h1>{{app_name}}</h1>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import mixin from "../mixin";
    export default {
        created() {
            let vm = this;
            switch (vm.usuario.rol){
                case vm.roles.autor:
                    vm.$router.push({ name: 'articulos.index.misarticulos' });
                    break;
                case vm.roles.revisor:
                    vm.$router.push({ name: 'revisiones.index' });
                    break;
                case vm.roles.editor:
                case vm.roles.director:
                case vm.roles.coordinador:
                case vm.roles.administrador:
                    vm.$router.push({ name: 'articulos.index' });
            }
        },
        mixins: [mixin],
        computed: {
            ...mapState('auth',['usuario']),
        },
        data() {
            return {
            }
        }
    }
</script>
