<template>
    <div style="margin-top:30px;">
        <h2>{{rol==roles.editor?__('site.decision_editor'):__('site.decision_director')}}</h2>
        <div v-if="log && log.id">
            <mostrar-campo :label="__('site.estado')" :valor="motrarEstadoArticulo(log.estado)"></mostrar-campo>
            <mostrar-campo :label="__('site.comentario')" :valor="log.descripcion" :is_html="true"></mostrar-campo>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import MostrarCampo from "../utils/MostrarCampo";
    export default {
        components: {MostrarCampo},
        mixins: [mixin],
        created: function(){
          let vm = this;
          axios.get(route('articulos.getcomentario',{id:vm.articulo.id,rol:vm.rol}))
              .then(response => {
                    vm.log = response.data.log;
              });
        },
        methods: {
            ...mapMutations(['setCargando']),
        },
        computed: {
            ...mapState('auth',['usuario']),
        },
        props: ['articulo','rol'],
        data() {
            return {
                log: {},
            }
        },
    }
</script>
