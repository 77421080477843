<template>
    <div >
        <div class="row">
            <div class="col-md-6">
                <div class="input-group">
                    <label>{{__('site.carta_presentacion')}}</label>
                    <vue-dropzone ref="dropZoneCarta" id="dropzone1" :options="dropzoneOptionsDoc"
                                  @vdropzone-file-added="updateFiles(1)"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles(1)"></vue-dropzone>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <label>*{{__('site.articulo_completo')}}</label>
                    <vue-dropzone ref="dropZoneCompleto" id="dropzone2" :options="dropzoneOptionsDoc"
                                  @vdropzone-file-added="updateFiles(2)"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles(2)"></vue-dropzone>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group" style="margin-top:15px;">
                    <label>*{{__('site.articulo_anonimo')}}</label>
                    <vue-dropzone ref="dropZoneCiego" id="dropzone3" :options="dropzoneOptionsDoc"
                                  @vdropzone-file-added="updateFiles(3)"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles(3)"></vue-dropzone>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group" style="margin-top:15px;">
                    <label>{{__('site.articulo_traducido')}}</label>
                    <vue-dropzone ref="dropZoneTraduccion" id="dropzone14" :options="dropzoneOptionsDoc"
                                  @vdropzone-file-added="updateFiles(14)"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles(14)"></vue-dropzone>
                </div>
            </div>
        </div>
        <h2 style="margin-top:30px;">{{__('site.recomendaciones')}}</h2>
        <div class="row">
            <div class="col-md-4">
                <div class="input-group">
                    <label>*{{__('site.blank_form')}}</label>
                    <vue-dropzone ref="dropZoneBlankform" id="dropzone4" :options="dropzoneOptionsDoc"
                                  @vdropzone-file-added="updateFiles(4)"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles(4)"></vue-dropzone>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group">
                    <label>*{{__('site.checklist')}}</label>
                    <vue-dropzone ref="dropZoneChecklist" id="dropzone5" :options="dropzoneOptionsDoc"
                                  @vdropzone-file-added="updateFiles(5)"
                                  @vdropzone-error="errorDropzone"
                                  @vdropzone-removed-file="updateFiles(5)"></vue-dropzone>
                </div>
            </div>
        </div>
        <h2 style="margin-top:30px;">{{__('site.adjuntos')}}</h2>
        <div class="input-group">
            <label>{{__('site.adjuntos')}}</label>
            <vue-dropzone ref="dropZoneAdjuntos" id="dropzone6" :options="dropzoneOptionsAdjuntos"
                          @vdropzone-file-added="updateFiles(6)"
                          @vdropzone-error="errorDropzone"
                          @vdropzone-removed-file="updateFiles(6)"></vue-dropzone>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    export default {
        created: function(){
            let vm = this;

        },
        mixins: [mixin],
        components: {vueDropzone: vue2Dropzone},
        methods: {
            ...mapMutations(['setCargando']),
            updateFiles: function(index){
                let vm = this;
                setTimeout(() => {
                    switch (index){
                        case 1:
                            vm.articulo.new_articulos_carta = vm.$refs.dropZoneCarta.getAcceptedFiles();
                            break;
                        case 2:
                            vm.articulo.new_articulos_completo = vm.$refs.dropZoneCompleto.getAcceptedFiles();
                            break;
                        case 3:
                            vm.articulo.new_articulos_ciego = vm.$refs.dropZoneCiego.getAcceptedFiles();
                            break;
                        case 4:
                            vm.articulo.new_articulos_blankform = vm.$refs.dropZoneBlankform.getAcceptedFiles();
                            break;
                        case 5:
                            vm.articulo.new_articulos_checklist = vm.$refs.dropZoneChecklist.getAcceptedFiles();
                            break;
                        case 6:
                            vm.articulo.new_articulos_adjuntos = vm.$refs.dropZoneAdjuntos.getAcceptedFiles();
                            break;
                        case 14:
                            vm.articulo.new_articulos_traduccion = vm.$refs.dropZoneTraduccion.getAcceptedFiles();
                            break;
                    }
                }, 1);
            }
        },
        props: ['articulo'],
        data() {
            return {
                dropzoneOptionsPdf: {
                    url: route('usuarios.index'),
                    thumbnailWidth: 100,
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    maxFiles: 1,
                    acceptedFiles: "application/pdf",
                    addRemoveLinks: true,
                    dictDefaultMessage: this.__('site.texto_upload'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                },
                dropzoneOptionsDoc: {
                    url: route('usuarios.index'),
                    thumbnailWidth: 100,
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    maxFiles: 1,
                    acceptedFiles: "application/pdf,.odf,.doc,.docx",
                    addRemoveLinks: true,
                    dictDefaultMessage: this.__('site.texto_upload'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                },
                dropzoneOptionsAdjuntos: {
                    url: route('usuarios.index'),
                    thumbnailWidth: 100,
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    addRemoveLinks: true,
                    dictDefaultMessage: this.__('site.texto_upload_multi'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                }
            }
        },
    }
</script>
