<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <div class="loginframe">
                    <div class="text-center form-wrapper">
                        <img src="/images/logo.png" class="img-fluid" />
                        <form class="form-signin" v-on:submit.prevent="submitLogin">
                            <div class="form-group" style="margin-top: 15px;">
                                <input type="text" id="inputEmail" class="form-control" :placeholder="__('site.usuario')" required autofocus v-model="user.usuario">
                            </div>
                            <div class="form-group" style="margin-top: 15px;">
                                <campo-password :placeholder="__('site.password')" v-model="user.password"></campo-password>
                            </div>
                            <div class="error" v-if="error">
                                {{errorMessage}}
                            </div>
                            <button class="btn boton btn-block" type="submit">{{__('site.entrar')}}</button>
                            <div style="margin-top:15px;text-align: left">
                                <campo-checkbox v-model="user.recuerdame" :label="__('site.recuerdame')"></campo-checkbox>
                            </div>
                            <p style="margin-top:15px;">
                                <router-link :to="{name:'password.forgot'}">{{__('site.olvidado_password')}}</router-link>
                            </p>
                        </form>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import CampoPassword from "./utils/CampoPassword";
    import CampoCheckbox from "./utils/CampoCheckbox";
    export default {
        components: {CampoPassword,CampoCheckbox},
        methods: {
            ...mapActions('auth',['signIn']),
            submitLogin: function(){
                this.error = false;
                this.errorMessage = '';
                this.signIn(this.user);
            }
        },
        watch: {
            logged: function(){
                if(this.logged === true)
                    this.$router.push({name: 'admin.home'});
            }
        },
        computed: {
            ...mapState('auth',['error','errorMessage','logged']),

        },
        data() {
            return {
                user: {
                    recuerdame: 0,
                    usuario: '',
                    password: ''
                }
            }
        }
    }
</script>
