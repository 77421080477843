<template>
    <div class="content" style="width: 100%;" v-if="articulo.id">
        <div class="container">
            <div>
                <h1 >{{articulo.codigo}}-{{articulo.revision}}</h1>
            </div>
            <articulos-edit-metadatos v-if="articulo.id" :articulo="articulo"></articulos-edit-metadatos>
            <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
            <div class="text-center" style="margin-top:30px;">
                <router-link :to="{name:'articulos.show',params:{id:articulo.id}}" class="btn boton">{{__('site.volver')}}</router-link>
                <boton-guardar
                        @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import BotonGuardar from "../BotonGuardar";
    import {mapMutations, mapState} from 'vuex';
    import ArticulosEditMetadatos from "./ArticulosEditMetadatos";
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);
            axios.get(route('articulos.show',{id:vm.$route.params.id}))
                .then(response => {
                    vm.articulo = response.data.articulo;
                    vm.setCargando(false);
                });

        },
        mixins: [mixin],
        components: {
            ArticulosEditMetadatos,BotonGuardar},
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            guardar: function(){
                let vm = this;
                vm.setCargando(true);
                vm.cargando = true;
                axios.post(route('articulos.update',{id:vm.articulo.id}),{
                    autores: vm.articulo.autores?vm.articulo.autores:[],
                    bibliografia: vm.articulo.bibliografia?vm.articulo.bibliografia:'',
                    observaciones_autor: vm.articulo.observaciones_autor?vm.articulo.observaciones_autor:'',
                    conflictos_interes: vm.articulo.conflictos_interes?vm.articulo.conflictos_interes:'',
                    idioma: vm.articulo.idioma?vm.articulo.idioma:'es',
                    idioma_comunicaciones: vm.articulo.idioma_comunicaciones?vm.articulo.idioma_comunicaciones:'es',
                    organismos_colaboradores: vm.articulo.organismos_colaboradores?vm.articulo.organismos_colaboradores:'',
                    resumen_en: vm.articulo.resumen_en?vm.articulo.resumen_en:'',
                    resumen_es: vm.articulo.resumen_es?vm.articulo.resumen_es:'',
                    tags_en: vm.articulo.tags_en?vm.articulo.tags_en:'',
                    tags_es: vm.articulo.tags_es?vm.articulo.tags_es:'',
                    tipo: vm.articulo.tipo?vm.articulo.tipo:0,
                    tipo_original: vm.articulo.tipo_original?vm.articulo.tipo_original:0,
                    titulo_en: vm.articulo.titulo_en?vm.articulo.titulo_en:'',
                    titulo_es: vm.articulo.titulo_es?vm.articulo.titulo_es:'',
                }).then(response => {
                      vm.$router.push({name:'articulos.show',params:{id:response.data.articulo.id}});
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                    vm.setCargando(false);
                });
            }
        },
        computed:{
            ...mapState('auth',['usuario']),
        },
        data() {
            return {
                errores: [],
                articulo: {},
                cargando: false,
            }
        },
    }
</script>
