<template>
    <div class="itemfile">
        <ventana-flotante
                :titulo="__('site.subir_ficheros')"
                v-if="editar"
                @close="editar = false">
            <div slot="body">
                <vue-dropzone ref="dropZoneMostrar" id="dropzonemostrar" :options="dropzoneOptions"
                              @vdropzone-error="errorDropzone"
                              @vdropzone-file-added="cargando = true"
                              @vdropzone-upload-progress="dropzoneUploadProgress"
                              @vdropzone-success="fileUpdated"></vue-dropzone>
                <div v-if="cargando" style="font-size: 16px;margin-top: 15px;">
                    <strong>{{__('site.subiendo_fichero')}} {{progreso}} %</strong>
                </div>
            </div>
        </ventana-flotante>

        <a href="javascript:void(0)" @click="editar = true" class="btn boton">{{__('site.subir_ficheros')}}</a>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import mixin from '../../mixin.js'
    import {mapState} from 'vuex'

    export default {
        created: function(){
            this.dropzoneOptions = {
                headers: {
                    Authorization: 'Bearer ' + this.token,
                },
                url: this.url_upload,
                thumbnailWidth: 100,
                autoProcessQueue: true,
                uploadMultiple: true,
                previewTemplate: this.template(),
                maxFilesize: 150,
                maxFiles: 10,
                dictDefaultMessage: this.__('site.texto_upload'),
                dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
            }
        },
        watch: {
            url_upload: function(){
                this.dropzoneOptions = {
                    headers: {
                        Authorization: 'Bearer ' + this.token,
                    },
                    url: this.url_upload,
                    thumbnailWidth: 100,
                    autoProcessQueue: true,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    maxFiles: 10,
                    dictDefaultMessage: this.__('site.texto_upload'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                }
            },
        },
        methods: {
          fileUpdated: function(response){
            this.cargando = false;
            this.editar = false;
            this.$emit('updated');
          },
          dropzoneUploadProgress: function(file, totalBytes, totalBytesSent) {
              if(file.size >= totalBytesSent){
                  this.progreso = Math.round((totalBytesSent*100)/file.size);
              }
          },
        },
        computed: {
            ...mapState('auth',['token']),
        },
        mixins: [mixin],
        components: {vueDropzone: vue2Dropzone},
        props: {
            url_upload: {
                type: String,
                default: ''
            }
        },
        data: function(){
            return {
                editar: false,
                cargando: false,
                progreso: 0,
                dropzoneOptions: {},
            }
        }
    }
</script>