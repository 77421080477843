<template>
    <div>
        <p v-if="usuario.rol == roles.editor">
            {{__('site.rechazar_articulo_editor_texto')}}
        </p>
        <p v-else>
            {{__('site.rechazar_articulo_texto')}}
        </p>
        <div class="input-group">
            <label>{{__('site.observaciones')}}</label>
            <div class="form-group">
                <textarea class="form-control" v-model="comentario" ></textarea>
            </div>
        </div>
        <div class="input-group">
            <label>{{__('site.ficheros_adjuntos')}}</label>
            <div class="form-group">
                <vue-dropzone ref="dropZoneAdjuntos" id="dropzone" :options="dropzoneOptions"
                              @vdropzone-file-added="updateFiles"
                              @vdropzone-error="errorDropzone"
                              @vdropzone-removed-file="updateFiles"></vue-dropzone>
            </div>
        </div>
        <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
        <div class="text-center">
            <button type="button" class="btn boton" @click="$emit('updated')">{{__('site.cancelar')}}</button>
            <button type="button" class="btn boton" @click="enviar">{{__('site.enviar')}}</button>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    export default {
        methods: {
            ...mapMutations(['setCargando']),

            updateFiles: function(){
                let vm = this;
                setTimeout(() => {
                    vm.ficheros = vm.$refs.dropZoneAdjuntos.getAcceptedFiles();
                }, 1);
            },
            enviar: function(){
                let vm = this;
                vm.errores = [];
                if(vm.comentario.length){
                    vm.setCargando(true);

                    let estado = vm.articulo_estados.rechazado;
                    let tipo = 'director';
                    if(this.usuario.rol == this.roles.editor){
                        estado = vm.articulo_estados.rechazado_editor;
                        tipo = 'editor';
                    } 

                let pen_en_curso = vm.articulo.revisiones.filter(revision => revision.estado == revision_estados.pendiente 
                || revision.estado == revision_estados.en_curso);
                if(pen_en_curso.length){
                            swal({
                    text: vm.__('site.decision_revisiones_pendientes'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: vm.__('site.cancelar'),
                            value: false
                        },
                        aceptar: {
                            text: vm.__('site.enviar'),
                            value: true
                        }
                    }
                }).then(function (result) {
                    if (result) {
                        vm.envioRevisiones(estado, tipo);
                    } else {
                        vm.setCargando(false);
                    }
                })
                } else {
                    vm.envioRevisiones(estado, tipo);
                }                
            }
            else{
                    vm.errores.push(vm.__('site.msg_texto_error'));
                }
            },
            envioRevisiones: function(estado, tipo){
                    let vm = this;
                    vm.setCargando(true);
                axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                    estado: estado,
                    texto: vm.comentario
                }).then(response => {
                        let promises = [];
                        let formData = new FormData();
                        if(vm.ficheros.length){
                            for(let i=0;i<vm.ficheros.length;i++){
                                formData.append('file['+i+']', vm.ficheros[i]);
                            }
                            promises.push(axios.post(route('articulos.storefileseditor',{tipo:tipo,id:vm.articulo.id}),formData));
                        }
                        Promise.all(promises).then(function(results){
                            vm.setCargando(false);
                            vm.$emit('updated');
                            resolve(true);
                        });
                    }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.setCargando(false);
                    
                });
                } 
        },
        mixins: [mixin],
        components: {vueDropzone: vue2Dropzone},
        computed: {
            ...mapState('auth',['usuario']),
        },
        props: ['articulo'],
        data() {
            return {
                errores: [],
                ficheros: [],
                comentario: '',
                dropzoneOptions: {
                    url: route('articulos.index'),
                    thumbnailWidth: 100,
                    autoProcessQueue: false,
                    uploadMultiple: true,
                    previewTemplate: this.template(),
                    maxFilesize: 150,
                    addRemoveLinks: true,
                    dictDefaultMessage: this.__('site.texto_upload_multi'),
                    dictRemoveFile: "<i class='fas fa-trash-alt'></i>",
                }
            }
        },
    }
</script>
