<template>
    <div>
        <div v-if="enviado">
            <p class="text-center" style="font-size: 20px;">{{__('site.suscrito_ok')}}</p>
        </div>
        <div v-else>
            <div v-if="!cargando">
                <div class="inputs">
                
                    <div class="input-group">
                        <input type="text" :placeholder="__('site.nombre')"  v-model="nombre" class="form-control" />
                    </div>
                
                    <div class="input-group">
                        <input type="text" :placeholder="__('site.email')" v-model="email" class="form-control" />
                    </div>
                    
                </div>
                <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                <div class="text-center">
                    <button type="button" @click="enviar" class="btn boton">{{__('site.enviar')}}</button>
                </div>
            </div>
            <div v-else>
                <div class="text-center">
                    <img src="/images/loader.svg" />
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
    export default {
        name: "formulario-suscribete",
        methods: {
            enviar: function(){
                let vm = this;
                grecaptcha.ready(function() {
                    grecaptcha.execute(process.env.MIX_GOOGLE_RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
                        vm.cargando = true;
                        vm.errores = [];
                        axios.post(vm.url,{
                            token: token,
                            nombre: vm.nombre,
                            idioma: vm.$lang().getLocale(),
                            email: vm.email,
                        }).then(() => {
                            vm.cargando = false;
                            vm.enviado = true;
                        }).catch(e => {
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.cargando = false;
                        })
                    });
                });
            }
        },
        props:['url'],
        data: function(){
            return {
                nombre: '',
                email: '',
                cargando: false,
                errores: [],
                enviado: false,
            }
        }
    }
</script>

