
export const Rol = {
    autor: 0,
    revisor: 1,
    editor: 2,
    director: 3,
    coordinador: 4,
    administrador: 5,
};

export const TiposPermisos = {
    gestion_administracion: 1,
    gestion_administracion_usuarios: 2,
};