import Vue from 'vue'
import { Lang } from 'laravel-vue-lang';

Vue.use(Lang, {
	locale: 'es'
});

const app_lang = new Vue();

export const textos_tabla = {
    count: app_lang.__('site.mostrando_elementos',{from:'{from}',to:'{to}',count:'{count}'})+"|"+app_lang.__('site.elementos',{count:'{count}'})+"|"+app_lang.__('site.un_elemento'),
    first: app_lang.__('site.primero'),
    last: app_lang.__('site.ultimo'),
    filter: "",
    filterPlaceholder: app_lang.__('site.buscar'),
    limit: "",
    page: app_lang.__('site.pagina')+":",
    noResults: app_lang.__('site.no_resultados'),
    filterBy: "Filtrar por {column}",
    loading: app_lang.__('site.cargando'),
    defaultOption: 'Seleccionar {column}',
    columns: app_lang.__('site.columnas')
};

export const iconos_tabla = {
    is: 'fa-sort',
    base: 'float-right fas',
    up: 'fa-sort-up',
    down: 'fa-sort-down'
};