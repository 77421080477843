<template>
    <div class="showcampo">
        <strong>{{label}}</strong><br/>
        <slot name="valor">
            <template v-if="is_html">
                <span style="white-space: pre-wrap;">{{valor}}</span>
            </template>
            <template v-else>
                {{valor}}
                <a v-if="url_mostrar" class="pull-right" :href="url_mostrar" target="_blank" :title="__('site.abrir')"><i class="fas fa-eye"></i></a>
            </template>
            
        </slot>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            valor: {
                type: String,
                default: ''
            },
            is_html: {
                type: Boolean,
                default: false
            },
            url_mostrar: {
                type: String,
                default: null
            }
        },
    }
</script>