<template>
    <div class="container">
        <div class="row">
            <div class="col-8 offset-2">
                <div class="loginframe text-center" v-if="articulo.id">
                    <h1>{{articulo.titulo}}</h1>
                    <template v-if="ya_firmado">
                        <p>
                            {{__('site.cesion_derechos_ya_firmada')}}
                        </p>
                    </template>
                    <template v-else>
                        <p>
                            {{__('site.texto_confirmar_cesion_web')}}
                        </p>
                        <div>
                            <div class="text-center">
                                <button type="button" class="btn boton" @click="enviar(2)">{{__('site.rechazar')}}</button>
                                <button type="button" class="btn boton" @click="enviar(1)">{{__('site.aceptar')}}</button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import BotonGuardar from "./BotonGuardar";
    export default {
        created: function(){
            let vm = this;

            axios.get(route('articulos.coautoria.sign',{
                id: vm.$route.params.id
            })).then(response => {
                vm.articulo = response.data.articulo;
                let autor_current = vm.articulo.autores.find( e => e.id == vm.$route.params.userid);
                if(autor_current && autor_current.pivot.aceptado){
                    vm.ya_firmado = 1;
                }
            }).catch(e => {
                for (let key in e.response.data.errors) {
                    vm.errores.push(e.response.data.errors[key][0]);
                }
                vm.cargando = false;
            });
        },
        components: {BotonGuardar},
        methods: {
            ...mapActions('auth',['signAsUsuario']),
            enviar: function(valor){
                let vm = this;
                vm.errores = [];
                axios.post(route('articulos.coautoria.enviar',{id:vm.$route.params.id}),{
                    valor: valor,
                    user: vm.$route.params.userid,
                }).then(response => {
                    vm.$router.push({name:'admin.home'});
                }).catch(e => {
                    console.log(e);
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });

            }
        },
        data() {
            return {
                signed_url_get: '',
                signed_url_post: '',
                articulo: {},
                ya_firmado: 0,
                errores: []
            }
        }
    }
</script>
