<template>
    <div>
        <p>
            {{__('site.asignar_editor_texto')}}
        </p>
        <div class="row">
            <div class="col-md-6" v-for="editor in editores">
                <div class="form-group">
                    <label
                            class="checkboxcontainer checkboxinline">
                            {{editor.nombre_completo}}
                        <input type="checkbox" v-model="editor.selected" :value="editor.id" @change="updateEditor(editor)" />
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
        </div>
        <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
        <div class="text-center">
            <button type="button" class="btn boton" @click="$emit('updated')">{{__('site.cancelar')}}</button>
            <button type="button" class="btn boton" :disabled="!usuario.id" @click="enviar">{{__('site.enviar')}}</button>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import Autocomplete from "../utils/Autocomplete";
    import mixin from '../../mixin.js'
    export default {
        created: function(){
              let vm = this;
              this.url_usuarios = route('usuarios.index',{rol:this.roles.editor,articulo:this.articulo.id,match:1});
              axios.get(this.url_usuarios).then(response => {
                  vm.editores = response.data.data;
                  vm.editores.forEach(function(item){
                     vm.$set(item,'selected',false);
                  });
              });
        },
        components: {Autocomplete},
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            updateEditor: function(editor_selected){
                let vm = this;
                vm.usuario ={};
                vm.editores.forEach(function(item){
                    if(item.id !== editor_selected.id){
                        item.selected = false;
                    }
                    else{
                        if(editor_selected.selected){
                            vm.usuario = editor_selected;
                        }
                    }
                });
            },
            enviar: function(){
                let vm = this;
                vm.errores = [];

                vm.setCargando(true);
                axios.post(route('articulos.asignareditor',{id:vm.articulo.id}),{
                    usuario: vm.usuario.id
                })
                    .then(response => {
                        axios.post(route('articulos.updateestado',{id:vm.articulo.id}),{
                            estado: vm.articulo_estados.editor_asignado,
                            texto: ''
                        })
                            .then(response => {
                                vm.setCargando(false);
                                vm.$emit('updated');
                            }).catch(e => {
                            vm.errores = [];
                            for (let key in e.response.data.errors) {
                                vm.errores.push(e.response.data.errors[key][0]);
                            }
                            vm.setCargando(false);
                        });
                    }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.setCargando(false);
                });
            }
        },
        props: ['articulo'],
        data() {
            return {
                errores: [],
                editores: [],
                usuario: {},
                url_usuarios: '',
            }
        },
    }
</script>
