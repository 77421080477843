<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <h1 v-if="esNuevo" >{{esNuevo?__('site.nuevo_numero'):__('site.editar_numero')}}</h1>

            <h2>{{__('site.metadatos')}}</h2>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.titulo_espanol')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="numero.titulo_es" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.titulo_ingles')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="numero.titulo_en" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.tipo')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="numero.tipo"
                                           :obligatorio="true"
                                           :elementos="elementos_tipo"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.fecha')}}</label>
                        <div class="form-group">
                            <datepicker format="dd-MM-yyyy"
                                        style="width: 100%"
                                        :language="es"
                                        v-model="numero.fecha"
                                        :monday-first="true"
                                        input-class="form-control inputfecha"
                                        placeholder=""></datepicker>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.ano')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="numero.year" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.volumen')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="numero.volumen" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.numero')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="numero.numero" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.meses_es')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="numero.meses_es" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.meses_en')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="numero.meses_en" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>{{__('site.paginas')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="numero.paginas" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.portada')}} {{__('site.espanol').toLowerCase()}}</label>
                        <div class="form-group">
                            <input type="file" id="portada" ref="portada" v-on:change="handleFileUpload(0)" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.portada')}} {{__('site.ingles').toLowerCase()}}</label>
                        <div class="form-group">
                            <input type="file" id="portada_en" ref="portada_en" v-on:change="handleFileUpload(3)" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.documento_pdf')}} {{__('site.espanol').toLowerCase()}}</label>
                        <div class="form-group">
                            <input type="file" id="documento" ref="documento" v-on:change="handleFileUpload(1)" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.documento_pdf')}} {{__('site.ingles').toLowerCase()}}</label>
                        <div class="form-group">
                            <input type="file" id="documento_en" ref="documento_en" v-on:change="handleFileUpload(4)" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.indice_editorial')}} {{__('site.espanol').toLowerCase()}}</label>
                        <div class="form-group">
                            <input type="file" id="indice_editorial" ref="indice_editorial" v-on:change="handleFileUpload(2)" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.indice_editorial')}} {{__('site.ingles').toLowerCase()}}</label>
                        <div class="form-group">
                            <input type="file" id="indice_editorial_en" ref="indice_editorial_en" v-on:change="handleFileUpload(5)" class="form-control"  />
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="!numero.publicada">
                <h2>{{__('site.seleccionar_articulos_incluir')}}</h2>
                <div v-for="tipo in secciones">
                    <h3 class="seccion">{{tipo.nombre}}</h3>
                    <ul class="elementosrevista">
                        <li v-for="articulo in articulosByTipo(tipo.id)" @click="seleccionarArticulo(articulo)">
                            <div>
                                <label class="checkboxcontainer">
                                    <input type="checkbox" v-model="articulo.selected" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <strong>{{articulo.codigo}} - {{articulo.titulo_es}}</strong><br/>
                                {{articulo.autores_nombres}}
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                    <div class="text-center" style="margin-top:30px;">
                        <router-link :to="url_volver" class="btn boton">{{__('site.cancelar')}}</router-link>
                        <boton-guardar @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import BotonGuardar from "../BotonGuardar";
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale';
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    import BotonesRadio from "../utils/BotonesRadio";
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);

            axios.get(route('articulos.getsecciones')).then(response => {
                vm.secciones = response.data.secciones;
            });

            axios.get(route('articulos.prepublicados')).then( response => {
                vm.articulos = response.data.articulos;
                vm.articulos.forEach(function(item){
                    Vue.set(item,'selected',0);
                });
            });

            if(vm.$route.params.id){
                vm.esNuevo = false;
                axios.get(route('numeros.show',{'id':vm.$route.params.id})
                ).then(response => {
                    vm.numero = response.data.numero;

                    vm.numero.articulos.forEach(function (item) {
                        Vue.set(item,'selected',1);
                        vm.articulos.push(item);
                    });

                    vm.setCargando(false);
                });
            }
            else {
                vm.setCargando(false);
            }

        },
        mixins: [mixin],
        components: {
            BotonesRadio,
            BotonGuardar,
            Datepicker
        },
        computed: {
          url_volver: function(){
              if(this.$route.params.id){
                  return {name:'numeros.show',params:{id:this.$route.params.id}};
              }
              else{
                  return {name:'numeros.index'};
              }
          }
        },
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            articulosByTipo: function(tipo){
                let vm = this;
                if(vm.articulos.length){
                    return vm.articulos.filter(e => e.tipo == tipo);
                }
                return [];
            },
            handleFileUpload: function(tipo){
                switch (tipo){
                    case 1:
                        this.numero.documento = this.$refs.documento.files[0];
                        break;
                    case 2:
                        this.numero.indice_editorial = this.$refs.indice_editorial.files[0];
                        break;
                    case 3:
                        this.numero.portada_en = this.$refs.portada_en.files[0];
                        break;
                    case 4:
                        this.numero.documento_en = this.$refs.documento_en.files[0];
                        break;
                    case 5:
                        this.numero.indice_editorial_en = this.$refs.indice_editorial_en.files[0];
                        break;
                    default:
                        this.numero.portada = this.$refs.portada.files[0];

                }
            },
            seleccionarArticulo: function(articulo){
                articulo.selected = !articulo.selected;
            },
            guardar: function() {
                let vm = this;
                vm.cargando = true;
                let data = {};

                let url_post = route('numeros.store');
                if(vm.$route.params.id) {
                    url_post = route('numeros.update',{'id':vm.$route.params.id});
                }

                var formData = new FormData();
                formData.append('titulo_es', vm.numero.titulo_es);
                formData.append('titulo_en', vm.numero.titulo_en);
                formData.append('tipo', vm.numero.tipo);
                formData.append('fecha', vm.numero.fecha?moment(vm.numero.fecha).format('DD-MM-YYYY'):'');
                formData.append('year', vm.numero.year);
                formData.append('volumen', vm.numero.volumen);
                formData.append('numero', vm.numero.numero);
                formData.append('meses_es', vm.numero.meses_es);
                formData.append('meses_en', vm.numero.meses_en);
                formData.append('paginas', vm.numero.paginas?vm.numero.paginas:'');
                formData.append('portada',vm.numero.portada);
                formData.append('portada_en',vm.numero.portada_en);
                formData.append('indice_editorial',vm.numero.indice_editorial);
                formData.append('indice_editorial_en',vm.numero.indice_editorial_en);
                formData.append('documento',vm.numero.documento);
                formData.append('documento_en',vm.numero.documento_en);

                let articulos = vm.articulos.filter(e => e.selected);
                formData.append('articulos',JSON.stringify(articulos));

                axios.post(url_post, formData).then(response => {
                    vm.$router.push({ name: 'numeros.show', params: {id:response.data.numero.id} });
                    vm.cargando = false;
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });

            }
        },
        data() {
            return {
                es: es,
                secciones: [],
                articulos: [],
                numero: {
                    titulo_es: '',
                    titulo_en: '',
                    tipo: 0,
                    publicada: 0,
                    fecha: '',
                    year: '',
                    volumen: '',
                    meses_es: '',
                    meses_en: '',
                    paginas: '',
                    portada: '',
                    indice_editorial: '',
                    documento: '',
                    portada_en: '',
                    indice_editorial_en: '',
                    documento_en: '',
                },
                errores: [],
                cargando: false,
                esNuevo: true,
                elementos_tipo: [{nombre:this.__('site.numero'),id:0},{nombre:this.__('site.suplemento'),id:1}],
            }
        },
    }
</script>
