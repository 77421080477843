<template>
    <div class="content" style="width: 100%;">

        <div class="container">
            <h1 v-if="esNuevo" >{{esNuevo?__('site.add_usuario'):__('site.editar_usuario')}}</h1>

            <h2>{{__('site.datos_personales')}}</h2>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.nombre')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="nombre" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.apellidos')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="apellidos" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.idioma')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="idioma"
                                           :obligatorio="true"
                                           :elementos="elementos_idioma"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>{{__('site.nif')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="nif" class="form-control"  />
                        </div>
                    </div>
                </div>
            </div>

            <h2>{{__('site.datos_contacto')}}</h2>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.email')}}</label>
                        <div class="form-group">
                            <input type="text" required v-model="email" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group">
                        <label>{{__('site.telefono')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="telefono" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>*{{__('site.pais')}}</label>
                        <div class="form-group">
                            <select class="form-control" v-model="pais" @change="provincia=''">
                                <option v-for="item in paises" :value="item.id">{{item.value}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.provincia')}}</label>
                        <div class="form-group">
                            <select v-if="Object.keys(provincias).length"  class="form-control" v-model="provincia">
                                <option v-for="value in provincias" :value="value">{{value}}</option>
                            </select>
                            <input v-else type="text" v-model="provincia" class="form-control"  />
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <label>{{__('site.localidad')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="localidad" class="form-control"  />
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="input-group">
                        <label>{{__('site.codigo_postal')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="codigo_postal" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="input-group">
                        <label>{{__('site.direccion')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="direccion" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>

            <h2>{{__('site.datos_profesionales')}}</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.orcid')}}</label>
                        <div class="form-group">
                            <input type="text" v-mask="'####-####-####-####'" placeholder="____-____-____-____" v-model="orcid" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.web')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="web" class="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <h2>Departamento</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.perfil_profesional')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="departamento" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.pais')}}</label>
                            <div class="form-group">
                                <select class="form-control" v-model="departamento_pais" @change="provincia=''">
                                    <option v-for="item in paises" :value="item.id">{{item.value}}</option>
                                </select>
                            </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group">
                        <label>{{__('site.provincia')}}</label>
                        <div class="form-group">
                            <select v-if="Object.keys(dep_provincias).length"  class="form-control" v-model="departamento_provincia">
                                <option v-for="value in dep_provincias" :value="value">{{value}}</option>
                            </select>
                            <input v-else type="text" v-model="departamento_provincia" class="form-control"  />
                        </div>
                    </div>
                </div>
            </div>
            <h2>{{__('site.datos_acceso')}}</h2>
            <div class="row">
                <div class="col-md-4">
                    <div class="input-group">
                        <label>*{{__('site.usuario')}}</label>
                        <div class="form-group">
                            <input type="text" v-model="usuario_text" class="form-control" />
                        </div>
                    </div>
                </div>
                <template v-if="esNuevo">
                    <div class="col-md-4">
                        <div class="input-group">
                            <label>*{{__('site.password')}}</label>
                            <div class="form-group">
                                <input type="password" class="form-control" v-model="password" name="password" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group">
                            <label>*{{__('site.repetir_password')}}</label>
                            <div class="form-group">
                                <input type="password" class="form-control" v-model="password_confirmation" name="password_confirmation" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="col-md-6" v-if="checkPermiso(tipos_permiso.gestion_administracion_usuarios)">
                    <div class="input-group">
                        <label>*{{__('site.rol')}}</label>
                        <div class="form-group">
                            <select class="form-control" v-model="rol">
                                <option value="0">{{__('site.autor')}}</option>
                                <option value="1">{{__('site.revisor')}}</option>
                                <option value="2">{{__('site.editor_adjunto')}}</option>
                                <option value="3">{{__('site.director')}}</option>
                                <option value="4">{{__('site.coordinador')}}</option>
                                <option value="5">{{__('site.administrador')}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="rol == roles.editor || rol == roles.revisor">
                    <div class="input-group">
                        <label>*{{__('site.interes_revision')}}</label>
                        <div class="row">
                            <div class="col-md-3" v-for="item in interes_revision">
                                <div class="form-group">
                                    <label
                                            class="checkboxcontainer checkboxinline">
                                        {{__('site.intereses_revision'+item)}}
                                        <input type="checkbox" v-model="interes_revision_user" :value="item" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="input-group">
                        <label>*{{__('site.newsletter')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="newsletter"
                                           :obligatorio="true"
                                           :elementos="elementos_sino"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" v-if="checkPermiso(tipos_permiso.gestion_administracion_usuarios)">
                    <div class="input-group">
                        <label>*{{__('site.activo')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="activo"
                                           :obligatorio="true"
                                           :elementos="elementos_sino"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" v-if="checkPermiso(tipos_permiso.gestion_administracion_usuarios)">
                    <div class="input-group">
                        <label>*{{__('site.comite_editorial')}}</label>
                        <div class="form-group">
                            <botones-radio v-model="comite_editorial"
                                           :obligatorio="true"
                                           :elementos="elementos_sino"></botones-radio>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" v-if="!esNuevo">
                    <div class="input-group">
                        <label>{{__('site.ultimo_acceso')}}</label>
                        <div class="form-group">
                            <div class="form-control">
                                {{mostrarFechaLarga(ultimo_acceso)}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="checkPermiso(tipos_permiso.gestion_administracion_usuarios)">
                <h2>{{__('site.observaciones')}}</h2>
                <div class="input-group">
                    <label>{{__('site.observaciones')}}</label>
                    <div class="form-group">
                        <textarea class="form-control" v-model="observaciones" ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                    <div class="text-center" style="margin-top:30px;">
                        <router-link :to="{name:'admin.usuarios.index'}" class="btn boton">{{__('site.volver')}}</router-link>
                        <button @click="loginUsuario"
                                v-if="$route.params.id && (usuario.rol == roles.coordinador || usuario.rol == roles.administrador)"
                                class="btn boton" >{{__('site.acceso_usuario')}}</button>
                        <boton-guardar
                                v-if="
                         usuario.id == $route.params.id ||
                         checkPermiso(tipos_permiso.gestion_administracion_usuarios)"
                                @guardar="guardar" :cargando="cargando" >{{__('site.guardar')}}</boton-guardar>
                        <router-link
                                v-if="$route.params.id &&
                        (checkPermiso(tipos_permiso.gestion_administracion_usuarios) || $route.params.id == usuario.id)"
                                class="btn boton" :to="{ name: 'admin.usuarios.password' }">{{__('site.cambiar_password')}}</router-link>

                        <button v-if="$route.params.id && checkPermiso(tipos_permiso.gestion_administracion_usuarios)"
                                @click="borrar(__('site.msg_confirmar_eliminar'),
                                            $route.params.id,
                                            'usuarios.destroy',
                                            'admin.usuarios.index')"
                                class="btn boton" >{{__('site.eliminar')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BotonGuardar from "../BotonGuardar";
    import mixin from '../../mixin.js'
    import {mapMutations, mapState, mapActions} from 'vuex';
    import BotonesRadio from "../utils/BotonesRadio";
    import {mask} from 'vue-the-mask'
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);

            axios.get(route('usuarios.getpaises')
            ).then(response => {
                vm.paises = response.data.paises;
            });

            if(vm.$route.params.id){
                vm.esNuevo = false;
                axios.get(route('usuarios.show',{'id':vm.$route.params.id})
                ).then(response => {
                    let user = response.data.usuario;
                    vm.nombre = user.nombre;
                    vm.apellidos = user.apellidos;
                    vm.idioma = user.idioma;
                    vm.nif = user.nif;
                    vm.email = user.email;
                    vm.telefono = user.telefono;
                    vm.pais = user.pais;
                    vm.provincia = user.provincia;
                    vm.localidad = user.localidad;
                    vm.codigo_postal = user.codigo_postal;
                    vm.direccion = user.direccion;
                    vm.web = user.web;
                    vm.orcid = user.orcid;
                    vm.departamento = user.departamento;
                    vm.departamento_pais = user.departamento_pais;
                    vm.departamento_provincia = user.departamento_provincia;
                    vm.rol = user.rol;
                    vm.usuario_text = user.usuario;
                    vm.newsletter = user.newsletter;
                    vm.activo = user.activo;
                    vm.comite_editorial = user.comite_editorial;
                    vm.interes_revision_user = user.interes_revision?JSON.parse(user.interes_revision):[];
                    vm.interes_otros = user.interes_otros;
                    vm.ultimo_acceso = user.ultimo_acceso;
                    vm.setCargando(false);
                });
            }
            else {
                vm.setCargando(false);
            }

        },
        mixins: [mixin],
        components: {BotonesRadio, BotonGuardar},
        directives: {mask},
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations('auth',['setUser']),
            ...mapActions('auth',['signAsUsuario']),
            guardar: function() {
                let vm = this;
                vm.cargando = true;
                vm.errores = [];

                let data = {};

                let url_post = route('usuarios.store');
                if(vm.$route.params.id) {
                    url_post = route('usuarios.update',{'id':vm.$route.params.id});
                    data = {
                        nombre: vm.nombre,
                        apellidos: vm.apellidos,
                        email: vm.email,
                        idioma: vm.idioma,
                        nif: vm.nif,
                        telefono: vm.telefono,
                        pais: vm.pais,
                        provincia: vm.provincia,
                        localidad: vm.localidad,
                        codigo_postal: vm.codigo_postal,
                        direccion: vm.direccion,
                        web: vm.web,
                        orcid: vm.orcid,
                        departamento: vm.departamento,
                        departamento_pais: vm.departamento_pais,
                        departamento_provincia: vm.departamento_provincia,
                        usuario: vm.usuario_text,
                        newsletter: vm.newsletter,
                        interes_revision: (vm.interes_revision_user && vm.interes_revision_user.length)?vm.interes_revision_user:'',
                        interes_otros: '',
                        activo: vm.activo,
                        comite_editorial: vm.comite_editorial,
                        rol: vm.rol,
                        observaciones: vm.observaciones,
                    };
                }
                else{
                    data = {
                        nombre: vm.nombre,
                        apellidos: vm.apellidos,
                        email: vm.email,
                        idioma: vm.idioma,
                        nif: vm.nif,
                        telefono: vm.telefono,
                        pais: vm.pais,
                        provincia: vm.provincia,
                        localidad: vm.localidad,
                        codigo_postal: vm.codigo_postal,
                        direccion: vm.direccion,
                        web: vm.web,
                        orcid: vm.orcid,
                        departamento: vm.departamento,
                        departamento_pais: vm.departamento_pais,
                        departamento_provincia: vm.departamento_provincia,
                        usuario: vm.usuario_text,
                        newsletter: vm.newsletter,
                        interes_revision: (vm.interes_revision_user && vm.interes_revision_user.length)?vm.interes_revision_user:'',
                        interes_otros: '',
                        activo: vm.activo,
                        comite_editorial: vm.comite_editorial,
                        rol: vm.rol,
                        password: vm.password,
                        password_confirmation: vm.password_confirmation,
                        observaciones: vm.observaciones,
                    };
                }

                axios.post(url_post, data).then(response => {
                    if(vm.usuario.id == response.data.usuario.id){
                        vm.setUser(response.data.usuario);
                    }
                    if(vm.checkPermiso(vm.tipos_permiso.gestion_administracion_usuarios)){
                        vm.$router.push({ name: 'admin.usuarios.index' });
                    }
                    else{
                        vm.$router.push({ name: 'admin.home' });
                    }
                    vm.cargando = false;
                }).catch(e => {
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });

            },
            loginUsuario: function(){
                this.signAsUsuario(this.$route.params.id);
            }
        },
        watch: {
            rol: function(){
                let vm = this;
                if(vm.rol != vm.roles.editor && vm.rol != vm.roles.revisor){
                    vm.interes_revision_user = [];
                    vm.interes_otros = '';
                }
            }
        },
        computed:{
            ...mapState('auth',['usuario']),
            provincias: function(){
                let vm = this;
                if(vm.pais && vm.pais.length && vm.paises){
                    let pais_selected = vm.paises.find(e => e.id == vm.pais);
                    return pais_selected?pais_selected.provincias:{};
                }
                return {};
            },
            dep_provincias: function(){
                let vm = this;
                if(vm.departamento_pais && vm.departamento_pais.length && vm.paises){
                    let pais_selected = vm.paises.find(e => e.id == vm.departamento_pais);
                    return pais_selected?pais_selected.provincias:{};
                }
                return {};
            }
        },
        data() {
            return {
                paises: [],
                nombre: '',
                apellidos: '',
                idioma: 'es',
                nif: '',
                email: '',
                telefono: '',
                pais: 'ES',
                provincia: '',
                localidad: '',
                codigo_postal: '',
                direccion: '',
                web: '',
                orcid: '',
                departamento: '',
                departamento_pais: '',
                departamento_provincia: '',
                usuario_text: '',
                newsletter: 0,
                activo: 1,
                comite_editorial: 0,
                observaciones: '',
                password: '',
                password_confirmation: '',
                interes_revision_user: [],
                interes_otros: '',
                ultimo_acceso: '',
                rol: 0,
                errores: [],
                cargando: false,
                esNuevo: true,
                elementos_idioma: [{nombre:this.__('site.espanol'),id:'es'},{nombre:this.__('site.ingles'),id:'en'}],
                elementos_sino: [{nombre:this.__('site.si'),id:1},{nombre:this.__('site.no'),id:0}],
            }
        },
    }
</script>
