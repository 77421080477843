<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{__('site.secciones')}}</h1>
            </div>
        </div>
        <table class="table table-striped table-bordered tablastatic">
            <thead>
            <tr>
                <th>{{__('site.nombre_es')}}</th>
                <th>{{__('site.nombre_en')}}</th>
                <th width="125px">{{__('site.max_autores')}}</th>
                <th width="125px">{{__('site.max_resumen')}}</th>
                <th width="125px">{{__('site.min_clave')}}</th>
                <th width="125px">{{__('site.max_clave')}}</th>
                <th width="125px">{{__('site.max_adjuntos')}}</th>
                <th width="125px">{{__('site.max_bibliografia')}}</th>
                <th width="71px">{{__('site.activo')}}</th>
                <th style="width: 50px"></th>
            </tr>
            </thead>
            <draggable v-model="secciones" element="tbody" @end="actualizaOrden" draggable="tr" >
                <tr :key="index" v-for="(seccion,index) in secciones">
                    <td>
                        <div style="display: flex;">
                            <i style="cursor:move;margin-top: 5px;" class="fas fa-grip-vertical"></i>
                            <div style="margin-left: 5px;">
                                <template v-if="seccion.editar">
                                    <input type="text" class="form-control" v-model="seccion.nombre_es" />
                                </template>
                                <template v-else>
                                    {{seccion.nombre_es}}
                                </template>
                            </div>
                        </div>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="text" class="form-control" v-model="seccion.nombre_en" />
                        </template>
                        <template v-else>
                            {{seccion.nombre_en}}
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="number" class="form-control" v-model="seccion.max_autores" />
                        </template>
                        <template v-else>
                            {{seccion.max_autores}}
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="number" class="form-control" v-model="seccion.max_resumen" />
                        </template>
                        <template v-else>
                            {{seccion.max_resumen}}
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="number" class="form-control" v-model="seccion.min_clave" />
                        </template>
                        <template v-else>
                            {{seccion.min_clave}}
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="number" class="form-control" v-model="seccion.max_clave" />
                        </template>
                        <template v-else>
                            {{seccion.max_clave}}
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="number" class="form-control" v-model="seccion.max_adjuntos" />
                        </template>
                        <template v-else>
                            {{seccion.max_adjuntos}}
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <input type="number" class="form-control" v-model="seccion.max_bibliografia" />
                        </template>
                        <template v-else>
                            {{seccion.max_bibliografia}}
                        </template>
                    </td>
                    <td class="text-center">
                        <label class="checkboxcontainer" v-if="seccion.editar">
                            <input type="checkbox" v-model="seccion.activo" value="1" >
                            <span class="checkmark"></span>
                        </label>
                        <template v-else>
                            <i v-if="seccion.activo" class="fas fa-check"></i>
                        </template>
                    </td>
                    <td>
                        <template v-if="seccion.editar">
                            <a href="#!" class="icono" @click="guardar(seccion)"><i class="fas fa-save"></i></a>
                        </template>
                        <template v-else>
                            <a href="#!" class="icono" @click="seccion.editar=1"><i class="far fa-edit" ></i></a>
                        </template>
                        <a href="#!" @click="borrarItem(seccion)" class="icono"><i class="fas fa-trash-alt"></i></a>&nbsp;
                    </td>
                </tr>
            </draggable>
            <tr v-if="errores.length">
                <td colspan="10">
                    <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                </td>
            </tr>
            <tr>
                <td>
                    <input class="form-control" type="text" v-model="nuevo.nombre_es" />
                </td>
                <td>
                    <input class="form-control" type="text" v-model="nuevo.nombre_en" />
                </td>
                <td>
                    <input class="form-control" type="number" v-model="nuevo.max_autores" />
                </td>
                <td>
                    <input class="form-control" type="number" v-model="nuevo.max_resumen" />
                </td>
                <td>
                    <input class="form-control" type="number" v-model="nuevo.min_clave" />
                </td>
                <td>
                    <input class="form-control" type="number" v-model="nuevo.max_clave" />
                </td>
                <td>
                    <input class="form-control" type="number" v-model="nuevo.max_adjuntos" />
                </td>
                <td>
                    <input class="form-control" type="number" v-model="nuevo.max_bibliografia" />
                </td>
                <td>
                    <label class="checkboxcontainer">
                        <input type="checkbox" v-model="nuevo.activo" value="1" >
                        <span class="checkmark"></span>
                    </label>
                </td>
                <td>
                    <button @click="addItem" class="boton btn">
                        <i class="fas fa-plus"></i>
                    </button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

    import draggable from 'vuedraggable';
    import {mapMutations} from 'vuex';
    import mixin from '../../mixin.js'
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);
            axios.get(route('secciones.index')
            ).then(response => {
                this.secciones = response.data.data;
                vm.setCargando(false);
            });
        },
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            addItem: function(){
                let vm = this;
                vm.nuevo.orden = vm.secciones.length+1;

                axios.post(route('secciones.store'), {
                    nombre_es: vm.nuevo.nombre_es,
                    nombre_en: vm.nuevo.nombre_en,
                    max_autores: vm.nuevo.max_autores,
                    max_resumen: vm.nuevo.max_resumen,
                    min_clave: vm.nuevo.min_clave,
                    max_clave: vm.nuevo.max_clave,
                    max_adjuntos: vm.nuevo.max_adjuntos,
                    max_bibliografia: vm.nuevo.max_bibliografia,
                    activo: vm.nuevo.activo,
                    orden: vm.nuevo.orden,
                }).then(response => {
                    vm.errores = [];
                    vm.secciones.push(response.data.seccion);
                    vm.nuevo = {
                        id: 0,
                        nombre_es: '',
                        nombre_en: '',
                        max_autores: '',
                        max_resumen: '',
                        min_clave: '',
                        max_clave: '',
                        max_adjuntos: '',
                        max_bibliografia: '',
                        orden: 0,
                        activo: 0,
                    };
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                });

            },
            guardar: function(seccion){
                let vm = this;
                vm.errores = [];
                axios.post(route('secciones.update',{'id':seccion.id}), {
                    nombre_es: seccion.nombre_es,
                    nombre_en: seccion.nombre_en,
                    max_autores: seccion.max_autores,
                    max_resumen: seccion.max_resumen,
                    min_clave: seccion.min_clave,
                    max_clave: seccion.max_clave,
                    max_adjuntos: seccion.max_adjuntos,
                    max_bibliografia: seccion.max_bibliografia,
                    activo: seccion.activo,
                    orden: seccion.orden,
                }).then(response => {
                    seccion.editar = 0;
                }).catch(e => {
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                });
            },
            borrarItem: function(seccion){
                let vm = this;
                vm.errores = [];
                swal({
                    text: vm.__('site.msg_confirmar_eliminar'),
                    icon: 'warning',
                    buttons: {
                        cancelar: {
                            text: 'Cancelar',
                            value: false
                        },
                        aceptar: {
                            text: 'Eliminar',
                            value: true
                        }
                    }
                }).then(function(result){
                    if(result){
                        axios.delete(route('secciones.delete',{'id':seccion.id})).then(function(response){
                            vm.secciones.splice(vm.secciones.indexOf(seccion),1);
                            vm.actualizaOrden();
                        })
                            .catch(function(error){
                                console.log(error);
                                º
                            });
                    }
                });
            },
            actualizaOrden: function(newIndex,oldIndex,element){
                let vm = this;
                vm.errores = [];
                vm.secciones.forEach(function(item,key){
                    item.orden = key;
                });

                axios.post(route('secciones.actualizaorden'), {
                    secciones: vm.secciones,
                }).then(response => {
                    console.log('llega todo ok');
                }).catch(e => {
                    vm.errores = [];
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                });
            }
        },
        components: {
            draggable,
        },
        data() {
            return {
                secciones: [],
                errores: [],
                nuevo: {
                    id: 0,
                    nombre_es: '',
                    nombre_en: '',
                    max_autores: '',
                    max_resumen: '',
                    min_clave: '',
                    max_clave: '',
                    max_adjuntos: '',
                    max_bibliografia: '',
                    orden: 0,
                    activo: 0,
                }
            }
        },
    }
</script>
