<template>
    <div class="autocomplete">
        <input type="text"
               @keydown.down="onArrowDown"
               @keydown.up="onArrowUp"
               @keydown.enter="onEnter"
               v-model="texto"
               :placeholder="placeholder"
               class="form-control"
        />
        <ul v-show="visible">
            <li v-for="(item,i) in resultados"
                :key="i"
                @click="seleccionar(item)"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }">
                {{mostrarFila(item)}}
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        created: function(){
            let vm = this;
            if(!Object.keys(vm.value).length){
                vm.texto = '';
            }
            else{
                vm.blocked = true;
                let resultado = vm.value[vm.clave];
                vm.texto = resultado;
            }
        },
        props: [
            'value',
            'url',
            'clave',
            'valores',
            'excluir',
            'placeholder'
        ],
        watch: {
            resultados: function(){
                if(this.resultados.length){
                    this.visible = true;
                }
                else{
                    this.visible = false;
                }
            },
            texto: function(){
                let vm = this;
                if(vm.texto.length){
                    vm.$emit('textocambiado',vm.texto);
                    if(!vm.blocked){
                        vm.espera();
                    }
                    else{
                        vm.blocked = false;
                    }
                }
                else{
                    this.$emit('input',{});
                    this.resultados = [];
                }
            },
            value: function(){
                let vm = this;
                if(!Object.keys(vm.value).length){
                    vm.texto = '';
                }
                else{
                    vm.blocked = true;
                    let resultado = vm.value[vm.clave]+(this.valores.length?' -':'');
                    vm.valores.forEach(function(e){
                        resultado+= vm.value[e]?' '+vm.value[e]:'';
                    });
                    vm.texto = resultado;
                }
            }
        },
        methods: {
            espera: function(){
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.signalChange.bind(this), this.tiempo_espera);
            },
            signalChange: function(){
                let vm = this;
                if(vm.texto.length){
                    axios.get(this.url+'?no_sort=1&query='+vm.texto
                    ).then(response => {
                        let result = response.data.data;
                        if(vm.excluir && vm.excluir.length){
                            vm.excluir.forEach(function(item){
                                let encontrado = result.find(e => { return e.id == item.id});
                                if(encontrado){
                                    result.splice(result.indexOf(encontrado),1);
                                }
                            });
                        }
                        vm.resultados = result;
                    });
                }
                else{
                    this.$emit('input',{});
                    vm.resultados = [];
                }
            },
            mostrarFila: function(item){
                let resultado = item[this.clave]+(this.valores.length?' -':'');
                this.valores.forEach(function(e){
                    resultado+= item[e]?' '+item[e]:'';
                });
                return resultado;

            },
            seleccionar: function(item){
                this.$emit('seleccionado',item);
                this.blocked = true;
                this.texto = this.mostrarFila(item);
                this.resultados = [];
                this.$emit('input',item);
            },
            onArrowDown: function() {
                if (this.arrowCounter < this.resultados.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp: function() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter: function() {

                if (this.arrowCounter >= 0) {
                    this.seleccionar(this.resultados[this.arrowCounter]);
                    this.visible = false;
                    this.arrowCounter = -1;
                }
                else{
                    if(this.resultados.length){
                        this.seleccionar(this.resultados[0]);
                    }
                }

            },
        },
        data() {
            return {
                texto: '',
                visible: false,
                resultados: [],
                arrowCounter: -1,
                blocked: false,
                timeout: null,
                tiempo_espera: 300
            }
        }
    }
</script>
<style>
    .autocomplete{
        width: 100%;
    }
    .autocomplete-result{
        padding: 5px 10px;
        border-bottom: 1px dotted #CCCCCC;
    }

    .autocomplete-result:last-child{
        border-bottom: none;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background: #BBBBBB;
        color: #FFFFFF;
        cursor: pointer;
    }

    .autocomplete ul{
        background: #FFFFFF;
        width: auto;
        z-index:10;
        border:1px solid #CCCCCC;
        list-style: none;
        margin:0;
        padding:0;
        position: absolute;
        width: calc(100% - 30px);
    }
</style>