<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{__('site.revisiones')}}</h1>
            </div>
            <div class="col-md-3 text-right">
            </div>
        </div>
        <v-server-table ref="tabla" @row-click="verRevision" @loaded="setCargando(false)" class="tablalistado" :url="url" :columns="columns" :options="options" >
            <div slot="afterFilterWrapper" style="display:inline-flex;margin-bottom:15px;">
                <div style="margin-right: 15px;display: flex;width: 100%;">
                    <input type="text"
                           class="form-control"
                           v-model="query_text"
                           @keyup.enter="actualizarTabla"
                           :placeholder="__('site.buscar')" />&nbsp;
                </div>
                <div style="margin-left: 5px;margin-right: 5px;display: flex;width: 100%;">
                    <select class="form-control" v-model="estado">
                        <option value="">{{__('site.estado')}}</option>
                        <option value="0">{{__('estados_revision.0')}}</option>
                        <option value="1">{{__('estados_revision.1')}}</option>
                        <option value="2">{{__('estados_revision.2')}}</option>
                        <option value="3">{{__('estados_revision.3')}}</option>
                        <option value="4">{{__('estados_revision.4')}}</option>
                    </select>
                </div>
                <button @click="actualizarTabla" class="btn boton"
                        :title="__('site.buscar')">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </v-server-table>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    import {textos_tabla,iconos_tabla} from '../../config_tabla'
    export default {
        created: function(){
            let vm = this;
            vm.setCargando(true);

            vm.url =route('revisiones.index');

            vm.options.headings = vm.headings;
            vm.options.templates = {
                'estado': function(h,row){
                    let texto = vm.motrarEstadoRevision(row.estado);
                    let fecha = row.fecha_envio;
                    switch (row.estado){
                        case 1:
                        case 2:
                            fecha = row.fecha_aceptacion;
                            break;
                        case 3:
                            fecha = row.fecha_finalizacion;
                            break;
                        default:
                            fecha = row.fecha_envio;

                    }
                    texto += ' ('+vm.mostrarFecha(fecha)+')';
                    return texto;
                },
                'created_at': function(h,row){
                    return vm.mostrarFecha(row.created_at);
                },
            };
        },
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            verRevision: function(data){
                this.$router.push({ name: 'revisiones.show', params: {'id':data.row.id} })
            },
            actualizarTabla: function(){
                let vm = this;
                vm.$refs.tabla.refresh();
            },
            funcionEnvio: function (data) {
                let vm = this;
                if(vm.query_text){
                    data['query'] = vm.query_text;
                }
                if(vm.estado.length){
                    data['estado'] = vm.estado;
                }

                return axios.get(this.url, {
                    params: data
                }).catch(function (e) {
                    this.dispatch('error', e);
                }.bind(this));
            }
        },
        data() {
            return {
                url: '',
                query_text: '',
                estado: '',
                columns: [
                    'codigo',
                    'revisor.nombre_completo',
                    'titulo',
                    'created_at',
                    'estado',
                ],
                headings: {
                    'codigo': this.__('site.referencia'),
                    'revisor.nombre_completo': this.__('site.revisor'),
                    'titulo': this.__('site.titulo'),
                    'created_at': this.__('site.fecha_creacion'),
                    'estado': this.__('site.estado'),
                },
                options: {
                    filterable: false,
                    texts: textos_tabla,
                    sortIcon: iconos_tabla,
                    perPage: 50,
                    orderBy: {
                        ascending: false
                    },
                    perPageValues: [25,50,100],
                    templates:{},
                    requestFunction: this.funcionEnvio
                }
            }
        },
    }
</script>
