<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{__('site.cesiones_pendientes')}}</h1>
            </div>
            <div class="col-md-3 text-right">
            </div>
        </div>
        <div v-if="!pendientes"><p>No tienes cesiones de derechos pendientes</p></div>
        <table v-else class="table table-striped table-bordered tablastatic">
            <thead>
            <tr>
                <th width="100px">{{__('site.referencia')}}</th>
                <th width="100px">{{__('site.ronda')}}</th>
                <th width="170px">{{__('site.tipo')}}</th>
                <th width="280px">{{__('site.titulo')}}</th>
                <th width="170px">{{__('site.autor')}}</th>
                <th width="140px">{{__('site.fecha_creacion')}}</th>
                <th style="width: 116px"></th>
            </tr>
            </thead>
            <tbody>
                <template v-for="(item,index) in articulos_data">
                    <tr>
                    <td>
                        {{item.codigo}}
                    </td>
                    <td>
                        {{item.revision_actual}}
                    </td>
                    <td>
                        {{item.tipo}}
                    </td>
                    <td>
                        {{item.titulo}} 
                    </td>
                    <td>
                        {{item.autor_correspondencia[0].nombre_completo}}
                    </td>
                    <td>
                        {{mostrarFecha(item.fecha_creacion)}}
                    </td>
                    <td>
                        <button type="button" class="btn boton" @click="confirmar(item)">{{__('site.confirmar')}}</button>
                    </td>
                </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    import Datepicker from 'vuejs-datepicker';
    export default {
        created: function(){
            let vm = this;
            vm.refresh();
        },
        mixins: [mixin],
        components: {
            Datepicker
        },
        watch:{
        },
        methods: {
            ...mapMutations(['setCargando']),
            ...mapMutations(['setPendientes']),
            confirmar: function(item){
                let vm = this;
                swal({
                    title: item.titulo,
                    text: vm.__('site.texto_confirmar_cesion_web'),
                    icon: 'info',
                    buttons: {
                        rechazar: {
                            text: vm.__('site.rechazar'),
                            value: 2
                        },
                        aceptar: {
                            text: vm.__('site.aceptar'),
                            value: 1
                        }
                    }
                }).then(function (result) {
                    vm.enviar(result, item);
                })
        }, 
        enviar: function(valor, articulo){
                let vm = this;
                vm.errores = [];
                axios.post(route('articulos.coautoria.enviar',{id: articulo.id}),{
                    valor: valor,
                    user: vm.usuario.id
                }).then(response => {
                    vm.refresh();
                }).catch(e => {
                    console.log(e);
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });
            },
            refresh: function(){
                let vm = this;
            vm.setCargando(true);

            vm.url = route('articulos.index.cesionespendientes');
            axios.get(route('articulos.index.cesionespendientes')).then(response => {
                vm.articulos_data = response.data.data;
                vm.setCargando(false);
                vm.setPendientes(vm.articulos_data.length);
            });
            }
        },
        computed: {
          ...mapState('auth',['usuario']),
          ...mapState(['pendientes']),
        },
        data() {
            return {
                articulos_data: [],
                url: '',
            }
        },
    }
</script>
<style type="text/css">
    .tablaarticulos table td:nth-child(1){
        width: 125px;
    }
    .tablaarticulos table td:nth-child(2){
        width: 90px;
    }
    .tablaarticulos table td:nth-last-child(2){
        width: 150px;
    }
    .tablaarticulos table td:nth-last-child(1){
        width: 200px;
    }
</style>