require('./bootstrap');
require('sweetalert');

import Vue from 'vue'
import store from './store';
import { Lang } from 'laravel-vue-lang';


window.Vue = require('vue');

Vue.use(Lang, {
	locale: 'es',
    fallback: 'en' ,
});

import Errores from './components/Errores.vue';
import FormularioContacto from './portal/formularioContacto';
import FormularioSuscribete from './portal/formularioSuscribete';
import FormularioRegistro from './portal/formularioRegistro';
Vue.component('mostrar-errores', Errores);
Vue.component('formulario-contacto', FormularioContacto);
Vue.component('formulario-suscribete', FormularioSuscribete);
Vue.component('formulario-registro', FormularioRegistro);


const app = new Vue({
    el: '#appweb',
    store,
});


$(function(){
   $(".botoncerrar").on('click',function(){
       $("#main-menu").removeClass("menuvisible");
   });
   $("#botmenu").on('click',function(){
       $("#main-menu").addClass("menuvisible");
   });

   $("#botonbuscar").on('click',function(){
       $(".buscador").toggle();
   });
});