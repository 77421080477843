<template>
    <div class="content">
        <div class="row">
            <div class="col-md-9">
                <h1>{{__('site.lectores')}}</h1>
            </div>
            <div class="col-md-3 text-right">
                <router-link v-if="checkPermiso(tipos_permiso.gestion_administracion)" class="btn boton" :to="{ name: 'admin.lectores.create' }">{{__('site.nuevo_lector')}}</router-link>
            </div>
        </div>
        <v-server-table ref="tabla" @row-click="verLector" @loaded="setCargando(false)" class="tablalistado" :url="url" :columns="columns" :options="options" >
            <template v-slot:idioma="props">{{(props.row).idioma == 'es' ? __('site.espanol') : __('site.ingles')}}</template>

            <div slot="afterFilterWrapper" style="display:inline-flex;margin-bottom:15px;">
                <div style="margin-right: 15px;display: flex;width: 100%;">
                    <input type="text"
                           class="form-control"
                           v-model="query_text"
                           @keyup.enter="actualizarTabla"
                           :placeholder="__('site.buscar')" />&nbsp;
                </div>
                <button @click="actualizarTabla" class="btn boton"
                        :title="__('site.buscar')">
                    <i class="fas fa-search"></i>
                </button>
            </div>
        </v-server-table>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    import {textos_tabla,iconos_tabla} from '../../config_tabla'
    
    export default{

        created: function(){
            let vm = this;
            vm.setCargando(true);
            vm.url =route('lectores.index');
            vm.options.headings = vm.headings;
        },
        mixins: [mixin],
        methods: {
            ...mapMutations(['setCargando']),
            verLector: function(data){
                this.$router.push({ name: 'admin.lectores.edit', params: {'id':data.row.id} })
            },
            actualizarTabla: function(){
                let vm = this;
                vm.$refs.tabla.refresh();
            },
            funcionEnvio: function (data) {
                let vm = this;
                if(vm.query_text){
                    data['query'] = vm.query_text;
                }

                return axios.get(this.url, {
                    params: data
                }).catch(function (e) {
                    this.dispatch('error', e);
                }.bind(this));
            }
        },
        
        data() {
            return {
                lectores: [],
                url: '',
                query_text: '',
                columns: [
                    'nombre',
                    'email',
                    'idioma',
                ],
                headings: {
                        'nombre': this.__('site.nombre'),
                        'email': this.__('site.email'),
                        'idioma': this.__('site.idioma'),
                },
                options: {
                    filterable: false,
                    texts: textos_tabla,
                    sortIcon: iconos_tabla,
                    perPage: 50,
                    perPageValues: [25,50,100],
                    requestFunction: this.funcionEnvio
                }
            }
        },

    }
</script>
