<template>
    <div class="form-control campopassword">
        <input :type="visible?'text':'password'" :placeholder="placeholder" v-model="localValue" />
        <button type="button" @click="visible= !visible">
            <i :class="visible?'fas fa-eye-slash':'fas fa-eye'"></i>
        </button>
    </div>

</template>

<script>
    export default {
        watch: {
            localValue (newValue) {
                this.$emit('input', newValue)
            },
            value (newValue) {
                this.localValue = this.value
            }
        },
        props: {
            value: String,
            placeholder: {
                type: String,
                default: ''
            }
        },
        data(){
            return {
                localValue: this.value,
                visible: false,
            }
        }
    }
</script>