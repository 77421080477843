<template>
    <div class="content">
        <div class="container">
            <h1>{{__('site.ayuda')}}</h1>
            <ul class="menuconfiguracion">
                <li>
                    <a href="/storage/ficheros_descargar/manual_autor.pdf" target="_blank">
                        <i class="fas fa-file-signature"></i>
                        <span>{{__('site.autor')}}</span>
                    </a>
                </li>
                <li v-if="usuario.rol >= roles.revisor">
                    <a href="/storage/ficheros_descargar/manual_revisor.pdf" target="_blank">
                        <i class="far fa-edit"></i>
                        <span>{{__('site.revisor')}}</span>
                    </a>
                </li>
                <li v-if="usuario.rol >= roles.editor">
                    <a href="/storage/ficheros_descargar/manual_editor.pdf" target="_blank">
                        <i class="fas fa-glasses"></i>
                        <span>{{__('site.editor')}}</span>
                    </a>
                </li>
                <li v-if="usuario.rol >= roles.director">
                    <a href="/storage/ficheros_descargar/manual_director.pdf" target="_blank">
                        <i class="fas fa-book"></i>
                        <span>{{__('site.director')}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    import {mapMutations, mapState} from 'vuex';
    import mixin from '../../mixin.js'
    export default {
        mixins: [mixin],
        computed: {
            ...mapState('auth',['usuario']),

        },
        data() {
            return {
            }
        },
    }
</script>

