<template>
    <div>
        <p>
            {{__('site.editar_seccion_texto')}}
        </p>
        <div class="input-group">
            <label>{{__('site.secciones')}}</label>
            <div class="form-group">
                <select class="form-control" v-model="articulo.tipo">
                    <option value=""></option>
                    <option v-for="item in secciones" :value="item.id">{{item.nombre}}</option>
                </select>
            </div>
        </div>
        
        <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
        <div class="text-center">
            <button type="button" class="btn boton" @click="$emit('updated')">{{__('site.cancelar')}}</button>
            <button type="button" class="btn boton" @click="enviar()">{{__('site.enviar')}}</button>
        </div>
    </div>
</template>

<script>
    import mixin from '../../mixin.js'
    import {mapMutations, mapState} from 'vuex';
    export default {

        created: function(){
            let vm = this;
            vm.setCargando(false);
            axios.get(route('articulos.getsecciones')).then(response => {
                vm.secciones = response.data.secciones;
            })
        },
        methods: {
            ...mapMutations(['setCargando']),

            enviar: function(){
                let vm = this;
                vm.setCargando(true);
                vm.errores = [];
                axios.post(route('articulos.updateseccion',{id:vm.articulo.id}), {
                    tipo: vm.articulo.tipo,
                }).then(response => {
                    vm.setCargando(false);
                    vm.$emit('updated');   
                    }).catch(e => {
                        console.log(e);
                        vm.errores = [];
                        for (let key in e.response.data.errors) {
                            vm.errores.push(e.response.data.errors[key][0]);
                        }
                        vm.setCargando(false);
                    });
            }
        },
        mixins: [mixin],
        computed: {
            ...mapState('auth',['usuario']),
            
        },
        props: ['articulo'],
        data() {
            return {
                errores: [],
                ficheros: [],
                secciones:[],
                
            }
        },
    }
</script>
