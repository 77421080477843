

import {mapState} from 'vuex'
import {Rol,TiposPermisos} from './permisos'
import moment from 'moment';

export default {
        computed: {
            ...mapState(['permisos']),
            app_name: function(){
                return process.env.MIX_APP_NAME;
            },
            roles: function(){
                return Rol;
            },
            tipos_permiso: function(){
                return TiposPermisos;
            },
        },
        methods:{
            checkPermiso: function(permiso_id){
                let permiso = this.permisos.find(item => item.permiso_id == permiso_id);
                if(permiso){
                    return permiso.valor;
                }
                return false;
            },
            checkPermisoArticulo: function(id,accion, mostrar = 0){
                return new Promise((resolve) => {
                    axios.get(route('articulos.checkpermiso',{id:id}),{
                        params: {
                            accion: accion,
                            mostrar: mostrar
                        }
                    }).then(response => {
                        resolve(response.data.permiso);
                    }).catch(() => {
                        resolve(false);
                    });
                })

            },
            borrar: function(msg,id,url_envio,url_retorno){
                let vm = this;
                var eliminar = eliminarItem(msg,route(url_envio,{id:id}),
                    url_retorno);
                if(eliminar){
                    vm.$router.push({ name: url_retorno })
                }
            },
            mostrarFecha(fecha){
                return (moment(fecha).isValid())?moment(fecha).format('DD-MM-YYYY'):'';
            },
            mostrarFechaLarga(fecha){
                return (moment(fecha).isValid())?moment(fecha).format('DD-MM-YYYY HH:mm'):'';
            },
            motrarTipoArticulo(tipo){
                return this.__('site.tipos_articulo'+(tipo+1));
            },
            motrarEstadoArticulo(estado){
                return this.__('estados.'+estado);
            },
            motrarEstadoRevision(estado){
                return this.__('estados_revision.'+estado);
            },
            motrarResultadoRevision(estado){
                return this.__('decision_revision.'+estado);
            },
            template: function () {
                return `<div class="dz-preview dz-file-preview">
                <div class="dz-filename"><span data-dz-name></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>`;
            },
            errorDropzone: function(file,message,xhr){
                if(message.includes('File is too big')){
                    swal('Error', message,'error');
                }
            },
        }
};

