<template>
    <div class="container">
        <div class="row">
            <div class="col-8 offset-2">
                <div class="loginframe">
                    <h1>{{__('site.recuperar_password')}}</h1>

                    <div v-if="!cambiada" class="card-body">
                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">{{__('site.email')}}</label>

                            <div class="col-md-6">
                                <input id="email" v-model="email" type="email" class="form-control" name="email" value="" required autofocus>
                            </div>
                        </div>
                        <mostrar-errores v-if="errores.length" :errores="errores"></mostrar-errores>
                        <div class="form-group row mb-0">
                            <div class="col-md-6 offset-md-4">
                                <boton-guardar @guardar="enviar" :cargando="cargando">
                                    <template slot="texto">{{__('site.enviar')}}</template>
                                </boton-guardar>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p>{{__('site.msg_email_password')}}</p>
                        <div class="text-center">
                            <router-link :to="{name:'admin.login'}" class="btn boton">{{__('site.acceder')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import BotonGuardar from "./BotonGuardar";
    export default {
        methods: {
            ...mapActions('auth',['signIn']),
            enviar: function(){
                let vm = this;
                vm.errores = [];
                vm.cargando = true;
                axios.post(route('forgot.password'),{
                    email: vm.email,
                }).then(response => {

                    if(response.data.email){
                        this.cambiada = false;
                        vm.errores = [];
                        vm.errores.push(e.response.data.email);
                    }
                    else{
                        this.cambiada = true;
                    }
                    vm.cargando = false;

                }).catch(e => {
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });
            }
        },
        components: {BotonGuardar},
        data() {
            return {
                cargando: false,
                email:'',
                cambiada: false,
                errores: []
            }
        }
    }
</script>
