<template>
    <div class="container">
        <div class="row">
            <div class="col-8 offset-2">
                <div class="loginframe text-center" >
                    <h1>{{__('site.baja_newsletter')}}</h1>
                    <p>
                        {{__('site.baja_newsletter_msg')}}
                    </p>
                    <div>
                        <div class="text-center">
                            <button type="button" class="btn boton" @click="enviar()">{{__('site.aceptar')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import BotonGuardar from "./BotonGuardar";
    export default {
        created: function(){
            let vm = this;
        },
        components: {BotonGuardar},
        methods: {
            ...mapActions('auth',['signAsUsuario']),
            enviar: function(){
                let vm = this;
                vm.errores = [];
                axios.post(route('usuarios.storebajanewsletter'),{
                    email: vm.$route.query.email?vm.$route.query.email:'',
                }).then(response => {
                    vm.$router.push({name:'admin.home'});
                }).catch(e => {
                    console.log(e);
                    for (let key in e.response.data.errors) {
                        vm.errores.push(e.response.data.errors[key][0]);
                    }
                    vm.cargando = false;
                });

            }
        },
        data() {
            return {

            }
        }
    }
</script>
